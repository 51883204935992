import { useEffect, useState } from "react";
import { Client, Leather, Model, Mold, Sector, Sole, SortDirection, Supplier } from "../../generated/graphql";
import { firstProperty } from "../../services/utils";
import { updateOmit } from "../../utils/objects";
import UserEditor from "../UserEditor/UserEditor";
import clientSchemaGetter from "./Entities/Clients/ClientFormInit";
import useClientStore from "./Entities/Clients/ClientStore";
import leatherSchemaGetter from "./Entities/Leathers/LeatherFormInit";
import useLeatherStore from "./Entities/Leathers/LeatherStore";
import modelSchemaGetter from "./Entities/Models/ModelFormInit";
import useModelStore from "./Entities/Models/ModelStore";
import moldSchemaGetter from "./Entities/Molds/MoldFormInit";
import useMoldStore from "./Entities/Molds/MoldStore";
import sectorSchemaGetter from "./Entities/Sectors/SectorFormInit";
import useSectorStore from "./Entities/Sectors/SectorStore";
import soleSchemaGetter from "./Entities/Soles/SoleFormInit";
import useSoleStore from "./Entities/Soles/SoleStore";
import supplierSchemaGetter from "./Entities/Suppliers/SupplierFormInit";
import useSupplierStore from "./Entities/Suppliers/SupplierStore";
import useUserStore from "./Entities/Users/UserStore";
import getRecords from "./Helpers/getRecords";
import makeSettingFormInit from "./Helpers/maleSettingFormInit";
import { SettingRecordUser } from "./SettingRecordUser";

const useSettingsStore = () => {

    // stores
    const clientStore = useClientStore()
    const leatherStore = useLeatherStore()
    const modelStore = useModelStore()
    const moldStore = useMoldStore()
    const sectorStore = useSectorStore()
    const soleStore = useSoleStore()
    const supplierStore = useSupplierStore()
    const userStore = useUserStore()

    // form inits
    const clientFormInit = makeSettingFormInit<Client>(clientSchemaGetter)
    const leatherFormInit = makeSettingFormInit<Leather>(leatherSchemaGetter)
    const modelFormInit = makeSettingFormInit<Model>(modelSchemaGetter)
    const moldFormInit = makeSettingFormInit<Mold>(moldSchemaGetter)
    const sectorFormInit = makeSettingFormInit<Sector>(sectorSchemaGetter)
    const soleFormInit = makeSettingFormInit<Sole>(soleSchemaGetter)
    const supplierFormInit = makeSettingFormInit<Supplier>(supplierSchemaGetter)

    const variables = { variables: { paging: { limit: 9999 }, sorting: { field: "name", direction: SortDirection.Asc } } }

    // setting ou configuration, temos que encapsular
    const entities = [
        { id: 1, name: "Clientes", store: clientStore, schemaInit: clientFormInit, variables },
        { id: 2, name: "Formas", store: moldStore, schemaInit: moldFormInit, variables },
        { id: 3, name: "Fornecedores", store: supplierStore, schemaInit: supplierFormInit, variables },
        { id: 4, name: "Modelos", store: modelStore, schemaInit: modelFormInit, variables },
        { id: 5, name: "Peles", store: leatherStore, schemaInit: leatherFormInit, variables },
        { id: 6, name: "Setores", store: sectorStore, schemaInit: sectorFormInit, variables },
        { id: 7, name: "Solas", store: soleStore, schemaInit: soleFormInit, variables },
        { id: 8, name: "Utilizadores", editor: UserEditor, viewer: SettingRecordUser, store: userStore, variables },
    ]

    const [selectedRecord, setSelectedRecord] = useState<any>(null)
    const [selectedEntity, setSelectedEntity] = useState<any>(entities[0])

    const { data, loading, refetch } = selectedEntity?.store?.query(selectedEntity?.variables)

    useEffect(() => {
        const records = getRecords(data)
        const found = records.find((obj: any) => obj.id === selectedRecord?.id)
        setSelectedRecord(found)
    }, [data]);


    const setSelectedEntityProxy = (entity: any) => {
        setSelectedRecord(null)
        setSelectedEntity(entity)
    }

    const createProxy = async (record: any) => {
        const { create, name } = selectedEntity?.store
        let response = null

        try {
            response = await create({ variables: { input: { [name]: { ...record } } } })
            await refetch()
            const first = firstProperty(response?.data)
            if (first) {
                setSelectedRecord(response.data[first])
            }
        } catch (e) {
            console.log(e)
        }

        return response
    }

    const updateProxy = async (id: any, data: any) => {
        const { update } = selectedEntity?.store
        const response = await update({ variables: { input: { id, update: updateOmit(data, []) } } })
        await refetch()
        const first = firstProperty(response?.data)
        if (first) {
            setSelectedRecord(response.data[first])
        }
        return response
    }

    const removeProxy = async (id: any) => {
        const { remove } = selectedEntity?.store
        await remove({ variables: { input: { id } } })
        await refetch()
        setSelectedRecord(null)
    }

    return {
        entities,
        selectedEntity,
        setSelectedEntity: setSelectedEntityProxy,
        data,
        loading,
        selectedRecord,
        setSelectedRecord,
        store: { ...selectedEntity?.store, create: createProxy, update: updateProxy, remove: removeProxy, refetch },
        editor: selectedEntity?.editor,
        viewer: selectedEntity?.viewer
    }
}

export default useSettingsStore
