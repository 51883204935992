import { Maybe, ValidationResult } from "../../../generated/graphql";

export type ValidationMessage = {
    address: string,
    value: string | null | undefined,
    messages: string[]
};

type ValidationResultLists = {
    errors: ValidationMessage[],
    informations: ValidationMessage[],
    warnings: ValidationMessage[]
};

/**
 * Função de comparação para ordenar pelo campo endereço, primeiro ordena pela letra
 * da coluna e depois pelo número da linha
 */
export const compareByAddress = (a: ValidationMessage, b: ValidationMessage) => {
    const letterComparison = a.address.charAt(0).localeCompare(b.address.charAt(0));
    if (letterComparison !== 0) return letterComparison;

    const numA = parseInt(a.address.slice(1));
    const numB = parseInt(b.address.slice(1));
    return numA - numB;
};


export const extractLists = (data: Maybe<ValidationResult[]>): ValidationResultLists => {
    let errors: ValidationMessage[] = [];
    let informations: ValidationMessage[] = [];
    let warnings: ValidationMessage[] = [];

    data?.forEach(result => {
        if (result.errors) errors.push({ address: result.address, value: result.value, messages: result.errors });
        if (result.informations) informations.push({ address: result.address, value: result.value, messages: result.informations });
        if (result.warnings) warnings.push({ address: result.address, value: result.value, messages: result.warnings });
    });

    // ordernar pelo address
    errors.sort(compareByAddress);
    informations.sort(compareByAddress);
    warnings.sort(compareByAddress);

    return { errors, informations, warnings };
};
