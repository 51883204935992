import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import PlanningStateFilter from "../PlanningStateFilter/PlanningStateFilter";
import SectorFilter from "../SectorFilter/SectorFilter";
import WeekFilter from "../WeekFilter/WeekFilter";
import YearFilter from "../YearFilter/YearFilter";
import styles from './PlanningsFilters.module.scss';

const PlanningsFilters = () => {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.PlanningsFilters}>
                <WeekFilter value={context?.filters?.week} onChange={context?.setFilter} />
                <YearFilter value={context?.filters?.year} onChange={context?.setFilter} />
                <SectorFilter value={context?.filters?.sector} onChange={context?.setFilter} />
                <PlanningStateFilter value={context?.filters?.planningState} onChange={context?.setFilter} />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}

export default PlanningsFilters
