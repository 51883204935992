import { IconButton, Popover } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { FC, MouseEvent, ReactNode, useState } from 'react';

interface MoreInfoButtonProps {
    children: ReactNode
    className?: string
}

const MoreInfoButton: FC<MoreInfoButtonProps> = props => {
    const { children, className } = props
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget)
        event.stopPropagation()
    }

    const popoverOpen = Boolean(anchor)
    const id = popoverOpen ? 'simple-popover' : undefined;

    return (
        <>
            <IconButton onClick={handleClick} className={className}>
                <InfoOutlined />
            </IconButton>
            <Popover
                id={id}
                open={popoverOpen}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                disableScrollLock={true}
                disablePortal={true}
            >
                {children}
            </Popover>
        </>
    )

}

export default MoreInfoButton
