import { TextFieldProps } from '@mui/material/TextField';
import { setLocale } from "yup";

export const REQUIRED_FIELD = 'Campo obrigatório.'

function getEnv(envname: string) {
    // @ts-ignore
    return window._env_ ? window._env_[envname] : ''
}

const Config = {
    GRAPHQL_API: getEnv("GRAPHQL_API"),
    MEDIA_URL: getEnv("MEDIA_URL"),
    AUTH_ENDPOINT: getEnv("AUTH_ENDPOINT"),
    APP_URL: getEnv("APP_URL"),
    MUI_LICENSE_KEY: getEnv("MUI_LICENSE_KEY"),
    JWT_AUTH_HEADER_PREFIX: "Bearer",
    AUTH_TOKEN_KEY: "auth.token",
    START_ROUTE: "/",
    PROTECT_DEFAULT_REDIRECT_ROUTE: "/login",
    PAGE_SIZE: 25,
    // FORMAT DATE
    DEFAULT_FORMAT_DATE: "dd'-'MM'-'yyyy",
    DEFAULT_FORMAT_DATETIME: "dd'/'MM'/'yyyy HH:mm",
    DATE_FORMAT: "dd/MM/yyyy",
    DATETIME_FORMAT: "dd'/'MM'/'yyyy HH:mm",
    DATE_INPUT_FORMAT: "dd/MM/yyyy",
    DEFAULT_FADE_TIMEOUT: 100,
    PAGE_SIZE_OPTIONS: [10, 25, 50],
    NO_OPTIONS_TEXT: "Sem opções",
    REQUIRED_FIELD: 'Campo obrigatório.',
    MAX_RESULTS_SIZE: 999,
    DEFAULT_TEXTFIELD_VARIANT: "standard" as TextFieldProps['variant'],
    NO_RESULTS_TEXT: "Sem Resultados",
    DEFAULT_STATE_COLOR: "#000000",
    COLORS: {
        RED: "red",
        GREEN: "green"
    }
};

setLocale({
    mixed: {
        default: 'Não é válido',
        required: REQUIRED_FIELD
    },
    number: {
        // no-template-curly-in-string
        // as variáveis não são definidas
        // neste contexto, pelo que não se podemos
        // usar template strings
        // eslint-disable-next-line
        min: 'Tem que ser maior que ${min}',
        // eslint-disable-next-line
        max: 'Tem que ser menor que ${max}',
        integer: 'Campo tem que ser um número inteiro',
        positive: 'Tem que ser um número positivo'
    },
    string: {
        length: 'Tem que ter 8 digitos.',
        // eslint-disable-next-line
        min: 'Tem que ser maior que ${min}',
        // eslint-disable-next-line
        max: 'No máximo ${max} caracteres.'
    },
    // date: {
    //     format: "Formato Inválido"
    // }
});


export default Config;
