import { Typography } from "@mui/material";
import translate from "../../utils/translate";

export const SettingRecordUser = (props: any) => {
    const { record, onSelect, classes } = props
    const t = translate
    return (
        <div onClick={() => onSelect(record)} style={{ marginBottom: 25 }}>
            <Typography variant="h5" color="primary" className={classes} style={{ marginBlockEnd: 0 }}>
                {record?.name} ({t(record?.role, "lowercase")})
            </Typography>
            <Typography variant="body1" color="secondary" style={{ cursor: "pointer", color: "#666666" }}>
                {record?.email}
            </Typography>
        </div>
    )
}
