import { Fade } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useOrderQuery } from '../../generated/graphql';
import styles from './Order.module.scss';

const Order = () => {
    const { id = "" } = useParams()
    const { data, loading } = useOrderQuery({ variables: { id } })
    return (
        <Fade in={!loading} timeout={300}>
            <div className={styles.Wrapper}>
                Order
                <p>{data?.order?.id}</p>
            </div>
        </Fade>
    )
}

export default Order;
