import { useCreateOneModelMutation, useDeleteOneModelMutation, useModelsQuery, useUpdateOneModelMutation } from "../../../../generated/graphql"

export default function useModelStore() {
    const query = useModelsQuery
    const name = "model"
    const [create] = useCreateOneModelMutation()
    const [update] = useUpdateOneModelMutation()
    const [remove] = useDeleteOneModelMutation()
    return { query, name, create, update, remove }
}
