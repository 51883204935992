import { FilterProps } from "../../types/types"
import { getPriorityOptions } from "../../utils/filters"
import SelectNumberFilter from "../SelectNumberFilter/SelectNumberFilter"

export default function PriorityFilter(props: FilterProps) {
    const { value, onChange } = props

    const options = getPriorityOptions()

    return (
        <SelectNumberFilter
            value={value}
            onChange={onChange}
            label="Prioridade"
            multiple={true}
            options={options}
            field="priority"
        />
    )
}
