import { ArrayParam, StringParam } from 'use-query-params'
import { SelectEnumFilterOption } from "../components/SelectEnumFilter/SelectEnumFilter"
import { SelectNumberFilterOption } from '../components/SelectNumberFilter/SelectNumberFilter'
import { FilterConfig } from "../hooks/useFilters"
import { numberLookupExpression, searchExpFunc } from "./lookups"
import { range } from './numbers'
import translate from "./translate"

interface FilterConfigParams {
    name: string
    defaultValue?: string[]
}

interface SearchFilterConfigParams {
    name: string
}

export const relationFilterConfig = (params: FilterConfigParams): FilterConfig => {
    const { name, defaultValue = [] } = params
    return { name, variant: ArrayParam, defaultValue }
}

export const searchFilterConfig = (params: SearchFilterConfigParams): FilterConfig => {
    const { name } = params
    return { name, variant: StringParam, defaultValue: "", lookupExpFunc: searchExpFunc }
}

export const numberFilterConfig = (params: FilterConfigParams): FilterConfig => {
    const { name, defaultValue = [] } = params
    return { name, variant: ArrayParam, defaultValue, lookupExpFunc: numberLookupExpression }
}


export const enumToOptions = (enumType: any, valueFieldName: string = "name"): SelectEnumFilterOption[] => {
    const options = Object.values(enumType).filter(value => typeof value === 'string') as string[]
    if (!options?.length) {
        throw Error("Invalid Input")
    }
    return options.map(
        (option: string) => {
            return (
                { id: option, [valueFieldName]: translate(option, "capitalize") || option }
            )
        }
    )
}

export const rangeToOptions = (range: number[]): SelectNumberFilterOption[] => {
    return range.map(num => {
        const numStr = num.toString()
        return {
            id: numStr,
            name: numStr
        }
    })
}

export const getPriorityOptions = () => {
    const priorities = range(1, 5)
    return rangeToOptions(priorities)
}
