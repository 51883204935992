import { FilterConfig } from '../../hooks/useFilters';
import { relationFilterConfig, searchFilterConfig } from '../../utils/filters';
import OrderProvider from '../OrderProvider/OrderProvider';
import OrdersActions from '../OrdersActions/OrdersActions';
import OrdersFilters from '../OrdersFilters/OrdersFilters';
import OrdersList from '../OrdersList/OrdersList';
import styles from './OrdersView.module.scss';

const OrdersView = () => {

    const filterConfig: FilterConfig[] = [
        searchFilterConfig({ name: "clientReference" }),
        relationFilterConfig({ name: "client" }),
    ]

    return (
        <div className={styles.Wrapper}>
            <OrderProvider filterConfig={filterConfig}>
                <OrdersActions />
                <OrdersFilters />
                <OrdersList />
            </OrderProvider>
        </div>
    )
}

export default OrdersView
