import Config from '../../../services/config'
import styles from './PlanningStateRender.module.scss'

const getPlanningStateBackgroundColor = (params: any) => {
    return params?.row?.planningState?.color || Config.DEFAULT_STATE_COLOR
}

export const PlanningStateRender = (params: any) => {
    const backgroundColor = getPlanningStateBackgroundColor(params)
    return (
        <div className={styles.PlanningState} style={{ backgroundColor }}>
            {params?.row?.planningState?.name}
        </div>
    )
}
