import { CalendarViewMonth, Dashboard, Event, Menu as MenuIcon, NextPlan, Settings, Upcoming } from '@mui/icons-material';
import { Drawer, Grid, IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { useIsManager } from '../../hooks/useIsManager';
import Hide from '../Hide/Hide';
import { useSidemenu } from '../Layout/LayoutProvider';
import Show from '../Show/Show';
import styles from './Sidemenu.module.scss';

const Sidemenu = () => {

    const navigate = useNavigate()
    const { isOpen, setIsOpen } = useSidemenu()
    const isManager = useIsManager()

    const handleNavigate = (route: string = '/dashboard') => {
        setIsOpen(false)
        navigate(route)
    }

    return (
        <Drawer open={isOpen} onClose={() => setIsOpen(false)}>
            <Grid className={styles.SideMenuHeader} container justifyContent="flex-start" alignItems="center">
                <IconButton onClick={() => setIsOpen(false)}>
                    <MenuIcon />
                </IconButton>
            </Grid>
            <Grid className={styles.Sidemenu}>
                <List>
                    <Show on={isManager}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigate()}>
                                <ListItemIcon>
                                    <Dashboard />
                                </ListItemIcon>
                                <ListItemText primary={"Dashboard"} />
                            </ListItemButton>
                        </ListItem>
                    </Show>
                    <Hide on={isManager}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigate("/sector")}>
                                <ListItemIcon>
                                    <NextPlan />
                                </ListItemIcon>
                                <ListItemText primary={"Planeamento"} />
                            </ListItemButton>
                        </ListItem>
                    </Hide>
                    <Show on={isManager}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigate("/plannings")}>
                                <ListItemIcon>
                                    <Event />
                                </ListItemIcon>
                                <ListItemText primary={"Planeamentos"} />
                            </ListItemButton>
                        </ListItem>
                    </Show>
                    <Show on={isManager}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigate("/orders")}>
                                <ListItemIcon>
                                    <Upcoming />
                                </ListItemIcon>
                                <ListItemText primary={"Encomendas"} />
                            </ListItemButton>
                        </ListItem>
                    </Show>
                    <Show on={isManager}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigate("/work-orders")}>
                                <ListItemIcon>
                                    <CalendarViewMonth />
                                </ListItemIcon>
                                <ListItemText primary={"Ordens"} />
                            </ListItemButton>
                        </ListItem>
                    </Show>
                    <Show on={isManager}>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handleNavigate("/settings")}>
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText primary={"Definições"} />
                            </ListItemButton>
                        </ListItem>
                    </Show>
                </List>
            </Grid>
        </Drawer>
    );
};

export default Sidemenu;
