import { useSolesQuery } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

export default function SoleFilter(props: FilterProps) {
    const { value, onChange } = props
    const query = useSolesQuery

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Sola"
            query={query}
            field="sole"
            sorting={{ field: "name", direction: "ASC" }}
            paging={{ limit: 9999 }}
            multiple={true}
        />
    )
}
