import { Card, CardContent, Typography } from "@mui/material";
import { clsx } from "clsx";
import { FC } from "react";
import styles from './SettingsView.module.scss';

interface SettingsEntitiesProps {
    entities: any,
    selected: any
    onSelect: any
}

// temos que mostrar a lista de entidades
// e cada lista tem que ser um link que muda
// a location
const SettingsEntities: FC<SettingsEntitiesProps> = props => {
    const { entities, selected, onSelect } = props
    return (
        <Card id={styles.SettingsEntities} className={styles.SettingsEntities}>
            <CardContent>
                <Typography variant="h4" color="primary">Listas Apoio</Typography>
                {entities.map((entity: any) => {
                    const classes = clsx(entity?.id === selected?.id ? styles.Selected : null)
                    return (
                        <Typography variant="h5" key={entity.id} onClick={() => onSelect(entity)} className={classes} style={{ color: "#666666" }}>
                            {entity.name}
                        </Typography>
                    )
                }
                )}
            </CardContent>
        </Card >
    )
}

export default SettingsEntities
