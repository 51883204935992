import { useLeathersQuery } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

export default function LeatherFilter(props: FilterProps) {
    const { value, onChange } = props
    const query = useLeathersQuery

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Pele"
            query={query}
            field="leather"
            sorting={{ field: "name", direction: "ASC" }}
            paging={{ limit: 9999 }}
            multiple={true}
        />
    )
}
