import config from "../services/config";

/**
* starts download of file from the frontend
* @param url - the url of file to download
* @param filename - the name of the file
*
* @example downloadRequest(url, 'folhasala.pdf')
*/
export const downloadRequest = (url: string, filename: string) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function() {
        const type = xhr.getResponseHeader("content-type");
        const arrayBufferView = new Uint8Array(this.response);
        // @ts-ignore
        const blob = new Blob([arrayBufferView], { type });
        const anchor = document.createElement('a');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.click();
    };
    xhr.send();
}

/**
 * o mesmo que o downlodRequest mas o primeiro argumento é passado
 * ao staticfile, uma vez que é um padrao comum.
 */
export const downloadRequestStatic = (path: string, filename: string) => {
    downloadRequest(staticFile(path), filename)
}

/** Returns the absolute path to a file in the api */
export const staticFile = (relative: string): string => {
    return config.MEDIA_URL + relative
}
