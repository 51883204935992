import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import ClientFilter from "../ClientFilter/ClientFilter";
import CuttingSystemFilter from "../CuttingSystemFilter/CuttingSystemFilter";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import LeatherFilter from "../LeatherFilter/LeatherFilter";
import ModelFilter from "../ModelFilter/ModelFilter";
import MoldFilter from "../MoldFilter/MoldFilter";
import SoleFilter from "../SoleFilter/SoleFilter";
import styles from './WorkOrdersFilters.module.scss';

const WorkOrdersFilters = () => {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.WorkOrdersFilters}>
                <ClientFilter value={context?.filters?.client} onChange={context?.setFilter} />
                <LeatherFilter value={context?.filters?.leather} onChange={context?.setFilter} />
                <SoleFilter value={context?.filters?.sole} onChange={context?.setFilter} />
                <MoldFilter value={context?.filters?.mold} onChange={context?.setFilter} />
                <ModelFilter value={context?.filters?.model} onChange={context?.setFilter} />
                <CuttingSystemFilter value={context.filters.cuttingSystem} onChange={context.setFilter} />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}

export default WorkOrdersFilters;
