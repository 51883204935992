import { FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { getPriorityOptions } from '../../utils/filters';

export default function PriorityCustomSelector(props: any) {
    const { value, onChange } = props

    const nodes = getPriorityOptions()

    const inputProps = { disableUnderline: true }

    const render = (selected: string): string => {
        const planning = nodes?.find(node => node.id === selected)
        return planning ? getListItemText(planning) : ""
    }

    const getListItemText = (node: any): string => {
        return `${node?.name}`
    }

    return (
        <FormControl sx={{ m: 1, width: 200 }}>
            {!value ? <InputLabel>Prioridade</InputLabel> : null}
            <Select
                value={value}
                onChange={onChange}
                input={<Input {...inputProps} />}
                renderValue={render}
                autoWidth={false}
            >
                {nodes?.length && nodes?.map((node: any) => (
                    <MenuItem key={node?.id} value={node?.id}>
                        <ListItemText primary={getListItemText(node)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}
