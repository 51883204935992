import { Card, CardContent, Typography } from "@mui/material";
import { clsx } from "clsx";
import { createElement, FC } from "react";
import Config from "../../services/config";
import styles from './SettingsView.module.scss';

interface SettingsRecordsProps {
    records: any
    selected: any
    onSelect: any
    viewer: any
    selectedEntity: any
}


interface SettingRecordProps {
    record: any
    onSelect: any
    classes: any
}

const SettingRecord: FC<SettingRecordProps> = props => {
    const { record, onSelect, classes } = props
    return (
        <Typography variant="h5" key={record.id} onClick={() => onSelect(record)} className={classes} style={{ color: "#666666" }}>
            {record.name}
        </Typography>
    )
}

const SettingsRecords: FC<SettingsRecordsProps> = props => {
    const { records, selected, onSelect, viewer, selectedEntity } = props

    return (
        <Card id={styles.SettingsRecords} className={styles.SettingsRecords}>
            <CardContent>
                <Typography variant="h4" color="primary">{selectedEntity.name}</Typography>
                {!records?.length && <Typography variant="h6" style={{ color: "#666666" }}>{Config.NO_OPTIONS_TEXT}</Typography>}
                {records?.map((record: any) => {
                    const classes = clsx(record?.id === selected?.id ? styles.Selected : null)
                    return (
                        viewer ? createElement(viewer, { record, onSelect, classes }) : <SettingRecord record={record} onSelect={onSelect} classes={classes} />
                    )
                }
                )}
            </CardContent>
        </Card>
    )
}

export default SettingsRecords
