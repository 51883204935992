import { useCreateOneSoleMutation, useDeleteOneSoleMutation, useSolesQuery, useUpdateOneSoleMutation } from "../../../../generated/graphql"

export default function useSoleStore() {
    const query = useSolesQuery
    const name = "sole"
    const [create] = useCreateOneSoleMutation()
    const [update] = useUpdateOneSoleMutation()
    const [remove] = useDeleteOneSoleMutation()
    return { query, name, create, update, remove }
}
