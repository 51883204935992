import { TextField } from '@mui/material';

// // @todo - temos que encontrar uma solução para isto
// ou pelo menos adicionar uma opção validation policy
const showError = (form: any, field: any) => {
    const fieldname = field.name
    const hasError = form.errors[fieldname]
    const isActive = field.value ? true : false
    const isTouched = form.touched[fieldname]
    return (hasError && (isActive || isTouched)) ? true : false
    // return hasError
}

//  // @todo - é preciso resolver este typings, decidir entre usar rest params ou não
const FieldText = ({ field, form, ...props }: { field: any, form: any, props: any }) => {

    const {
        // @ts-ignore
        disabled = false, inputProps = {}
    } = props

    // @ts-ignore
    const { label } = props
    const error = showError(form, field)
    const helperText = error && form.errors[field.name]
    // @ts-ignore
    const _type = props.type ? props.type : "text"
    // @ts-ignore
    const autoComplete = props.autoComplete ? props.autoComplete : "off"
    // @ts-ignore
    const autoFocus = props.autoFocus ? props.autoFocus : false

    // @ts-ignore
    const variant = props.variant ? props.variant : "standard"

    // @ts-ignore
    const multiline = props.multiline ? props.multiline : false

    // @ts-ignore
    const fullWidth = props.fullWidth ? props.fullWidth : false

    // @ts-ignore
    const rows = props.rows ? props.rows : 1

    // @ts-ignore
    const InputProps = props?.InputProps ? props.InputProps : {}

    // @ts-ignore
    const InputLabelProps = props?.InputLabelProps ? props.InputLabelProps : {}

    return (
        <TextField
            {...field}
            label={label}
            error={error}
            helperText={helperText}
            type={_type}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            InputProps={InputProps}
            fullWidth={fullWidth}
            disabled={disabled}
            inputProps={inputProps}
            rows={rows}
            variant={variant}
            multiline={multiline}
            InputLabelProps={InputLabelProps}
        />
    )
}

export default FieldText
