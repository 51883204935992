import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { useContext } from "react"
import { Order } from "../../generated/graphql"
import useMakeFormSubmit from "../../hooks/useMakeFormSubmit"
import { formInit } from "../../utils/forms"
import ClientField from "../ClientField/ClientField"
import { DataViewContext } from "../DataViewProvider/DataViewProvider"
import Hide from "../Hide/Hide"
import InputNumberField from "../InputNumberField/InputNumberField"
import InputTextField from "../InputTextField/InputTextField"
import SubmitButton from "../SubmitButton/SubmitButton"
import styles from './OrderForm.module.scss'
import orderSchemaGetter from "./OrderFormSchema"

const OrderForm = (props: any) => {
    const { order, onSuccess } = props
    const context = useContext(DataViewContext)
    const { schema, initial } = formInit<Order>(orderSchemaGetter, order)
    const handleSubmit = useMakeFormSubmit({ context, onSuccess, entity: "order" })

    const formName = order?.id ? "Editar Encomenda" : "Criar Encomenda"


    const _handleSubmit = (values: FormikValues) => {
        values.cuttingSystem = values.cuttingSystem?.id
        handleSubmit(values)
    }

    return (
        <div className={styles.TaskFormWrapper}>
            <Formik initialValues={initial} onSubmit={_handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                {() => {
                    return (
                        <Form>
                            <Grid container direction="column" className={styles.Section}>
                                <Typography variant="h4" color="primary" gutterBottom>
                                    {formName}
                                </Typography>
                            </Grid>

                            <TableContainer>
                                <Table sx={{ minWidth: 400 }} className={styles.Table}>
                                    <TableBody>

                                        <Hide on={order?.id}>
                                            <TableRow key={0}>
                                                <TableCell>
                                                    <InputNumberField name="orderId" label="encomenda" fullWidth />
                                                </TableCell>
                                            </TableRow>
                                        </Hide>

                                        <TableRow key={1}>
                                            <TableCell>
                                                <InputTextField name="clientReference" label="referência" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={2}>
                                            <TableCell>
                                                <ClientField />
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container justifyContent="flex-end">
                                <SubmitButton />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default OrderForm
