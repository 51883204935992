import { FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { PlanningSortFields, SortDirection, usePlanningsQuery } from '../../generated/graphql';

export default function PlanningCustomSelector(props: any) {
    const { value, onChange } = props
    const { data } = usePlanningsQuery({ variables: { paging: { limit: 999 }, sorting: [{ field: PlanningSortFields.Id, direction: SortDirection.Asc }] } })

    const nodes = data?.plannings?.nodes
    const inputProps = { disableUnderline: true }

    const render = (selected: string): string => {
        const planning = nodes?.find(node => node.id === selected)
        return planning ? getListItemText(planning) : ""
    }

    const getListItemText = (node: any): string => {
        return `${node?.week}/${node.year} - ${node?.sector?.name}`
    }

    return (
        <FormControl sx={{ m: 1, width: 200 }}>
            {!value ? <InputLabel>Planeamento</InputLabel> : null}
            <Select
                value={value}
                onChange={onChange}
                input={<Input {...inputProps} />}
                renderValue={render}
                autoWidth={false}
            >
                {nodes?.length && nodes?.map((node: any) => (
                    <MenuItem key={node?.id} value={node?.id}>
                        <ListItemText primary={getListItemText(node)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}
