import { useCreateOneSectorMutation, useDeleteOneSectorMutation, useSectorsQuery, useUpdateOneSectorMutation } from "../../../../generated/graphql"

export default function useSectorStore() {
    const query = useSectorsQuery
    const name = "sector"
    const [create] = useCreateOneSectorMutation()
    const [update] = useUpdateOneSectorMutation()
    const [remove] = useDeleteOneSectorMutation()
    return { query, name, create, update, remove }
}
