import { useOrdersQuery } from '../../generated/graphql';
import config from '../../services/config';
import { FormFieldProps } from '../../types/types';
import SelectAutocompleteField from '../SelectAutocompleteField/SelectAutocompleteField';

export default function OrderField(props: FormFieldProps) {
    const {
        name = "order",
        label = "Encomenda",
        textFieldProps = { variant: config.DEFAULT_TEXTFIELD_VARIANT },
        disabled = false,
        limit = config.MAX_RESULTS_SIZE
    } = props

    return (
        <SelectAutocompleteField
            name={name}
            label={label}
            query={useOrdersQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            field="orderId"
            queryPaging={{
                limit
            }}
        />
    )
}
