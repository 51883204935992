import { Close } from "@mui/icons-material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Maybe, useGenerateWorkOrderTemplateMutation, useImportWorkOrdersMutation, useValidateWorkOrdersImportMutation, ValidationResult } from "../../generated/graphql";
import useToast from "../../hooks/useToast";
import { downloadRequestStatic } from "../../utils/files";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import Hide from "../Hide/Hide";
import MoreInfoButton from "../MoreInfoButton/MoreInfoButton";
import Show from "../Show/Show";
import { extractLists, ValidationMessage } from "./Helpers/payloadTransformer";
import styles from './ImportWorkOrders.module.scss';

interface ImportWorkOrdersProps {
    disabled?: boolean,
    onClose: () => void
}

interface ShowValidationResultsProps {
    informations: ValidationMessage[]
    errors: ValidationMessage[]
}

const ShowValidationResults: FC<ShowValidationResultsProps> = props => {
    const { errors, informations } = props

    return (
        <Show on={!!errors?.length || !!informations?.length}>

            <div style={{ marginTop: 20 }}>
            </div >
            <div>
                {errors?.map(error => (
                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                        <Typography variant="body1" style={{ color: "red" }}>
                            Verificar valor {error.value} na célula {error.address}
                        </Typography>
                        <MoreInfoButton>
                            <div style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10 }}>
                                {error.messages.map(message => <p key={message}>{message}</p>)}
                            </div>
                        </MoreInfoButton>
                    </Grid>
                )
                )}
            </div>

            <Show on={!!informations?.length}>
                <div style={{ marginTop: 25 }}>
                    <Typography variant="subtitle1" color="text">
                        Informações Adicionais
                    </Typography>
                </div>
            </Show>

            <div>
                {informations?.map(information => (
                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                        <Typography variant="body1">
                            Valor <i>{information.value}</i> na célula {information.address} não encontrado, será adicionado.
                        </Typography>
                    </Grid>
                )
                )}
            </div>

        </Show >
    )
}


export default function ImportWorkOrders(props: ImportWorkOrdersProps) {
    const { onClose, disabled = false } = props

    const { errorToast, feedback } = useToast()
    const [importFile, setImportFile] = useState<Maybe<File>>(null)
    const [errors, setErrors] = useState<Maybe<ValidationResult[]>>(null)
    const [generate] = useGenerateWorkOrderTemplateMutation()
    const [validate] = useValidateWorkOrdersImportMutation()
    const [import_] = useImportWorkOrdersMutation()
    const context = useContext(DataViewContext)

    const handleDownload = async () => {
        try {
            const response = await generate()
            const path = response?.data?.generateImportTemplate
            if (path) downloadRequestStatic(path, `template.xlsx`)
        } catch (e) {
            errorToast("Erro ao gerar template. Verifique pf todas as definições.")
        }
    }

    const handleDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {

        if (acceptedFiles?.length) {
            setImportFile(acceptedFiles[0])
            const response = await validate({ variables: { input: { path: acceptedFiles[0] } } })
            if (response?.data?.validateWorkOrdersImport?.length) {
                setErrors(response?.data?.validateWorkOrdersImport)
            }
        }

        /* if (rejectedFiles?.length) {
         *     rejectedFiles.map(rejectedFile => {
         *         // temos que verificar apenas dois códigos, FileToolarge e FileInvalidtype
         *         // uma rejeição pode ter mais do que um motivo
         *         // vamos apenas mostrar um, sendo o mais importante o formato
         *         const invalidMessage = `Ficheiro ${rejectedFile?.file?.name} não suportado. Apenas suporte para .xlsx.`
         *         const tooLargeMessage = `Ficheiro ${rejectedFile?.file?.name} excede o tamanho permitido`
         *         const message = rejectedFile.errors.find(fileError => fileError.code === ErrorCode.FileInvalidType) ? invalidMessage : tooLargeMessage
         *         errorToast(message)
         *     })
         * } */
    }

    const handleImport = async () => {
        try {
            const response = await import_({ variables: { input: { path: importFile } } })
            feedback(Boolean(response?.data?.importWorkOrders), "Importação bem sucedida.", "Erro ao importar linhas de trabalho")
            setImportFile(null)
            onClose()
            context.refetch()
        }
        catch (e: unknown) {
            errorToast("Erro desconhecido.")
        }
    }

    const handleClearImportFile = (event: any) => {
        setImportFile(null)
        setErrors(null)
        event.stopPropagation()
    }


    const { getRootProps } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xlsx'] },
        disabled
    })

    const disableImportButton = Boolean(!importFile?.name || errors?.length)
    const lists = extractLists(errors)

    return (
        <div className={styles.Wrapper}>
            <Typography variant="h5" color="primary" gutterBottom>
                Importar Ordens Fabrico
            </Typography>
            <div className={styles.Instructions}>
                <ol>
                    <li>
                        <Typography variant="body2" color="text">
                            Descarregue ficheiro excel
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" color="text">
                            Preencha o ficheiro
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" color="text">
                            Carregue Ficheiro e verifique se tem erros
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body2" color="text">
                            Importe Ordens
                        </Typography>
                    </li>
                </ol>
            </div>
            <Button onClick={handleDownload} variant="outlined" color="secondary" disabled={disabled}>
                Descarregar Ficheiro
            </Button>
            <span {...getRootProps()} style={{ marginLeft: 20 }}>
                <Button variant="contained" disabled={disabled}>
                    Upload
                </Button>
            </span>

            <Hide on={!importFile?.name}>
                <span style={{ fontSize: 12, marginLeft: 15 }}>{importFile?.name}</span>
                <IconButton onClick={handleClearImportFile}>
                    <Close fontSize="small" />
                </IconButton>
            </Hide>

            <Button onClick={handleImport} variant="contained" color="primary" disabled={disableImportButton && !!lists?.errors?.length} style={{ marginLeft: 25 }}>
                Importar
            </Button>

            <Hide on={disableImportButton}>
                <Typography variant="body1" color="text" style={{ marginTop: 50 }}>
                    Ficheiro não contém erros. Pode prosseguir com a importação.
                </Typography>
            </Hide>

            <ShowValidationResults errors={lists.errors} informations={lists.informations} />
        </div>
    )
}
