import * as Yup from "yup";
import config from "../../services/config";

export default function workOrderSchemaGetter() {
    return Yup.object().shape({
        workOrderid: Yup.number().required().default(null),
        quantity: Yup.number().required().default(0),
        order: Yup.mixed().required().default(null),
        leather: Yup.mixed().required().default(null),
        sole: Yup.mixed().required().default(null),
        mold: Yup.mixed().required().default(null),
        model: Yup.mixed().required().default(null),
        orderDate: Yup.date().required().default(null).typeError(config.REQUIRED_FIELD),
        cuttingSystem: Yup.mixed().required().default(null),
        clientOrderReference: Yup.string().nullable().default(""),
        clientModelReference: Yup.string().nullable().default(""),
        observations: Yup.string().nullable().default(""),
        isLeatherAvailable: Yup.boolean().required().default(false),
        isSoleAvailable: Yup.boolean().required().default(false)
    })
}
