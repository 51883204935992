import { CopyAll, Preview } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { MouseEvent } from 'react';

export const ActionsRender = (params: any, openLink: any, copyPlanning: any) => {

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        openLink(`/planning/${params?.row?.id}`)
    }

    const handleDuplicate = () => {
        copyPlanning(params?.row)
    }

    return (
        <div>
            <Tooltip title="ver planeamento">
                <span>
                    <IconButton onClick={handleClick}>
                        <Preview />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="duplicar">
                <span>
                    <IconButton onClick={handleDuplicate}>
                        <CopyAll />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    )
}
