import { FC, ReactNode } from "react";
import { useCreateOneWorkOrderMutation, useDeleteOneWorkOrderMutation, useUpdateOneWorkOrderMutation, useWorkOrdersQuery } from "../../generated/graphql";
import { FilterConfig } from "../../hooks/useFilters";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const WorkOrdersDataSource = {
    createFunc: useCreateOneWorkOrderMutation,
    readFunc: useWorkOrdersQuery,
    updateFunc: useUpdateOneWorkOrderMutation,
    deleteFunc: useDeleteOneWorkOrderMutation,
}

interface WorkOrderProviderProps {
    children: ReactNode
    filterConfig: FilterConfig[]
}

const WorkOrderProvider: FC<WorkOrderProviderProps> = props => {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={WorkOrdersDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default WorkOrderProvider
