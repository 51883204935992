import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license-pro';
import pt from 'date-fns/locale/pt';
import { SnackbarProvider } from 'notistack';
import { parse, stringify } from 'query-string';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './App.css';
import AuthView from './components/AuthView/AuthView';
import Dashboard from './components/Dashboard/Dashboard';
import Definitions from './components/Definitions/Definitions';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import Layout from './components/Layout/Layout';
import Login from './components/Login/Login';
import NotFound from './components/NotFound/NotFound';
import Order from './components/Order/Order';
import OrdersView from './components/OrdersView/OrdersView';
import PlanningsView from './components/PlanningsView/PlanningsView';
import PlanningView from './components/PlanningView/PlanningView';
import ProfileView from './components/ProfileView/ProfileView';
import SectorView from './components/SectorView/SectorView';
import SetPassword from './components/SetPassword/SetPassword';
import SettingsView from './components/SettingsView/SettingsView';
import Status from './components/Status/Status';
import UserRedirect from './components/UserRedirect/UserRedirect';
import WorkOrdersView from './components/WorkOrders/WorkOrdersView';
import AuthProvider from './services/auth';
import { client } from './services/client';
import Config from './services/config';
import WorkspaceProvider from './services/workspace';

LicenseInfo.setLicenseKey(Config.MUI_LICENSE_KEY);


function App() {
    return (
        <ApolloProvider client={client}>
            <AuthProvider>
                <SnackbarProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
                        <BrowserRouter>
                            <QueryParamProvider
                                adapter={ReactRouter6Adapter}
                                options={{
                                    searchStringToObject: parse,
                                    objectToSearchString: stringify,
                                }}
                            >
                                <WorkspaceProvider>
                                    <Routes>
                                        <Route path="" element={<Layout><Dashboard /></Layout>} />
                                        <Route path="dashboard" element={<Layout><Dashboard /></Layout>} />
                                        <Route path="definitions" element={<Layout><Definitions /></Layout>} />
                                        <Route path="status" element={<Status />} />
                                        <Route path="login" element={<UserRedirect><AuthView><Login /></AuthView></UserRedirect>} />
                                        <Route path="forgot-password" element={<UserRedirect><AuthView><ForgotPassword /></AuthView></UserRedirect>} />
                                        <Route path="set-password" element={<UserRedirect><AuthView><SetPassword /></AuthView></UserRedirect>} />
                                        <Route path="plannings" element={<Layout><PlanningsView /></Layout>} />
                                        <Route path="orders" element={<Layout><OrdersView /></Layout>} />
                                        <Route path="order/:id" element={<Layout><Order /></Layout>} />
                                        <Route path="work-orders" element={<Layout><WorkOrdersView /></Layout>} />
                                        <Route path="planning/:id" element={<Layout><PlanningView /></Layout>} />
                                        <Route path="profile" element={<Layout><ProfileView /></Layout>} />
                                        <Route path="sector/" element={<Layout><SectorView /></Layout>} />
                                        <Route path="settings" element={<Layout><SettingsView /></Layout>} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </WorkspaceProvider>
                            </QueryParamProvider>
                        </BrowserRouter>
                    </LocalizationProvider>
                </SnackbarProvider>
            </AuthProvider>
        </ApolloProvider >
    );
}

export default App;
