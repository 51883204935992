import { format } from 'date-fns';
import { Maybe } from 'graphql/jsutils/Maybe';
import Config from '../../../services/config';
import { _get } from '../../../services/utils';

export const dateGetter = (params: any, field: string = "value"): Maybe<string> => {
    const value = _get(params, field)
    try {
        return format(value, Config.DATE_FORMAT)
    } catch (e) {
        return null
    }
}
