import { FC, ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Config from "../../services/config";

interface ProtectedProps {
    redirectTo?: string,
    children: ReactElement | null
}

// o atual sistema de autorização apenas distingue utilizadores
// autenticados e não autenticados, a partir do momento que um utilizador
// está autenticado ele pode aceder às routes protegidas
// contudo dentro dessas routes haverá uma autorização mais granular
// ultrapassar esta autorização não vai expor informação sensível
// é por essa razão que basta ter um token no local storage
const Protected: FC<ProtectedProps> = props => {
    const { children, redirectTo = Config.PROTECT_DEFAULT_REDIRECT_ROUTE } = props
    const location = useLocation()
    const token = localStorage.getItem(Config.AUTH_TOKEN_KEY)
    const state = { redirect: location.pathname }

    if (!token) {
        return <Navigate to={redirectTo} replace state={state} />;
    }

    return children;
}

export default Protected;
