import { FilterConfig } from '../../hooks/useFilters';
import { numberFilterConfig, relationFilterConfig } from '../../utils/filters';
import PlanningProvider from '../PlanningProvider/PlanningProvider';
import PlanningsActions from '../PlanningsActions/PlanningsActions';
import PlanningsFilters from '../PlanningsFilters/PlanningsFilters';
import PlanningsList from '../PlanningsList/PlanningsList';
import styles from './PlanningsView.module.scss';

const PlanningsView = () => {

    const filterConfig: FilterConfig[] = [
        relationFilterConfig({ name: "state" }),
        relationFilterConfig({ name: "planningState" }),
        relationFilterConfig({ name: "sector" }),
        numberFilterConfig({ name: "year" }),
        numberFilterConfig({ name: "week" }),
    ]

    return (
        <div className={styles.Wrapper}>
            <PlanningProvider filterConfig={filterConfig}>
                <PlanningsActions />
                <PlanningsFilters />
                <PlanningsList />
            </PlanningProvider>
        </div>
    )
}

export default PlanningsView
