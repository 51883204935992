import { Delete } from "@mui/icons-material"
import { Button, Card, CardContent, Grid, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC } from "react"
import { User } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { formInit } from "../../utils/forms"
import ConfirmButton from "../ConfirmButton/ConfirmButton"
import InputPasswordField from "../InputPasswordField/InputPasswordField"
import InputTextField from "../InputTextField/InputTextField"
import SectorField from "../SectorField/SectorField"
import { MoreMenu } from "../SettingsView/SettingsEditor"
import settingsStyles from '../SettingsView/SettingsView.module.scss'
import Show from "../Show/Show"
import UserRoleField from "../UserRoleField/UserRoleField"
import styles from './UserEditor.module.scss'
import userSchemaGetter from "./UserSchema"

interface UserEditorProps {
    selected: any
    store: any
}

interface UserFormProps {
    onCreate?: any
    onUpdate?: any
    onDelete?: any
    user?: Partial<User>
}

const UserForm: FC<UserFormProps> = props => {
    const { user, onCreate, onUpdate } = props
    const { initial, schema } = formInit<User>(userSchemaGetter, user)
    const action = user?.id ? "Guardar" : "Criar"

    const handleSubmit = (values: FormikValues) => {
        values?.id ? onUpdate(values) : onCreate(values)
    }

    return (
        <div>
            <Formik initialValues={initial} schema={schema} onSubmit={handleSubmit} enableReinitialize>
                {() => {
                    return (
                        <Form className={styles.CreateUserForm}>
                            <p><InputTextField name="name" label="nome" fullWidth /></p>
                            <p><InputTextField name="email" label="email" fullWidth /></p>
                            <Show on={!user?.id}>
                                <p><InputPasswordField /></p>
                            </Show>
                            <p><UserRoleField /></p>
                            <p><SectorField /></p>
                            <Grid container justifyContent="flex-end">
                                <Button type="submit" variant="contained" size="small">{action}</Button>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik >
        </div >
    )
}


const UserEditor: FC<UserEditorProps> = props => {
    const { selected, store } = props
    const { feedback, errorToast } = useToast()

    const handleDelete = async () => { }

    const handleCreate = async (user: Partial<User>) => {
        try {
            // @ts-ignore
            const response = await store?.create({ ...user, role: user?.role?.id, sector: user?.sector?.id })
            feedback(response, "Utilizador criado", "Erro ao criar utilizador")
        } catch (error: unknown) {
            errorToast("Erro desconhecido")
        }
    }

    const handleUpdate = async (user: Partial<User>) => {
        const { id, ...data } = user
        try {
            // @ts-ignore
            const response = await store?.update(id, { ...data, role: user?.role?.id, sector: user?.sector?.id })
            console.log("response", response)
            feedback(response, "Utilizador atualizado", "Erro ao atualizar utilizador")
        } catch (error: unknown) {
            console.log("error", error)
            errorToast("Erro desconhecido")
        }
    }

    return (
        <Card className={settingsStyles.SettingsEditor}>
            <CardContent>
                <Grid container justifyContent="space-between">
                    <Typography variant="h4" color="primary">Criar</Typography>
                    <MoreMenu>
                        <MenuItem disabled={true}>
                            <ListItemIcon>
                                <ConfirmButton onClick={() => handleDelete()}>
                                    <Delete fontSize="small" />
                                </ConfirmButton>
                            </ListItemIcon>
                            <ListItemText>
                                Remover
                            </ListItemText>
                        </MenuItem>


                    </MoreMenu>
                </Grid>

                {
                    !selected
                        ?
                        <UserForm onCreate={handleCreate} />
                        :
                        <UserForm user={selected} onUpdate={handleUpdate} />
                }

            </CardContent>
        </Card >

    )
}

export default UserEditor
