import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { UseFiltersOnChange } from '../../hooks/useFilters';

export interface SelectEnumFilterOption {
    id: string
    [key: string]: string
}

interface SelectEnumFilterProps {
    value: string[]
    onChange: UseFiltersOnChange
    multiple?: boolean
    label?: string
    options: SelectEnumFilterOption[]
    field: string
    filter?: any
    sorting?: any
    paging?: any
    allOptionName?: string
    hideAllOption?: boolean
}

export default function SelectEnumFilter(props: SelectEnumFilterProps) {
    const {
        value,
        onChange,
        multiple = false,
        label,
        field,
        options,
        allOptionName = "TODOS",
        hideAllOption = false
    } = props


    const inputProps = { disableUnderline: true }

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value }, } = event;
        onChange(field, value)
    }

    const findById = (id: string) => {
        return options.find((obj: any) => obj?.id === id)
    }

    const render = (selected: string[]) => {
        const first = selected[0]
        const obj = findById(first)
        return selected.length === 1 ? obj?.name : obj?.name + '...'
    }

    return (
        <FormControl sx={{ m: 1, width: 200 }}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                multiple={multiple}
                value={value}
                onChange={handleChange}
                input={<Input {...inputProps} />}
                renderValue={render}
                autoWidth={false}
            >
                {!multiple && <MenuItem key={"__all__"} value={""}>
                    <ListItemText primary={<em>{!hideAllOption ? allOptionName : ""}</em>} />
                </MenuItem>}

                {options?.length && options?.map((option: SelectEnumFilterOption) => (
                    <MenuItem key={option.id} value={option.id}>
                        {multiple && <Checkbox checked={value.includes(option.id)} />}
                        <ListItemText primary={option.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}
