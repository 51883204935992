import { FC, ReactNode } from 'react'
import styles from './ActionsBar.module.scss'

interface ActionsBarProps {
    children: ReactNode
}

const ActionsBar: FC<ActionsBarProps> = props => {

    const { children } = props

    return (
        <div className={styles.ActionsBar}>
            {children}
        </div >
    )
}


export default ActionsBar
