import { Save } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useFormikContext } from "formik"
import { FC } from "react"

interface SubmitButtonProps {
    tooltip?: string
}

const SubmitButton: FC<SubmitButtonProps> = props => {
    const { tooltip = "" } = props
    const context = useFormikContext()
    const { isValid, isSubmitting } = context
    const color = isValid ? "primary" : "error"

    return (
        <Tooltip title={tooltip}>
            <IconButton size="small" color={color} type="submit" disabled={isSubmitting}>
                <Save />
            </IconButton>
        </Tooltip>
    )
}

export default SubmitButton
