import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import { FastField } from 'formik';
import { Autocomplete } from 'formik-mui';
import { CuttingSystem } from '../../generated/graphql';
import { useShowFieldError } from '../../hooks/useShowFieldError';
import { FormFieldProps } from '../../types/types';
import { enumToOptions } from '../../utils/filters';

export default function CuttingSystemField(props: FormFieldProps) {
    const {
        name = "cuttingSystem",
        label = "Sistema Corte",
        textFieldProps = { variant: "standard" }
    } = props

    const options = enumToOptions(CuttingSystem, "label")
    const { error, helperText } = useShowFieldError(name)

    return (
        <FastField
            name={name}
            component={Autocomplete}
            disableClearable
            options={options}

            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    label={label}
                    {...textFieldProps}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    )
}
