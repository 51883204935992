import { useClientsQuery } from '../../generated/graphql';
import { FilterProps } from '../../types/types';
import { SelectFilter } from '../SelectFilter/SelectFilter';

export default function ClientFilter(props: FilterProps) {
    const { value, onChange } = props
    const query = useClientsQuery

    return (
        <SelectFilter
            value={value}
            onChange={onChange}
            label="Cliente"
            query={query}
            field="client"
            sorting={{ field: "name", direction: "ASC" }}
            paging={{ limit: 9999 }}
            multiple={true}
        />
    )
}
