import { useState } from "react"
import { ArrayParam } from "use-query-params"
import { usePlanningsQuery } from "../../generated/graphql"
import { FilterConfig } from "../../hooks/useFilters"
import { useSectorId } from "../../hooks/useSector"
import { getCurrentIsoWeek, getCurrentIsoYear } from "../../utils/dates"
import { numberFilterConfig, relationFilterConfig } from "../../utils/filters"
import { clientFkFunc } from "../../utils/lookups"
import PlanningFilters from "../PlanningFilters/PlanningFilters"
import PlanningList from "../PlanningList/PlanningList"
import PlanningRowProvider from "../PlanningRowProvider/PlanningRowProvider"
import SectorActions from "../SectorActions/SectorActions"
import styles from './SectorView.module.scss'

const PlanningWrapper = (props: any) => {
    const { planning, weekYear, setWeekYear } = props

    const filterConfig: FilterConfig[] = [
        relationFilterConfig({ name: "planning", defaultValue: [planning?.id] }),
        relationFilterConfig({ name: "leather" }),
        relationFilterConfig({ name: "model" }),
        relationFilterConfig({ name: "mold" }),
        relationFilterConfig({ name: "planningRowState" }),
        numberFilterConfig({ name: "priority" }),
        { name: "client", variant: ArrayParam, defaultValue: [], lookupExpFunc: clientFkFunc, alias: "order" }
    ]

    return (
        <PlanningRowProvider filterConfig={filterConfig}>
            <SectorActions weekYear={weekYear} setWeekYear={setWeekYear} />
            <PlanningFilters />
            <PlanningList planning={planning} />
        </PlanningRowProvider>
    )
}


const SectorView = () => {
    const currentWeek = getCurrentIsoWeek()
    const currentYear = getCurrentIsoYear()
    const [week, setWeek] = useState<number>(currentWeek)
    const [year, setYear] = useState<number>(currentYear)
    const sectorId = useSectorId()
    const { data } = usePlanningsQuery({ variables: { filter: { week: { eq: week }, year: { eq: year }, sector: { id: { eq: sectorId } } } } })

    const handleSetWeekYear = (_: string, weekYear: string) => {
        const [week, year] = weekYear.split("/")
        setWeek(+week)
        setYear(+year)
    }

    return (
        <div className={styles.Wrapper}>
            <PlanningWrapper planning={data?.plannings?.nodes[0]} weekYear={`${week}/${year}`} setWeekYear={handleSetWeekYear} />
        </div>
    )
}

export default SectorView
