import { FormikValues } from "formik"
import { noop } from "../services/utils"
import { createPayload, updatePayload } from "../utils/payloads"
import translate from "../utils/translate"
import useToast from "./useToast"

interface useMakeFormSubmitParams {
    context: any,
    entity: string
    onSuccess: () => any
}

const useMakeFormSubmit = (params: useMakeFormSubmitParams) => {
    const { context, entity, onSuccess = noop } = params
    const { errorToast, successToast } = useToast()

    const createSuccessMessage = `${translate(entity)} criado`
    const createErrorMessage = `Erro ao criar ${translate(entity)}`
    const updateSuccessMessage = `${translate(entity)} atualizado`
    const updateErrorMessage = `Erro ao atualizar ${translate(entity)}`


    const handleCreate = async (values: FormikValues) => {
        try {
            const payload = createPayload(values, entity)
            const response = await context?.create(payload)
            if (!response?.errors?.length) {
                successToast(createSuccessMessage)
                onSuccess()
            } else {
                errorToast(createErrorMessage)
            }
        } catch (e: unknown) {
            errorToast(createErrorMessage)
        }
    }

    const handleUpdate = async (values: FormikValues) => {
        try {
            const payload = updatePayload(values)
            const response = await context?.update(payload)
            if (!response?.errors?.length) {
                successToast(updateSuccessMessage)
                onSuccess()
            } else {
                errorToast(updateErrorMessage)
            }
        } catch (e: unknown) {
            errorToast(updateErrorMessage)
        }
    }

    return (values: FormikValues) => {
        values?.id ? handleUpdate(values) : handleCreate(values)
    }
}

export default useMakeFormSubmit
