import { FilterConfig } from "../../hooks/useFilters"

export const filterBuilder = (filterConfig?: FilterConfig[], filters?: any) => {
    const filterQuery = {}

    filterConfig?.map((filter: any) => {
        const filtername = filter.name

        if (filters && filters[filtername]) {
            // filtros que tem lookup expression functions
            // serve para contruir queries que são mais complexos
            // do que o default id : {in []}
            if (filter.lookupExpFunc) {
                // podemos ter um filtro mas precisar de criar
                // um query em que nome do filtro é outro
                // acontece por exemplo com o filtro weekFilter
                const name = filter?.alias || filtername
                // @ts-ignore
                filterQuery[name] = filter.lookupExpFunc(filters[filtername])
            } else {
                // os que não têm um lookup expression function, por defeito usam a expressão em baixo
                // garantir que a array não está vazia antes de fazer o query
                if (filters[filtername].length) {
                    // @ts-ignore
                    filterQuery[filtername] = { id: { in: filters[filtername] } }
                }
            }
        }
    })

    return filterQuery
}

export const pagingBuilder = (paginationModel: any) => {
    const { page, pageSize } = paginationModel
    const offset = page * pageSize
    return { limit: pageSize, offset }
}

export const sortingBuilder = (sortModel: any) => {

    if (sortModel?.length) {
        return sortModel.map((obj: any) => {
            return { field: obj.field, direction: obj.sort.toUpperCase() }
        })
    }

    return [{ field: "id", direction: "ASC" }]
}


// E#1061
export const queryBuilder = (filterConfig?: FilterConfig[], filters?: any, paginationModel?: any, sortModel?: any) => {

    const filter = filterBuilder(filterConfig, filters)

    let variables: any = {
        sorting: sortingBuilder(sortModel),
        paging: pagingBuilder(paginationModel)
    }

    if (Object.keys(filter).length) {
        variables["filter"] = filter
    }

    return { variables }
}

export default queryBuilder
