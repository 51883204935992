import { Add, Delete, PlaylistAdd, Publish } from '@mui/icons-material';
import { Button, Grid, IconButton, SelectChangeEvent, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { useCreateManyPlanningRowsMutation, useDeleteManyWorkOrdersMutation } from '../../generated/graphql';
import useToast from '../../hooks/useToast';
import ActionsBar from '../ActionsBar/ActionsBar';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import CustomModal from '../CustomModal/CustomModal';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import ImportWorkOrders from '../ImportWorkOrders/ImportWorkOrders';
import PlanningCustomSelector from '../PlanningCustomSelector/PlanningCustomSelector';
import PopAction from '../PopAction/PopAction';
import Show from '../Show/Show';
import TemporaryDrawer from '../TemporaryDrawer/TemporaryDrawer';
import WorkOrderForm from '../WorkOrderForm/WorkOrderForm';
import styles from './WorkOrdersActions.module.scss';

export default function WorkOrdersActions() {
    const [open, setOpen] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const context = useContext(DataViewContext)
    const [deleteWorkOrders] = useDeleteManyWorkOrdersMutation()
    const { feedback, successToast } = useToast()
    const [popAction, setPopAction] = useState<boolean>(false)
    const [planning, setPlanning] = useState<string>("")
    const [createPlanningRows] = useCreateManyPlanningRowsMutation()


    const handleDelete = async () => {
        const selection = context.rowSelectionModel
        const response = await deleteWorkOrders({ variables: { input: { filter: { id: { in: selection } } } } })
        feedback(!response?.errors?.length, "Ordens removidas com sucesso", "Erro ao remover ordens de fabrico")
        context.refetch()
    }

    const handleAddToPlanning = async () => {
        const selection = context.rowSelectionModel
        const planningRows = selection.map((selected: number) => { return { planning, workOrder: selected } })
        const response = await createPlanningRows({ variables: { input: { planningRows } } })
        if (!response?.errors?.length) {
            successToast("Ordens de fabrico associadas")
            setPopAction(false)
            context?.clearSelection()
        }
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        setPlanning(event.target.value)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <ActionsBar>
            <Grid container justifyContent="flex-end" alignContent="center" className={styles.Wrapper}>
                <Show on={context?.rowSelectionModel?.length}>
                    <Tooltip title="adicionar a planeamento">
                        <PopAction name="addToPlanning" icon={<PlaylistAdd />} open={popAction} setOpen={setPopAction}>
                            <Grid container alignItems="center">
                                <PlanningCustomSelector value={planning} onChange={handleChange} />
                                <Button onClick={handleAddToPlanning}>Adicionar</Button>
                            </Grid>
                        </PopAction>
                    </Tooltip>
                    <Tooltip title="remover">
                        <ConfirmButton onClick={handleDelete}>
                            <Delete />
                        </ConfirmButton>
                    </Tooltip>
                </Show>
                <Tooltip title="importar">
                    <IconButton onClick={() => setOpen(true)}>
                        <Publish />
                    </IconButton>
                </Tooltip>
                <Tooltip title="criar encomenda">
                    <IconButton onClick={() => setIsOpen(true)}>
                        <Add />
                    </IconButton>
                </Tooltip>
            </Grid>
            <CustomModal isActive={open} handleClose={handleClose} maxWidth="lg">
                <ImportWorkOrders onClose={handleClose} />
            </CustomModal>
            <TemporaryDrawer isOpen={isOpen} setIsOpen={setIsOpen} hideBackdrop={false}>
                <WorkOrderForm onSuccess={() => setIsOpen(false)} />
            </TemporaryDrawer>
        </ActionsBar>
    )
}
