import { FC, ReactNode } from "react";
import { usePlanningsQuery, useCreateOnePlanningMutation, useDeleteOnePlanningMutation, useUpdateOnePlanningMutation } from "../../generated/graphql";
import { FilterConfig } from "../../hooks/useFilters";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const PlanningsDataSource = {
    createFunc: useCreateOnePlanningMutation,
    readFunc: usePlanningsQuery,
    updateFunc: useUpdateOnePlanningMutation,
    deleteFunc: useDeleteOnePlanningMutation,
}

interface PlanningProviderProps {
    children: ReactNode
    filterConfig: FilterConfig[]
}

const PlanningProvider: FC<PlanningProviderProps> = props => {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={PlanningsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default PlanningProvider
