import { SvgIconComponent, Upcoming, Grass } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { ReactNode, createElement } from "react";
import styles from './DataListHeader.module.scss'

interface DataListHeaderProps {
    name: string
    switcher?: ReactNode
    icon?: SvgIconComponent
}

export default function DataListHeader(props: DataListHeaderProps) {
    const { name, switcher = null, icon = Grass } = props
    return (
        <Grid container justifyContent="space-between" alignItems="center" className={styles.DataListHeader}>
            <Grid item xs={6}>

                <Grid container alignItems="center">
                    {createElement(icon, { color: "primary", style: { marginRight: 10, fontSize: 28 } })}
                    <Typography variant="h4" color="primary">
                        {name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                {switcher}
            </Grid>
        </Grid>
    )
}
