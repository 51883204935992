import { useCreateOneClientMutation, useDeleteOneClientMutation, useClientsQuery, useUpdateOneClientMutation } from "../../../../generated/graphql"

export default function useClientStore() {
    const query = useClientsQuery
    const name = "client"
    const [create] = useCreateOneClientMutation()
    const [update] = useUpdateOneClientMutation()
    const [remove] = useDeleteOneClientMutation()
    return { query, name, create, update, remove }
}
