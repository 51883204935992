import { FormControl, Input, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { PlanningStateSortFields, SortDirection, usePlanningStatesQuery } from '../../generated/graphql';

export default function PlanningStateCustomSelector(props: any) {
    const { value, onChange } = props
    const { data } = usePlanningStatesQuery({ variables: { paging: { limit: 999 }, sorting: [{ field: PlanningStateSortFields.Name, direction: SortDirection.Asc }] } })

    const nodes = data?.planningStates?.nodes
    const inputProps = { disableUnderline: true }

    const render = (selected: string): string => {
        const planning = nodes?.find(node => node.id === selected)
        return planning ? getListItemText(planning) : ""
    }

    const getListItemText = (node: any): string => {
        return `${node?.name}`
    }

    return (
        <FormControl sx={{ m: 1, width: 200 }}>
            {!value ? <InputLabel>Estado</InputLabel> : null}
            <Select
                value={value}
                onChange={onChange}
                input={<Input {...inputProps} />}
                renderValue={render}
                autoWidth={false}
            >
                {nodes?.length && nodes?.map((node: any) => (
                    <MenuItem key={node?.id} value={node?.id}>
                        <ListItemText primary={getListItemText(node)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}
