const isUppercase = (word: string) => {
    return word === word.toUpperCase()
}

const isLowercase = (word: string) => {
    return word === word.toLowerCase()
}

const capitalize = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

// há a possibilidade de ter um outro casing ex:
// CaSinG, isto não é suportado
const getCasing = (word: string) => {
    if (isUppercase(word)) {
        return "uppercase"
    } else if (isLowercase(word)) {
        return "lowercase"
    } else {
        return "capitalize"
    }
}

const toCasing = (word: string, casing: string) => {
    if (casing === "uppercase") return word.toUpperCase()
    if (casing === "lowercase") return word.toLowerCase()
    if (casing === "capitalize") return capitalize(word)
}

export default function translate(word?: string, casing?: "uppercase" | "capitalize" | "lowercase") {

    if (!word) return null

    let originalCasing = getCasing(word)
    let _casing = casing ? casing : originalCasing

    const dictionary: any = {
        draft: "rascunho",
        approved: "aprovado",
        canceled: "cancelado",
        finished: "terminado",
        manager: "gestor",
        overseer: "encarregado",
        planning: "planeamento"
    }

    let translated = dictionary[word.toLowerCase()]

    if (!translated) return null

    return toCasing(translated, _casing)
}
