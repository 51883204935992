import { Block, CheckCircleOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Config from "../../services/config";

interface IsAvailableProps {
    state?: boolean
}

export default function IsAvailable(props: IsAvailableProps) {
    const { state } = props
    if (state === undefined || state === null) return null

    const Icon = state ? CheckCircleOutline : Block
    const color = state ? Config.COLORS.GREEN : Config.COLORS.RED
    const title = state ? "Disponível" : "Indisponível"

    return (
        <Tooltip title={title}>
            <Icon fontSize="small" style={{ color }} />
        </Tooltip>
    )
}
