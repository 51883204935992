import { Field, useField } from 'formik';
import { Switch } from 'formik-mui';
import { FormFieldProps } from '../../types/types';

export default function IsSoleAvailableField(props: FormFieldProps) {
    const {
        name = "isSoleAvailable",
    } = props

    const [field] = useField(name);
    const label = field.value ? "Sola Disponível" : "Sola Indisponível"

    return (
        <span>
            <Field
                name={name}
                component={Switch}
                type="checkbox"
            />
            <span>{label}</span>
        </span>
    )
}
