import { Add, Delete } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { useDeleteManyOrdersMutation } from '../../generated/graphql';
import useToast from '../../hooks/useToast';
import ActionsBar from '../ActionsBar/ActionsBar';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import OrderForm from '../OrderForm/OrderForm';
import Show from '../Show/Show';
import TemporaryDrawer from '../TemporaryDrawer/TemporaryDrawer';
import styles from './OrdersActions.module.scss';

export default function OrdersActions() {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const context = useContext(DataViewContext)
    const [deleteMany] = useDeleteManyOrdersMutation()
    const { feedback } = useToast()

    const handleDelete = async () => {
        const selection = context.rowSelectionModel
        const response = await deleteMany({ variables: { input: { filter: { id: { in: selection } } } } })
        feedback(!response?.errors?.length, "Encomendas removidas", "Erro ao remover encomendas")
        context.refetch()
    }

    return (
        <ActionsBar>
            <Grid container justifyContent="flex-end" alignContent="center" className={styles.Wrapper}>
                <Show on={context?.rowSelectionModel?.length}>
                    <Tooltip title="remover">
                        <ConfirmButton onClick={handleDelete}>
                            <Delete />
                        </ConfirmButton>
                    </Tooltip>
                </Show>
                <Tooltip title="criar encomenda">
                    <IconButton onClick={() => setIsOpen(true)}>
                        <Add />
                    </IconButton>
                </Tooltip>
            </Grid>
            <TemporaryDrawer isOpen={isOpen} setIsOpen={setIsOpen} hideBackdrop={false}>
                <OrderForm onSuccess={() => setIsOpen(false) } />
            </TemporaryDrawer>
        </ActionsBar>
    )
}
