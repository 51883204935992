import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { Planning, useDuplicatePlanningMutation } from "../../generated/graphql"
import useToast from "../../hooks/useToast"
import { formInit } from "../../utils/forms"
import InputNumberField from "../InputNumberField/InputNumberField"
import planningSchemaGetter from "../PlanningForm/PlanningFormInit"
import SectorField from "../SectorField/SectorField"
import SubmitButton from "../SubmitButton/SubmitButton"
import styles from './DuplicatePlanning.module.scss'

interface DuplicatePlanningProps {
    planning: Partial<Planning>
    onSuccess: any
}

export default function DuplicatePlanning(props: DuplicatePlanningProps) {
    const { planning, onSuccess } = props
    const [duplicate] = useDuplicatePlanningMutation()
    const { schema, initial } = formInit<Planning>(planningSchemaGetter, planning)
    const { successToast, errorToast } = useToast()


    const handleDuplicate = async (values: FormikValues) => {
        const { week, year, sector } = values

        if (!planning?.id) return

        try {
            const response = await duplicate({
                variables: {
                    input: {
                        planning: planning.id,
                        week,
                        year,
                        sector: sector?.id
                    }
                }
            })
            const id = response?.data?.duplicatePlanning?.id
            if (id) {
                successToast("Planeamento duplicado, novo planeamento tem id " + id)
                onSuccess()
            }
        } catch (e: unknown) {
            console.log("e", e)
            errorToast("Erro ao duplicar planeamento. Só pode existir um planeamento por semana para cada setor.")
        }
    }

    return (
        <div>
            <Typography variant="h6" color="primary" gutterBottom>
                Duplicar Planeamento
            </Typography>
            <Formik initialValues={initial} onSubmit={handleDuplicate} validationSchema={schema} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                {() => {
                    return (
                        <Form>
                            <TableContainer>
                                <Table sx={{ minWidth: 400 }} className={styles.Table}>
                                    <TableBody>
                                        <TableRow key={1}>
                                            <TableCell>
                                                <InputNumberField name="week" label="semana" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={2}>
                                            <TableCell>
                                                <InputNumberField name="year" label="ano" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={3}>
                                            <TableCell>
                                                <SectorField />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container justifyContent="flex-end">
                                <SubmitButton />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
