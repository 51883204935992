import { useSolesQuery } from '../../generated/graphql';
import config from '../../services/config';
import { FormFieldProps } from '../../types/types';
import SelectAutocompleteField from '../SelectAutocompleteField/SelectAutocompleteField';

export default function SoleField(props: FormFieldProps) {
    const {
        name = "sole",
        label = "Sola",
        textFieldProps = { variant: config.DEFAULT_TEXTFIELD_VARIANT },
        disabled = false,
        limit = config.MAX_RESULTS_SIZE
    } = props
    return (
        <SelectAutocompleteField
            name={name}
            label={label}
            query={useSolesQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={{
                limit
            }}
        />
    )
}
