import { Event } from '@mui/icons-material';
import { Fade } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Planning, useDuplicatePlanningMutation } from '../../generated/graphql';
import Config from '../../services/config';
import CustomModal from '../CustomModal/CustomModal';
import Datagrid from '../Datagrid/Datagrid';
import DataListHeader from '../DataListHeader/DataListHeader';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import DuplicatePlanning from '../DuplicatePlanning/DuplicatePlanning';
import PlanningForm from '../PlanningForm/PlanningForm';
import TemporaryDrawer from '../TemporaryDrawer/TemporaryDrawer';
import getPlanningColumns from './PlanningColumns';
import styles from './PlanningsList.module.scss';

const PlanningsList = () => {
    const context = useContext(DataViewContext)
    const nodes = context?.data?.plannings?.nodes
    const rows = nodes || []
    const [detailRow, setDetailRow] = useState<any>(null)
    const [duplicateRow, setDuplicateRow] = useState<any>(null)

    const navigate = useNavigate()

    const openLink = (link: string) => {
        navigate(link)
    }

    useEffect(() => {
        context.setView("planningsView")
        context.setColumnDefs(getPlanningColumns(openLink, duplicatePlanning))
    }, [])

    const handleRowSelectionChange = (newRowSelectionModel: any) => {
        context?.setRowSelectionModel(newRowSelectionModel);
    }

    const handleCellClick = (params: any) => {
        if (params?.field === 'id') {
            setDetailRow(params.row)
        }
    }

    const duplicatePlanning = async (planning: Planning) => {
        setDuplicateRow(planning)
    }

    const handleSuccess = () => {
        context?.refetch()
        setDuplicateRow(null)
    }

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.PlanningsLists}>
                <DataListHeader name="Planeamentos" icon={Event} />
                <Datagrid
                    rows={rows}
                    columns={context?.columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                    setColumnOrderModel={context?.setColumnOrderModel}
                    columnVisibilityModel={context?.columnVisibilityModel}
                    setColumnVisibilityModel={context?.setColumnVisibilityModel}
                    onRowSelectionModelChange={handleRowSelectionChange}
                    onCellClick={handleCellClick}
                    disableSelectionOnClick={true}
                />
                <TemporaryDrawer isOpen={!!detailRow} setIsOpen={setDetailRow} hideBackdrop={false}>
                    <PlanningForm planning={detailRow} onSuccess={() => setDetailRow(null)} />
                </TemporaryDrawer>
                <CustomModal isActive={!!duplicateRow} handleClose={() => setDuplicateRow(false)}>
                    <DuplicatePlanning planning={duplicateRow} onSuccess={handleSuccess} />
                </CustomModal>
            </div>
        </Fade>
    );
}

export default PlanningsList
