import { MailOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { useSendResetPasswordEmailMutation } from '../../generated/graphql';
import FieldText from '../FieldText/FieldText';
import styles from './ForgotPassword.module.scss';

const Feedback = () => {
    return (
        <div className={styles.Feedback}>
            <Typography variant="h4" color="primary">Email Enviado</Typography>
            <Typography variant="subtitle1" color="text" gutterBottom>
                Se a sua conta estiver associada a este email vai receber nos próximos instantes um email com instruções para redefinir a sua senha.
            </Typography>
            <Typography variant="subtitle1" color="text">
                Por favor verifique o seu email, inclusive a pasta SPAM.
            </Typography>
        </div>
    )
}

const ForgotPassword = () => {

    const [sendResetEmail] = useSendResetPasswordEmailMutation()
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);
    const navigate = useNavigate()

    const schema = Yup.object().shape({
        email: Yup.string().email().required().default(""),
    })

    const handleSubmit = async (values: any) => {
        const response = await sendResetEmail({ variables: { email: values.email } });
        if (!response?.data?.sendResetPasswordEmail) {
            setErrorMessage("Não foi possível enviar email. Tente mais tarde por favor.");
        } else {
            setSubmitted(true)
        }
    }

    const handleGoBack = () => {
        navigate(-1)
    }


    if (submitted) return <Feedback />

    return (
        <Formik onSubmit={handleSubmit} initialValues={schema.cast({})} validationSchema={schema}>
            {({ isSubmitting }) => (
                <Form id={styles.ResetPasswordForm}>
                    <Typography variant="h4" color="primary">Recuperar Senha</Typography>

                    <div>
                        <div>{errorMessage}</div>
                        <Typography variant="subtitle1" color="primary">Por favor introduza o email associado à sua conta para receber um email com instruções para repor senha.</Typography>

                        <br />
                        <br />

                        <Field
                            component={FieldText}
                            label="Email"
                            name="email"
                            required
                            fullWidth
                            autoFocus
                            autoComplete="current-username"
                            InputProps={{
                                startAdornment: <MailOutlined color="primary" />
                            }}

                        />

                        <br />
                        <br />

                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            enviar email
                        </Button>
                        <Button onClick={handleGoBack} variant="text" color="inherit" style={{ marginRight: 20 }}>
                            Voltar
                        </Button>

                    </div>
                </Form>
            )}
        </Formik >
    );
};

export default ForgotPassword
