import { Grid, Typography } from "@mui/material";
import { Maybe, Planning } from "../../generated/graphql";
import { DeepPartial } from "../../types/types";
import styles from './PlanningListHeader.module.scss';

interface PlanningListHeaderProps {
    planning?: Maybe<DeepPartial<Planning>>
}

export default function PlanningListHeader(props: PlanningListHeaderProps) {
    const { planning } = props

    const subheader = planning ? `${planning?.week}/${planning?.year} - ${planning?.sector?.name} - ${planning?.planningState?.name}` : "Não existe ainda planeamento"

    return (
        <Grid container justifyContent="space-between" alignItems="center" className={styles.PlanningListHeader}>
            <Grid item xs={6}>
                <Typography variant="h4" color="primary">
                    Planeamento
                </Typography>
                <Typography variant="h6" color="text" style={{ color: "#4a4a4a" }}>
                    {subheader}
                </Typography>
                <div></div>
            </Grid>
        </Grid>
    )
}
