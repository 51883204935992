import { toNumberArray } from "../services/utils"

export const clientFkFunc = (value: any) => {
    if (!value?.length) return
    return { clientFk: { in: toNumberArray(value) } }
}

export const searchExpFunc = (value: any) => {
    return {
        iLike: `%${value}%`
    }
}

export const boolLookupExpression = (value: string[]) => {
    if (value?.length) return { in: value }
}

export const numberLookupExpression = (value: string[]) => {
    if (value?.length) return { in: value.map(val => +val) }
}
