import { ArrowBack } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

interface BackButtonProps {
    route?: string
    routeSelector?: () => string
}

/**
 * botão que por defeito vai para a página anterior
 * do histórico, mas que aceita uma route ou uma função
 * para determinar uma route com lógica própria
 */
const BackButton: FC<BackButtonProps> = props => {

    const { route, routeSelector } = props
    const navigate = useNavigate()

    const getRoute = () => {
        return routeSelector ? routeSelector() : route
    }

    const handle = () => {
        const _route = getRoute()
        // navigate tem dois overloads, numa
        // o argumento é um number noutro é um To
        // que aceita entre outras coisas uma string
        _route ? navigate(_route) : navigate(-1)
    }

    return (
        <IconButton onClick={handle}>
            <ArrowBack />
        </IconButton>
    )
}


export default BackButton
