import { Add, Delete, Transform } from '@mui/icons-material';
import { Button, Grid, IconButton, SelectChangeEvent, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { useDeleteManyPlanningsMutation, useUpdateManyPlanningsMutation } from '../../generated/graphql';
import useToast from '../../hooks/useToast';
import ActionsBar from '../ActionsBar/ActionsBar';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import PlanningForm from '../PlanningForm/PlanningForm';
import PlanningStateCustomSelector from '../PlanningStateCustomSelector/PlanningStateCustomSelector';
import PopAction from '../PopAction/PopAction';
import Show from '../Show/Show';
import TemporaryDrawer from '../TemporaryDrawer/TemporaryDrawer';
import styles from './PlanningsActions.module.scss';

export default function PlanningsActions() {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const context = useContext(DataViewContext)
    const [deletePlannings] = useDeleteManyPlanningsMutation()
    const [popActionState, setPopActionState] = useState<boolean>(false)
    const [planningState, setPlanningState] = useState<string>("")
    const { feedback, errorToast, successToast } = useToast()
    const [updatePlannings] = useUpdateManyPlanningsMutation()

    const handleStateChange = (event: SelectChangeEvent<string>) => {
        setPlanningState(event.target.value)
    }

    const handleDelete = async () => {
        const selection = context.rowSelectionModel
        try {
            const response = await deletePlannings({ variables: { input: { filter: { id: { in: selection } } } } })
            feedback(!response?.errors?.length, "Planeamentos removidos com sucesso", "Erro ao remover planeamentos")
            context.refetch()
        } catch (e: unknown) {
            errorToast("Erro! Só é possível remover planeamentos vazios")
        }
    }

    const updateState = async () => {
        const selection = context.rowSelectionModel
        const response = await updatePlannings({ variables: { input: { filter: { id: { in: selection } }, update: { planningState } } } })
        if (!response?.errors?.length) {
            successToast("Estado atualizado")
            setPopActionState(false)
            setPlanningState("")
            context?.clearSelection()
        }
    }

    return (
        <ActionsBar>
            <Grid container justifyContent="flex-end" alignContent="center" className={styles.Wrapper}>
                <Tooltip title="estado">
                    <PopAction name="state" icon={<Transform />} open={popActionState} setOpen={setPopActionState}>
                        <Grid container alignItems="center">
                            <PlanningStateCustomSelector value={planningState} onChange={handleStateChange} />
                            <Button onClick={updateState}>Atualizar</Button>
                        </Grid>
                    </PopAction>
                </Tooltip>
                <Show on={context?.rowSelectionModel?.length}>
                    <Tooltip title="remover">
                        <ConfirmButton onClick={handleDelete}>
                            <Delete />
                        </ConfirmButton>
                    </Tooltip>
                </Show>
                <Tooltip title="criar planeamento">
                    <IconButton onClick={() => setIsOpen(true)}>
                        <Add />
                    </IconButton>
                </Tooltip>
            </Grid>
            <TemporaryDrawer isOpen={isOpen} setIsOpen={setIsOpen} hideBackdrop={false}>
                <PlanningForm onSuccess={() => setIsOpen(false)} />
            </TemporaryDrawer>
        </ActionsBar>
    )
}
