import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { useContext } from "react"
import { PlanningRow } from "../../generated/graphql"
import useMakeFormSubmit from "../../hooks/useMakeFormSubmit"
import { noop } from "../../services/utils"
import { formInit } from "../../utils/forms"
import { DataViewContext } from "../DataViewProvider/DataViewProvider"
import InputNumberField from "../InputNumberField/InputNumberField"
import InputTextField from "../InputTextField/InputTextField"
import SubmitButton from "../SubmitButton/SubmitButton"
import styles from './PlanningRowForm.module.scss'
import planningRowSchemaGetter from "./PlanningRowSchema"

interface PlanningRowFormProps {
    planningRow?: Partial<PlanningRow>
    onSuccess?: () => any
}

export default function PlanningRowForm(props: PlanningRowFormProps) {
    const { planningRow, onSuccess = noop } = props
    const context = useContext(DataViewContext)
    const { schema, initial } = formInit<PlanningRow>(planningRowSchemaGetter, planningRow)
    const handleSubmit = useMakeFormSubmit({ context, entity: "planningRow", onSuccess })

    const formName = planningRow?.id ? "Editar" : "Criar"

    return (
        <div className={styles.TaskFormWrapper}>
            <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                {() => {
                    return (
                        <Form>
                            <Grid container direction="column" className={styles.Section}>
                                <Typography variant="h4" color="primary" gutterBottom>
                                    {formName}
                                </Typography>
                            </Grid>

                            <TableContainer>
                                <Table sx={{ minWidth: 400 }} className={styles.Table}>
                                    <TableBody>
                                        <TableRow key={1}>
                                            <TableCell>
                                                <InputTextField name="observations" label="observações" fullWidth multiline minRows={1} />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={2}>
                                            <TableCell>
                                                <InputNumberField name="priority" label="prioridade" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container justifyContent="flex-end">
                                <SubmitButton />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
