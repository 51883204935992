import { getISOWeek, getISOWeeksInYear, getISOWeekYear } from 'date-fns';

// @todo - precisa de testes para os edge cases
export const getCurrentIsoYear = (): number => {
    return getISOWeekYear(new Date())
}

// @todo - precisa de testes para os edge cases
export const getCurrentIsoWeek = (): number => {
    return getISOWeek(new Date())
}

export const parseWeekValue = (value: string) => {
    const [week, year] = value?.split("/")
    return {
        week: +week, year: +year
    }
}

export const getLastISOWeekOfYear = (year: number) => {
    return getISOWeeksInYear(new Date(year, 6, 6))
}


export const previousISOWeek = (year: number, week: number) => {
    if (week === 1) {
        return { year: year - 1, week: getLastISOWeekOfYear(year - 1) }
    }
    return { year, week: week - 1 }
}

export const nextISOWeek = (year?: number, week?: number) => {
    if (year === undefined) year = getCurrentIsoYear()
    if (week === undefined) week = getCurrentIsoWeek()

    if (week === getLastISOWeekOfYear(year)) {
        return { year: year + 1, week: 1 }
    }
    return { year, week: week + 1 }
}
