import { AccountCircle, Logout, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../services/auth';
import translate from '../../utils/translate';
import { useSidemenu } from '../Layout/LayoutProvider';
import MenuDivider from '../MenuDivider/MenuDivider';
import Notifications from '../Notifications/Notifications';
import Show from '../Show/Show';
import styles from './Header.module.scss';

const Header = () => {

    const { setIsOpen } = useSidemenu()
    const auth = useAuth()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate()

    const handle = () => {
        setIsOpen(true)
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="fixed">
            <Toolbar className={styles.AppToolbar}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={4}>
                        <IconButton color="inherit" aria-label="menu" onClick={handle}>
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} container alignItems="center" justifyContent="flex-end">

                        <Show on={auth?.user?.name}>
                            <Typography variant="subtitle1" style={{ marginTop: 5, marginRight: 10 }}>
                                <span style={{ fontWeight: 500 }}>{auth?.user?.name}</span> <span style={{ fontSize: 14 }}>({translate(auth?.user?.role)})</span>
                            </Typography>
                        </Show>

                        <Notifications />

                        <IconButton onClick={handleClick} className={styles.Profile}>
                            <AccountCircle fontSize="medium" style={{ color: "white", fontSize: 28 }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            sx={{ width: 500 }}
                        >

                            <MenuItem onClick={() => navigate("/profile")}>
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText>
                                    Dados Perfil
                                </ListItemText>
                            </MenuItem>

                            <MenuDivider />

                            <MenuItem onClick={() => auth?.logout()}>
                                <ListItemIcon>
                                    <Logout />
                                </ListItemIcon>
                                <ListItemText>
                                    Sair
                                </ListItemText>
                            </MenuItem>
                        </Menu>

                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar >

    );
};

export default Header;
