import { GridColDef } from "@mui/x-data-grid-pro";
import { dateGetter } from "../Datagrid/Getters/DateGetter";
import { PlanningRowStateRender } from "../Datagrid/Renders/PlanningStateRowRender";

export const planningRowColumns: GridColDef[] = [
    { field: "id", headerName: "id", valueGetter: params => params?.row?.id, width: 20, filterable: false, sortable: false },
    { field: "quantity", headerName: "quantidade", valueGetter: params => params?.row?.workOrder?.quantity, width: 150, filterable: false, sortable: false },
    { field: "sole", headerName: "sola", valueGetter: params => params?.row?.workOrder?.sole?.name, width: 150, filterable: false },
    { field: "leather", headerName: "pele", valueGetter: params => params?.row?.workOrder?.leather?.name, width: 150, filterable: false },
    { field: "model", headerName: "modelo", valueGetter: params => params?.row?.workOrder?.model?.name, width: 150, filterable: false },
    { field: "mold", headerName: "forma", valueGetter: params => params?.row?.workOrder?.mold?.name, width: 150, filterable: false },
    { field: "client", headerName: "cliente", valueGetter: params => params?.row?.workOrder?.order?.client?.name, width: 150, filterable: false },
    { field: "cuttingSystem", headerName: "corte", valueGetter: params => params?.row?.workOrder?.order?.cuttingSystem, width: 60, filterable: false, align: "center" },
    { field: "orderDate", headerName: "data pedida", valueGetter: params => dateGetter(params, "row.workOrder.orderDate"), width: 150, filterable: false },
    { field: "priority", headerName: "prioridade", valueGetter: params => params?.row?.priority, width: 100, filterable: false, align: "center" },
    { field: "observations", headerName: "observações", valueGetter: params => params?.row?.observations, width: 150, filterable: false },
    { field: "state", headerName: "estado", width: 150, renderCell: PlanningRowStateRender, filterable: false, sortable: false },
]
