import { format as doFormat, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FC } from 'react';
import Config from '../../services/config';

interface FormatDateProps {
    date: string
    format?: string
}

/**
 * returns a formatted string
 */
const FormatDate: FC<FormatDateProps> = props => {
    const defaultFormatDate = Config?.DEFAULT_FORMAT_DATE || "dd'/'MM'/'yyyy"
    const { format = defaultFormatDate, date } = props
    if (!date) return null
    const parsedDate = parseISO(date);
    const formattedDate = doFormat(parsedDate, format, { locale: pt });
    return <span>{formattedDate}</span>
};

export const FormatDateTime: FC<FormatDateProps> = props => {
    const defaultFormatDate = Config?.DEFAULT_FORMAT_DATETIME || "dd'/'MM'/'yyyy HH:mm"
    const { format = defaultFormatDate, date } = props
    return <FormatDate date={date} format={format} />
};


export default FormatDate;
