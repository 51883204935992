import { Fade } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Maybe, Planning } from '../../generated/graphql';
import Config from '../../services/config';
import { DeepPartial } from '../../types/types';
import Datagrid from '../Datagrid/Datagrid';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import PlanningListHeader from '../PlanningListHeader/PlanningListHeader';
import PlanningRowForm from '../PlanningRowForm/PlanningRowForm';
import TemporaryDrawer from '../TemporaryDrawer/TemporaryDrawer';
import styles from './PlanningList.module.scss';
import { planningRowColumns } from './PlanningRowColumns';

interface PlanningListProps {
    planning?: Maybe<DeepPartial<Planning>>
}

export default function PlanningList(props: PlanningListProps) {
    const { planning } = props
    const context = useContext(DataViewContext)
    const nodes = context?.data?.planningRows?.nodes
    const [detailRow, setDetailRow] = useState<any>(null)
    const rows = nodes || []

    useEffect(() => {
        context.setView("planningRowsView")
        context.setColumnDefs(planningRowColumns)
    }, [])

    const handleRowSelectionChange = (newRowSelectionModel: any) => {
        context?.setRowSelectionModel(newRowSelectionModel);
    }

    const handleCellClick = (params: any) => {
        if (params?.field === 'id') {
            setDetailRow(params.row)
        }
    }

    const handleSuccess = () => {
        context?.refetch()
        setDetailRow(null)
    }

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.PlanningList}>
                <PlanningListHeader planning={planning} />
                <Datagrid
                    rows={rows}
                    columns={context?.columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                    setColumnOrderModel={context?.setColumnOrderModel}
                    columnVisibilityModel={context?.columnVisibilityModel}
                    setColumnVisibilityModel={context?.setColumnVisibilityModel}
                    disableSelectionOnClick={true}
                    onRowSelectionModelChange={handleRowSelectionChange}
                    rowSelectionModel={context?.rowSelectionModel}
                    onCellClick={handleCellClick}
                />
                <TemporaryDrawer isOpen={!!detailRow} setIsOpen={setDetailRow} hideBackdrop={false}>
                    <PlanningRowForm planningRow={detailRow} onSuccess={handleSuccess} />
                </TemporaryDrawer>

            </div>
        </Fade>
    );
}
