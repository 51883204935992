import { ArrayParam } from 'use-query-params';
import { FilterConfig } from '../../hooks/useFilters';
import { relationFilterConfig } from '../../utils/filters';
import { boolLookupExpression, clientFkFunc } from '../../utils/lookups';
import WorkOrderProvider from '../WorkOrderProvider/WorkOrderProvider';
import WorkOrdersActions from '../WorkOrdersActions/WorkOrdersActions';
import WorkOrdersFilters from '../WorkOrdersFilters/WorkOrdersFilters';
import WorkOrdersList from '../WorkOrdersList/WorkOrdersList';
import styles from './WorkOrdersView.module.scss';

const WorkOrdersView = () => {

    const filterConfig: FilterConfig[] = [
        relationFilterConfig({ name: "leather" }),
        relationFilterConfig({ name: "model" }),
        relationFilterConfig({ name: "sole" }),
        relationFilterConfig({ name: "mold" }),
        { name: "client", variant: ArrayParam, defaultValue: [], lookupExpFunc: clientFkFunc, alias: "order" },
        { name: "cuttingSystem", variant: ArrayParam, defaultValue: [], lookupExpFunc: boolLookupExpression }
    ]

    return (
        <div className={styles.Wrapper}>
            <WorkOrderProvider filterConfig={filterConfig}>
                <WorkOrdersActions />
                <WorkOrdersFilters />
                <WorkOrdersList />
            </WorkOrderProvider>
        </div>
    )
}

export default WorkOrdersView
