import { Grid } from '@mui/material';
import { UseFiltersValue } from '../../hooks/useFilters';
import ActionsBar from '../ActionsBar/ActionsBar';
import IsoWeekFilter from '../IsoWeekFilter/IsoWeekFilter';
import styles from './SectorActions.module.scss';

interface SectorActionsProps {
    weekYear: string
    setWeekYear: (name: string, value: UseFiltersValue) => void
}

export default function SectorActions(props: SectorActionsProps) {
    const { weekYear, setWeekYear } = props
    return (
        <ActionsBar>
            <Grid container justifyContent="flex-end" alignContent="center" className={styles.Wrapper}>
                <div style={{ marginTop: 5, marginLeft: 20, marginRight: 30 }}>
                    <IsoWeekFilter value={weekYear} onChange={setWeekYear} />
                </div>
            </Grid>
        </ActionsBar>
    )
}
