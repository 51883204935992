// https://youmightnotneed.com/lodash/
// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code!
export const _get = (obj: {}, path: string, defValue?: string) => {
    // If path is not defined or it has false value
    if (!path) return undefined
    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
    // Find value
    // @ts-ignore
    const result = pathArray.reduce(
        (prevObj: any, key: any) => prevObj && prevObj[key],
        obj
    )
    // If found value is undefined return default value; otherwise return the value
    return result === undefined ? defValue : result
}

/**
* Returns the name of the first property in an object
* if more than one property exists it is not guaranteed
* that the returned property will be the same
* see: https://stackoverflow.com/questions/983267/how-to-access-the-first-property-of-a-javascript-object
*
* @param checkOnlyOne - throws errror if more than one property exists in the object
*/
export const firstProperty = (obj: object, checkOnlyOne?: boolean) => {
    if (!obj) return null

    const count = Object.keys(obj).length
    if (checkOnlyOne && count !== 1) {
        throw new Error("Object has more or less than one property")
    }
    return Object.keys(obj)[0]
}

// https://stackoverflow.com/a/20381799
export const breakCamelCase = (str: string) => {
    return str.split(/(?=[A-Z])/).map(s => s.toLowerCase())
}

export const orderColDefs = (colDefs: any[], workspacePrefs: any[]): any[] => {
    // Cria um mapa para facilitar a busca dos elementos em workspacePrefs
    const workspacePrefsMap = new Map(workspacePrefs.map(item => [item.name, item]));

    // Mapeia colDefs para incluir a propriedade order de workspacePrefs
    const colDefsWithOrder = colDefs.map(colDef => {
        const workspacePref = workspacePrefsMap.get(colDef.field);
        return { ...colDef, order: workspacePref ? workspacePref.order : Infinity };
    });

    // Ordena a nova array com base na propriedade order
    colDefsWithOrder.sort((a, b) => a.order - b.order);

    return colDefsWithOrder;
}

export const convertArrayToObject = (arr: { name: string, visible: boolean }[]): { [key: string]: boolean } => {
    return arr.reduce((obj: any, item: any) => {
        obj[item.name] = item.visible;
        return obj;
    }, {});
};

export const diffObjects = (obj1: { [key: string]: any }, obj2: { [key: string]: any }): { [key: string]: any } => {
    return Object.keys(obj1).reduce((result: any, key: any) => {
        if (obj1[key] !== obj2[key]) {
            result[key] = obj2[key];
        }
        return result;
    }, {});
};

export const toNumber = (input: string | number) => {
    return typeof (input) === "number" ? input : parseInt(input)
}

export const toNumberArray = (input: string[] | number[]) => {
    return input.map((element: string | number) => toNumber(element))
}

export const noop = () => {
    return []
}
