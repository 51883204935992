import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { Badge, Button, Grid, IconButton, Popover, Typography } from "@mui/material";
import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Notification as NotificationType, NotificationSortFields, NotificationsQuery, SortDirection, useNotificationsLazyQuery, useUpdateManyNotificationsMutation, useUpdateOneNotificationMutation } from '../../generated/graphql';
import { useAuth } from '../../services/auth';
import Empty from '../Empty/Empty';
import Notification from '../Notification/Notification';
import Show from '../Show/Show';
import styles from './Notifications.module.scss';

const getBadgeCount = (data?: NotificationsQuery) => {
    if (!data) return null
    const count = data?.notifications.nodes.filter(notification => !notification.isOpen)
    if (count?.length) {
        return count.length.toString()
    }
}

const getNotificationsIds = (data?: NotificationsQuery) => {
    if (!data) return null
    return data?.notifications?.nodes?.map(notification => notification.id)
}

const Notifications = () => {

    const auth = useAuth()
    const [query, { data, loading, refetch }] = useNotificationsLazyQuery()
    const [updateOne] = useUpdateOneNotificationMutation()
    const [updateMany] = useUpdateManyNotificationsMutation()
    const navigate = useNavigate()
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            await query({
                variables: {
                    filter: { user: { id: { eq: auth?.user?.id } }, isCleared: { is: false } },
                    sorting: [{ field: NotificationSortFields.Id, direction: SortDirection.Asc }]
                }, fetchPolicy: "no-cache"
            })
        }, 3000)
        return () => clearInterval(intervalId)
    }, [auth])

    const content = getBadgeCount(data)
    const notifications = data?.notifications?.nodes

    const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchor(event.currentTarget)
        const notificationIds = getNotificationsIds(data)
        await updateMany({
            variables: {
                input: {
                    filter: { id: { in: notificationIds } },
                    update: { isOpen: true }
                }
            }
        })
        refetch()
    }

    const markAllRead = async () => {
        const notificationIds = getNotificationsIds(data)
        await updateMany({
            variables: {
                input: {
                    filter: { id: { in: notificationIds } },
                    update: { isRead: true }
                }
            }
        })
        refetch()
    }

    const toogleRead = async (notification: NotificationType) => {
        const update = { isRead: !notification.isRead }
        await updateOne({ variables: { input: { id: notification.id, update } } })
        refetch()
    }

    const markRead = async (notification: NotificationType) => {
        const update = { isRead: true }
        await updateOne({ variables: { input: { id: notification.id, update } } })
        refetch()
    }

    const clearList = async () => {
        const notificationIds = getNotificationsIds(data)
        await updateMany({
            variables: {
                input: {
                    filter: { id: { in: notificationIds } },
                    update: { isCleared: true }
                }
            }
        })
        refetch()
    }

    const openLink = (notification: NotificationType) => {
        if (notification?.link) {
            navigate(notification?.link)
            setAnchor(null)
            markRead(notification)
        }
    }

    const hasNotifications = !!data?.notifications?.nodes?.length

    return (
        <>
            <IconButton onClick={handleClick}>
                <Badge badgeContent={content} color="error">
                    <NotificationsIcon fontSize="medium" style={{ color: "white", fontSize: 28 }} />
                </Badge>
            </IconButton>
            <Popover
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={styles.NotificationsList}>
                    <div className={styles.NotificationsHeader}>
                        <Typography variant="h6" color="primary">
                            Notificações
                        </Typography>
                        <Grid container justifyContent="flex-end" style={{ marginTop: 10, paddingRight: 10 }}>
                            <Button variant="text" size="small" color="inherit" className={styles.ClearAll} onClick={clearList}>
                                Limpar
                            </Button>
                            <Button variant="text" size="small" color="inherit" className={styles.ClearAll} onClick={markAllRead}>
                                Marcar Todas Lidas
                            </Button>
                        </Grid>
                    </div>


                    <Grid container justifyContent="center" className={styles.Empty}>
                        <Show on={!hasNotifications}>
                            <Empty message="Não existem notificações" color="text" variant="body1" />
                        </Show>
                    </Grid>


                    <div className={styles.NotificationsContent}>
                        {notifications?.map(notification => (
                            <Notification key={notification?.id} notification={notification} toogleRead={toogleRead} openLink={openLink} />
                        ))}
                    </div>
                </div>
            </Popover>
        </>
    )

}

export default Notifications
