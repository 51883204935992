import { StringParam, useQueryParams, withDefault } from 'use-query-params';

export type FilterConfig = string | { name: string, alias?: string, variant: any, defaultValue: any, lookupExpFunc?: (value: any) => any }
export type UseFiltersValue = string | number | string[] | number[] | Date
export type UseFiltersOnChange = (field: string, value: UseFiltersValue) => any

export const useFiltersBuilder = (filters: FilterConfig[]) => {
    const filters_ = {}
    filters.forEach(filter => {
        if (typeof filter === 'string') {
            // @ts-ignore
            filters_[filter] = withDefault(StringParam, "")
        } else {
            // @ts-ignore
            filters_[filter.name] = withDefault(filter.variant, filter.defaultValue)
        }
    })
    return filters_
}

export const useFilters = (filters: FilterConfig[]) => {

    const filters_ = useFiltersBuilder(filters)

    const [query, setQuery] = useQueryParams(filters_)

    const setFilter = (name: string, value: UseFiltersValue) => {
        if (name === "page") {
            setQuery({ ...query, [name]: value })
        } else {
            setQuery({ ...query, [name]: value, page: 1 })
        }
    }

    const reset = () => {
        filters.forEach(filter => {
            // @ts-ignore
            const { name, defaultValue } = filter
            setQuery({ [name]: defaultValue })
        })
    }

    return [query, setFilter, reset]
}
