import { ObjectSchema } from "yup";


// https://github.com/jaredpalmer/formik/discussions/3026
// Initial Valies From Schema
export const getInitialValues = (schema: ObjectSchema<any>) => {
    return schema.cast({});
}

export function formInit<T>(getSchema: () => any, item?: Partial<T>): { schema: ObjectSchema<any>, initial: T } {
    const schema = getSchema()
    const initial = item ? item : getInitialValues(schema)
    return { schema, initial }
}
