import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import { UseFiltersOnChange } from "../../hooks/useFilters";
import { nextISOWeek, parseWeekValue, previousISOWeek } from "../../utils/dates";

interface WeekFilterProps {
    value: string
    onChange: UseFiltersOnChange
}

const IsoWeekFilter: FC<WeekFilterProps> = props => {
    const { value, onChange } = props
    const { week, year } = parseWeekValue(value)


    const handle = (direction: number) => {
        const isoWeek = direction < 0 ? previousISOWeek(year, week) : nextISOWeek(year, week)
        onChange("week", `${isoWeek.week}/${isoWeek.year}`)
    }

    return (
        <>
            <Tooltip title="Semana Anterior">
                <IconButton onClick={() => handle(-1)}>
                    <KeyboardArrowLeft />
                </IconButton>
            </Tooltip>
            <Tooltip title="Semana Seguinte">
                <IconButton>
                    <KeyboardArrowRight onClick={() => handle(1)} />
                </IconButton>
            </Tooltip>
            <span>{week}/{year}</span>
        </>
    )
}


export default IsoWeekFilter
