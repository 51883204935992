import { Delete, Flag, OpenWith, Transform } from '@mui/icons-material';
import { Button, Grid, SelectChangeEvent, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { useDeleteManyPlanningRowsMutation, useUpdateManyPlanningRowsMutation } from '../../generated/graphql';
import { useIsManager } from '../../hooks/useIsManager';
import useToast from '../../hooks/useToast';
import ActionsBar from '../ActionsBar/ActionsBar';
import ConfirmButton from '../ConfirmButton/ConfirmButton';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import PlanningCustomSelector from '../PlanningCustomSelector/PlanningCustomSelector';
import PlanningRowStateCustomSelector from '../PlanningRowStateCustomSelector/PlanningRowStateCustomSelector';
import PopAction from '../PopAction/PopAction';
import PriorityCustomSelector from '../PriorityCustomSelector/PriorityCustomSelector';
import Show from '../Show/Show';
import styles from './PlanningActions.module.scss';


export default function PlanningActions() {
    const context = useContext(DataViewContext)
    const [popActionMove, setPopActionMove] = useState<boolean>(false)
    const [popActionState, setPopActionState] = useState<boolean>(false)
    const [popActionPriority, setPopActionPriority] = useState<boolean>(false)
    const [planning, setPlanning] = useState<string>("")
    const [priority, setPriority] = useState<string>("")
    const [planningRowState, setPlanningRowState] = useState<string>("")
    const isManager = useIsManager()
    const [updatePlanningRows] = useUpdateManyPlanningRowsMutation()
    const [deletePlanningRows] = useDeleteManyPlanningRowsMutation()
    const { successToast, feedback } = useToast()

    const handleChange = (event: SelectChangeEvent<string>) => {
        setPlanning(event.target.value)
    }

    const handleStateChange = (event: SelectChangeEvent<string>) => {
        setPlanningRowState(event.target.value)
    }

    const handlePriorityChange = (event: SelectChangeEvent<string>) => {
        setPriority(event.target.value)
    }

    const handleMovePlanningRows = async () => {
        const selection = context.rowSelectionModel
        const response = await updatePlanningRows({ variables: { input: { filter: { id: { in: selection } }, update: { planning } } } })
        if (!response?.errors?.length) {
            successToast("Ordens de fabrico movidas")
            setPopActionMove(false)
            context?.clearSelection()
        }
    }

    const handleDelete = async () => {
        const selection = context.rowSelectionModel
        const response = await deletePlanningRows({ variables: { input: { filter: { id: { in: selection } } } } })
        feedback(!response?.errors?.length, "Order de fabrico removidas com sucesso", "Erro ao remover planeamentos")
        context.refetch()
    }

    const updateState = async () => {
        const selection = context.rowSelectionModel
        const response = await updatePlanningRows({ variables: { input: { filter: { id: { in: selection } }, update: { planningRowState } } } })
        if (!response?.errors?.length) {
            successToast("Estado atualizado")
            setPopActionState(false)
            setPlanningRowState("")
            context?.clearSelection()
        }
    }

    const updatePriority = async () => {
        const selection = context.rowSelectionModel
        const response = await updatePlanningRows({ variables: { input: { filter: { id: { in: selection } }, update: { priority: +priority } } } })
        if (!response?.errors?.length) {
            successToast("Prioridade atualizada")
            setPopActionPriority(false)
            setPriority("")
            context?.clearSelection()
        }
    }

    return (
        <ActionsBar>
            <Grid container justifyContent="flex-end" alignContent="center" className={styles.Wrapper}>

                <Show on={context?.rowSelectionModel?.length && isManager}>
                    <Tooltip title="mover">
                        <PopAction name="move" icon={<OpenWith />} open={popActionMove} setOpen={setPopActionMove}>
                            <Grid container alignItems="center">
                                <PlanningCustomSelector value={planning} onChange={handleChange} />
                                <Button onClick={handleMovePlanningRows}>Mover</Button>
                            </Grid>
                        </PopAction>
                    </Tooltip>
                    <Tooltip title="estado">
                        <PopAction name="state" icon={<Transform />} open={popActionState} setOpen={setPopActionState}>
                            <Grid container alignItems="center">
                                <PlanningRowStateCustomSelector value={planningRowState} onChange={handleStateChange} />
                                <Button onClick={updateState}>Atualizar</Button>
                            </Grid>
                        </PopAction>
                    </Tooltip>
                    <Tooltip title="prioridade">
                        <PopAction name="priority" icon={<Flag />} open={popActionPriority} setOpen={setPopActionPriority}>
                            <Grid container alignItems="center">
                                <PriorityCustomSelector value={priority} onChange={handlePriorityChange} />
                                <Button onClick={updatePriority}>Atualizar</Button>
                            </Grid>
                        </PopAction>
                    </Tooltip>
                    <Tooltip title="remover">
                        <ConfirmButton onClick={handleDelete}>
                            <Delete />
                        </ConfirmButton>
                    </Tooltip>

                </Show>
            </Grid>
        </ActionsBar>
    )
}
