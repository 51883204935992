import { ArrayParam } from 'use-query-params'
import { usePlanningQuery } from '../../generated/graphql'
import { FilterConfig } from '../../hooks/useFilters'
import useParamId from '../../hooks/useParamId'
import { relationFilterConfig } from '../../utils/filters'
import { clientFkFunc, numberLookupExpression } from '../../utils/lookups'
import PlanningActions from '../PlanningActions/PlanningActions'
import PlanningFilters from '../PlanningFilters/PlanningFilters'
import PlanningList from '../PlanningList/PlanningList'
import PlanningRowProvider from '../PlanningRowProvider/PlanningRowProvider'
import styles from './PlanningView.module.scss'

export default function PlanningView() {

    const id = useParamId()
    const { data } = usePlanningQuery({ variables: { id } })

    const filterConfig: FilterConfig[] = [
        { name: "planning", variant: ArrayParam, defaultValue: [id] },
        { name: "leather", variant: ArrayParam, defaultValue: [] },
        { name: "model", variant: ArrayParam, defaultValue: [] },
        { name: "mold", variant: ArrayParam, defaultValue: [] },
        { name: "client", variant: ArrayParam, defaultValue: [], lookupExpFunc: clientFkFunc, alias: "order" },
        { name: "priority", variant: ArrayParam, defaultValue: [], lookupExpFunc: numberLookupExpression },
        relationFilterConfig({ name: "planningRowState" })
    ]

    return (
        <div className={styles.Wrapper}>
            <PlanningRowProvider filterConfig={filterConfig}>
                <PlanningActions />
                <PlanningFilters />
                <PlanningList planning={data?.planning} />
            </PlanningRowProvider>
        </div >
    )
}
