import { useCreateOneSupplierMutation, useDeleteOneSupplierMutation, useSuppliersQuery, useUpdateOneSupplierMutation } from "../../../../generated/graphql"

export default function useSupplierStore() {
    const query = useSuppliersQuery
    const name = "supplier"
    const [create] = useCreateOneSupplierMutation()
    const [update] = useUpdateOneSupplierMutation()
    const [remove] = useDeleteOneSupplierMutation()
    return { query, name, create, update, remove }
}
