import { Delete, MoreVert } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, IconButton, Menu, Typography } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import { createElement, FC, Fragment, ReactNode, useState } from "react";
import useToast from "../../hooks/useToast";
import { updateOmit } from "../../utils/objects";
import translate from "../../utils/translate";
import InputTextField from "../InputTextField/InputTextField";
import MenuItemWithConfirmation from "../MenuItemWithConfirmation/MenuItemWithConfirmation";
import styles from './SettingsView.module.scss';

interface SettingsEditorProps {
    selected: any
    editor: any
    store: any
    entity: any
    deselect: any
}


interface MoreMenuProps {
    children: ReactNode
}

export const MoreMenu: FC<MoreMenuProps> = props => {
    const { children } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {

    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreVert color="secondary" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {children}
            </Menu>
        </div>
    )
}

interface HideProps {
    condition: boolean
    children: ReactNode
}

const Hide: FC<HideProps> = props => {
    const { children, condition } = props
    if (!condition) return null
    return <Fragment>{children}</Fragment>
}


const getFieldsFromSchema = (schema: any) => {
    return Object.keys(schema.fields)
}


// se o selectedRecord for null temos
// o form create, de outra forma temos o
// form update

// passamos uma lista de SettingsField e ele constroi o form
// ou passamos diretamente um componente editor / form?
const SettingsEditor: FC<SettingsEditorProps> = props => {
    const { selected, deselect, editor, store, entity } = props

    const t = translate
    const { feedback, errorToast } = useToast()

    if (editor) {
        return createElement(editor, { selected, store })
    }

    const { schema, initial } = entity?.schemaInit(selected)

    const fields = getFieldsFromSchema(schema)

    const handleSubmit = async (values: FormikValues) => {
        let response = null
        if (selected?.id) {
            response = await store.update(selected?.id, { ...updateOmit(values, ["id"]) })
        } else {
            response = await store.create({ ...values })
        }

        feedback(!response?.errors?.length, "Pedido executado com sucessso.", "Erro ao executar pedido.")
    }

    const handleClose = () => {
        deselect()
    }

    const handleDelete = async () => {
        try {
            const response = await store.remove(selected?.id)
            feedback(!response?.errors?.length, "Pedido executado com sucessso", "Erro ao executar pedido")
        } catch (e: unknown) {
            errorToast("Erro ao executar pedido, verifique se os dados não estão a ser usados na aplicação.")
        }
    }


    const title = selected?.id ? "Guardar" : "Criar"
    const InputLabelProps = selected?.id ? { shrink: true } : {}

    return (
        <Card className={styles.SettingsEditor}>
            <CardContent>
                <Grid container justifyContent="space-between">
                    <Typography variant="h4" color="primary">{title}</Typography>
                    <MoreMenu>
                        <MenuItemWithConfirmation
                            onClick={handleDelete}
                            icon={<Delete fontSize="small" style={{ color: "red" }} />}
                            label="Remover"
                            listItemTextColor="red"
                        />
                    </MoreMenu>
                </Grid>

                <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize>
                    {() => {
                        return (
                            <Form>
                                <div style={{ maxWidth: "80%" }}>
                                    {fields.map((field: string) => <p key={field}><InputTextField name={field} label={t(field) || field} fullWidth InputLabelProps={InputLabelProps} /></p>)}
                                </div>
                                <Grid container justifyContent="flex-end" className={styles.Actions}>
                                    <Hide condition={selected?.id}>
                                        <Button onClick={handleClose} size="small">Fechar</Button>
                                    </Hide>
                                    <Button type="submit" variant="contained" size="small">{title}</Button>
                                    {/* <SubmitButton /> */}
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik >
            </CardContent>
        </Card >
    )
}

export default SettingsEditor

{/* <MenuItem disabled={!selected?.id}>
    <ListItemIcon>
    <ConfirmButton onClick={handleDelete}>
    <Delete fontSize="small" style={{ color: "red" }} />
    </ConfirmButton>
    </ListItemIcon>
    <ListItemText style={{ color: "red" }}>
    Remover
    </ListItemText>
    </MenuItem> */}
