import { uniqueValues } from "./arrays"

/**
 * retorna num novo objeto com base no objeto original
 * excluindo os campos espeficiados em keys
 */
export const omit = (obj: {}, keys: string[]) => {
    let newObject = {}
    Object.keys(obj).forEach(key => {
        if (!keys.includes(key)) {
            // @ts-ignore
            newObject[key] = obj[key]
        }
    })
    return newObject
}

/**
 * versão especializada do omit que por defeito
 * omite os campos id e __typename
 */
export const updateOmit = (obj: {}, keys: string[]) => {
    return omit(obj, uniqueValues([...keys, "id", "__typename"]))
}

/**
versão própria de pick, outras propostas encontradas online não funcionaram
e não queremos usar loadsh ou underscore
tem testes
**/
export const pick = (obj: {}, keys: string[]) => {
    let newObject = {}
    keys.forEach(key => {
        if (obj && obj.hasOwnProperty(key)) {
            // @ts-ignore
            newObject[key] = obj[key]
        }
    })
    return newObject
}
