import { useCreateOneUserMutation, useDeleteOneUserMutation, useUsersQuery, useUpdateOneUserMutation } from "../../../../generated/graphql"

export default function useUserStore() {
    const query = useUsersQuery
    const name = "user"
    const [create] = useCreateOneUserMutation()
    const [update] = useUpdateOneUserMutation()
    const [remove] = useDeleteOneUserMutation()
    return { query, name, create, update, remove }
}
