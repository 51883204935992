import { SectorSortFields, SortDirection, useSectorFieldQuery } from '../../generated/graphql';
import config from '../../services/config';
import { FormFieldProps } from '../../types/types';
import SelectAutocompleteField from '../SelectAutocompleteField/SelectAutocompleteField';

export default function SectorField(props: FormFieldProps) {
    const {
        name = "sector",
        label = "Setor",
        textFieldProps = { variant: config.DEFAULT_TEXTFIELD_VARIANT },
        disabled = false,
        limit = config.MAX_RESULTS_SIZE

    } = props
    return (
        <SelectAutocompleteField
            name={name}
            label={label}
            query={useSectorFieldQuery}
            textFieldProps={textFieldProps}
            disabled={disabled}
            queryPaging={{
                limit
            }}
            querySorting={{ direction: SortDirection.Asc, field: SectorSortFields.Name }}
        />
    )
}
