import { useCreateOneLeatherMutation, useDeleteOneLeatherMutation, useLeathersQuery, useUpdateOneLeatherMutation } from "../../../../generated/graphql"

export default function useLeatherStore() {
    const query = useLeathersQuery
    const name = "leather"
    const [create] = useCreateOneLeatherMutation()
    const [update] = useUpdateOneLeatherMutation()
    const [remove] = useDeleteOneLeatherMutation()
    return { query, name, create, update, remove }
}
