import { Checkbox, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FC } from 'react';
import { UseFiltersOnChange } from '../../hooks/useFilters';
import { firstProperty } from '../../services/utils';

interface SelectFilterProps {
    value: string[]
    onChange: UseFiltersOnChange
    multiple?: boolean
    label?: string
    query: () => any
    field: string
    filter?: any,
    paging?: any,
    sorting?: any
}

export const SelectFilter: FC<SelectFilterProps> = props => {
    const {
        value,
        onChange,
        multiple = false,
        label,
        query,
        field,
        filter = {},
        paging = {},
        sorting = {}
    } = props


    const inputProps = { disableUnderline: true }

    // @ts-ignore
    const { data } = query({ fetchPolicy: "no-cache", variables: { filter, paging, sorting } })


    const collection = firstProperty(data)
    const nodes = collection ? data?.[collection]?.nodes : []

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const { target: { value }, } = event;
        onChange(field, value)
    }

    const findById = (id: string) => {
        if (collection) {
            return data?.[collection]?.nodes.find((obj: any) => obj?.id === id)
        }
    }

    const render = (selected: string[]) => {
        const first = selected[0]
        const obj = findById(first)
        return selected.length === 1 ? obj?.name : obj?.name + '...'
    }

    return (
        <FormControl sx={{ m: 1, width: 200 }}>
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                multiple={multiple}
                value={value}
                onChange={handleChange}
                input={<Input {...inputProps} />}
                renderValue={render}

            >
                {!multiple && <MenuItem key={"__all__"} value={""}>
                    <ListItemText primary={<em>TODOS</em>} />
                </MenuItem>}

                {nodes?.length && nodes?.map((node: any) => (
                    <MenuItem key={node?.id} value={node?.id}>
                        {multiple && <Checkbox checked={value.includes(node?.id)} />}
                        <ListItemText primary={node?.name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}
