import { createContext, useContext, useState } from "react";

export const LayoutContext = createContext<any>(undefined)

const LayoutProvider = ({ children }: { children: any }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    return <LayoutContext.Provider value={{ isOpen, setIsOpen }}>{children}</LayoutContext.Provider>;
}

export const useSidemenu = () => useContext(LayoutContext)


export default LayoutProvider
