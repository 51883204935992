import { useCreateOneMoldMutation, useDeleteOneMoldMutation, useMoldsQuery, useUpdateOneMoldMutation } from "../../../../generated/graphql"

export default function useMoldStore() {
    const query = useMoldsQuery
    const name = "mold"
    const [create] = useCreateOneMoldMutation()
    const [update] = useUpdateOneMoldMutation()
    const [remove] = useDeleteOneMoldMutation()
    return { query, name, create, update, remove }
}
