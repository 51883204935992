import { Fade, Grid } from "@mui/material";
import { useContext } from "react";
import Config from "../../services/config";
import ClearFilters from "../ClearFilters/ClearFilters";
import ClientFilter from "../ClientFilter/ClientFilter";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import Search from "../Search/Search";
import styles from './OrdersFilters.module.scss';

const OrdersFilters = () => {
    const context = useContext(DataViewContext)
    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <Grid container justifyContent="flex-start" alignItems="center" className={styles.OrdersFilters}>
                <Search value={context.filters.clientReference} onChange={context.setFilter} name="clientReference" />
                <ClientFilter value={context.filters.client} onChange={context.setFilter} />
                <div style={{ marginLeft: 20 }}></div>
                <ClearFilters />
            </Grid>
        </Fade>
    )
}

export default OrdersFilters;
