import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { useContext } from "react"
import { WorkOrder } from "../../generated/graphql"
import useMakeFormSubmit from "../../hooks/useMakeFormSubmit"
import { formInit } from "../../utils/forms"
import CuttingSystemField from "../CuttingSystemField/CuttingSystemField"
import { DataViewContext } from "../DataViewProvider/DataViewProvider"
import Hide from "../Hide/Hide"
import InputDateField from "../InputDateField/InputDateField"
import InputNumberField from "../InputNumberField/InputNumberField"
import InputTextField from "../InputTextField/InputTextField"
import IsLeatherAvailableField from "../IsLeatherAvailableField/IsLeatherAvailableField"
import IsSoleAvailableField from "../IsSoleAvailableField/IsSoleAvailableField"
import LeatherField from "../LeatherField/LeatherField"
import ModelField from "../ModelField/ModelField"
import MoldField from "../MoldField/MoldField"
import OrderField from "../OrderField/OrderField"
import SoleField from "../SoleField/SoleField"
import SubmitButton from "../SubmitButton/SubmitButton"
import styles from './WorkOrderForm.module.scss'
import workOrderSchemaGetter from "./WorkOrderFormSchema"

const WorkOrderForm = (props: any) => {
    const { workOrder, onSuccess } = props
    const context = useContext(DataViewContext)
    const { schema, initial } = formInit<WorkOrder>(workOrderSchemaGetter, workOrder)
    const handleSubmit = useMakeFormSubmit({ context, entity: "workOrder", onSuccess })
    const formName = workOrder?.id ? "Editar Ordem" : "Criar Ordem"

    const _handleSubmit = (values: FormikValues) => {
        values.cuttingSystem = values.cuttingSystem?.id
        handleSubmit(values)
    }

    return (
        <div className={styles.TaskFormWrapper}>
            <Formik initialValues={initial} onSubmit={_handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                {() => {
                    return (
                        <Form>
                            <Grid container direction="column" className={styles.Section}>
                                <Typography variant="h4" color="primary" gutterBottom>
                                    {formName}
                                </Typography>
                            </Grid>

                            <TableContainer>
                                <Table sx={{ minWidth: 400 }} className={styles.Table}>
                                    <TableBody>
                                        <Hide on={workOrder?.id}>
                                            <TableRow key={0}>
                                                <TableCell>
                                                    <InputNumberField name="workOrderid" label="ordem de fabrico" fullWidth />
                                                </TableCell>
                                            </TableRow>
                                        </Hide>

                                        <TableRow key={1}>
                                            <TableCell>
                                                <OrderField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={2}>
                                            <TableCell>
                                                <InputNumberField name="quantity" label="quantidade" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={3}>
                                            <TableCell>
                                                <InputDateField name="orderDate" label="data pedida" />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={4}>
                                            <TableCell>
                                                <LeatherField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={5}>
                                            <TableCell>
                                                <IsLeatherAvailableField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={6}>
                                            <TableCell>
                                                <SoleField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={7}>
                                            <TableCell>
                                                <IsSoleAvailableField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={8}>
                                            <TableCell>
                                                <MoldField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={9}>
                                            <TableCell>
                                                <ModelField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={10}>
                                            <TableCell>
                                                <CuttingSystemField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={11}>
                                            <TableCell>
                                                <InputTextField name="clientOrderReference" label="ref. enc. cliente" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={12}>
                                            <TableCell>
                                                <InputTextField name="clientModelReference" label="ref. mod. cliente" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={13}>
                                            <TableCell>
                                                <InputTextField name="observations" label="observações" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container justifyContent="flex-end">
                                <SubmitButton />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default WorkOrderForm
