import { Grid } from "@mui/material";
import { useContext } from "react";
import ClientFilter from "../ClientFilter/ClientFilter";
import { DataViewContext } from "../DataViewProvider/DataViewProvider";
import LeatherFilter from "../LeatherFilter/LeatherFilter";
import ModelFilter from "../ModelFilter/ModelFilter";
import MoldFilter from "../MoldFilter/MoldFilter";
import PlanningRowStateFilter from "../PlanningRowStateFilter/PlanningRowStateFilter";
import PriorityFilter from "../PriorityFilter/PriorityFilter";
import styles from './PlanningFilters.module.scss';

export default function PlanningFilters() {
    const context = useContext(DataViewContext)
    return (
        <Grid container justifyContent="flex-start" alignItems="center" className={styles.PlanningFilters} >
            <LeatherFilter value={context?.filters?.leather} onChange={context?.setFilter} />
            <ClientFilter value={context?.filters?.client} onChange={context?.setFilter} />
            <MoldFilter value={context?.filters?.mold} onChange={context?.setFilter} />
            <ModelFilter value={context?.filters?.model} onChange={context?.setFilter} />
            <PriorityFilter value={context?.filters?.priority} onChange={context?.setFilter} />
            <PlanningRowStateFilter value={context?.filters?.planningRowState} onChange={context?.setFilter} />
        </Grid>
    )
}
