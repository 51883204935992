import { Sector } from "../generated/graphql"
import { useAuth } from "../services/auth"

export const useSector = (): Partial<Sector> => {
    const auth = useAuth()
    return auth?.user?.sector
}

export const useSectorId = () => {
    const sector = useSector()
    return sector?.id
}
