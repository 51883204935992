import { GridColDef } from "@mui/x-data-grid-pro";
import { relationGetter } from "../Datagrid/Datagrid";
import { dateGetter } from "../Datagrid/Getters/DateGetter";
import { ClientRender } from "../Datagrid/Renders/ClientRender";
import { IsLeatherAvailableRender } from "../Datagrid/Renders/IsLeatherAvailable";
import { IsSoleAvailableRender } from "../Datagrid/Renders/IsSoleAvailableRender";

export const workOrderColumns: GridColDef[] = [
    { field: "workOrderid", headerName: "id", width: 70 },
    { field: "quantity", headerName: "quantidade", width: 100 },
    { field: "leather", headerName: "pele", valueGetter: params => relationGetter(params, "leather"), width: 200, sortable: false },
    { field: "sole", headerName: "sola", valueGetter: params => relationGetter(params, "sole"), width: 120, sortable: false },
    { field: "mold", headerName: "forma", valueGetter: params => relationGetter(params, "mold"), width: 160, sortable: false },
    { field: "model", headerName: "modelo", valueGetter: params => relationGetter(params, "model"), width: 160, sortable: false },
    { field: "order", headerName: "encomenda", valueGetter: params => relationGetter(params, "order", "orderId"), width: 160, sortable: false },
    { field: "client", headerName: "cliente", renderCell: params => ClientRender(params), width: 280, sortable: false },
    { field: "orderDate", headerName: "data", valueGetter: dateGetter, width: 120, sortable: true, filterable: false, editable: false },
    { field: "cuttingSystem", headerName: "corte", width: 80 },
    { field: "clientOrderReference", headerName: "ref. enc. cliente", width: 120 },
    { field: "clientModelReference", headerName: "ref. mod. cliente", width: 120 },
    { field: "observations", headerName: "observações", width: 100 },
    { field: "isLeatherAvailable", headerName: "pele disponível", renderCell: IsLeatherAvailableRender, width: 80 },
    { field: "isSoleAvailable", headerName: "sola disponível", renderCell: IsSoleAvailableRender, width: 80 },

]
