import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { MouseEvent, useState } from "react";

// @todo - confrontar com o confirm buttom e ver o que se pode isolar
interface MenuItemWithConfirmationProps {
    icon: any,
    label: string
    disabled?: boolean
    onClick: () => any,
    confirmationRequired?: boolean,
    title?: string
    cancelText?: string
    confirmText?: string
    message?: string
    listItemTextColor?: string
}

export default function MenuItemWithConfirmation(props: MenuItemWithConfirmationProps) {
    const { icon,
        label,
        disabled = false,
        onClick,
        confirmationRequired = true,
        title = "Continuar?",
        cancelText = "Cancelar",
        confirmText = "Continuar",
        message = "Tem a certeza que quer continuar, a ação é irreverísvel",
        listItemTextColor
    } = props

    const [open, setOpen] = useState(false);

    const handleClick = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        confirmationRequired ? setOpen(true) : onClick()
    }

    const handleConfirm = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        onClick()
        setOpen(false);
    }

    const handleCancel = (event: MouseEvent<unknown>) => {
        event.stopPropagation()
        setOpen(false);
    }

    const listItemTextStyle = listItemTextColor ? { color: listItemTextColor } : {}

    return (
        <>
            <MenuItem onClick={handleClick} disabled={disabled}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText style={listItemTextStyle}>
                    {label}
                </ListItemText>
            </MenuItem>
            <Dialog
                open={open}
                onClose={handleCancel}

            >
                <div>
                    <DialogTitle id="alert-dialog-title" >{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="inherit" size="small">
                            {cancelText}
                        </Button>
                        <Button onClick={handleConfirm} color="primary" variant="contained" size="small">
                            {confirmText}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>

        </>
    )

}
