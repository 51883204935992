import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AddManufacturingOrdersToMoldInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddOrdersToClientInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddPlanningRowsToPlanningInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddPlanningRowsToWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddPlanningsToSectorInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkOrdersToLeatherInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkOrdersToModelInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkOrdersToOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkOrdersToSoleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkOrdersToSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkspaceViewColumnsToWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type AddWorkspaceViewsToWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
};

export type Client = {
  __typename?: 'Client';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  orders: Array<Order>;
  updatedAt: Scalars['DateTime'];
};


export type ClientOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  sorting?: InputMaybe<Array<OrderSort>>;
};

export type ClientAggregateGroupBy = {
  __typename?: 'ClientAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ClientAvgAggregate = {
  __typename?: 'ClientAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ClientConnection = {
  __typename?: 'ClientConnection';
  /** Array of nodes. */
  nodes: Array<Client>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ClientCountAggregate = {
  __typename?: 'ClientCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ClientDeleteFilter = {
  and?: InputMaybe<Array<ClientDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ClientDeleteFilter>>;
};

export type ClientDeleteResponse = {
  __typename?: 'ClientDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClientFilter = {
  and?: InputMaybe<Array<ClientFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ClientFilter>>;
  orders?: InputMaybe<ClientFilterOrderFilter>;
};

export type ClientFilterOrderFilter = {
  and?: InputMaybe<Array<ClientFilterOrderFilter>>;
  clientFk?: InputMaybe<NumberFieldComparison>;
  clientReference?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ClientFilterOrderFilter>>;
};

export type ClientMaxAggregate = {
  __typename?: 'ClientMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ClientMinAggregate = {
  __typename?: 'ClientMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ClientSort = {
  direction: SortDirection;
  field: ClientSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ClientSortFields {
  Id = 'id',
  Name = 'name'
}

export type ClientSumAggregate = {
  __typename?: 'ClientSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ClientUpdateFilter = {
  and?: InputMaybe<Array<ClientUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ClientUpdateFilter>>;
};

export type CreateClient = {
  name: Scalars['String'];
};

export type CreateLeather = {
  name: Scalars['String'];
};

export type CreateManyClientsInput = {
  /** Array of records to create */
  clients: Array<CreateClient>;
};

export type CreateManyLeathersInput = {
  /** Array of records to create */
  leathers: Array<CreateLeather>;
};

export type CreateManyModelsInput = {
  /** Array of records to create */
  models: Array<CreateModel>;
};

export type CreateManyMoldsInput = {
  /** Array of records to create */
  molds: Array<CreateMold>;
};

export type CreateManyNotificationsInput = {
  /** Array of records to create */
  notifications: Array<CreateNotification>;
};

export type CreateManyOrdersInput = {
  /** Array of records to create */
  orders: Array<CreateOrder>;
};

export type CreateManyPlanningRowStatesInput = {
  /** Array of records to create */
  planningRowStates: Array<CreatePlanningRowState>;
};

export type CreateManyPlanningRowsInput = {
  /** Array of records to create */
  planningRows: Array<CreatePlanningRow>;
};

export type CreateManyPlanningStatesInput = {
  /** Array of records to create */
  planningStates: Array<CreatePlanningState>;
};

export type CreateManyPlanningsInput = {
  /** Array of records to create */
  plannings: Array<CreatePlanning>;
};

export type CreateManySectorsInput = {
  /** Array of records to create */
  sectors: Array<CreateSector>;
};

export type CreateManySolesInput = {
  /** Array of records to create */
  soles: Array<CreateSole>;
};

export type CreateManySuppliersInput = {
  /** Array of records to create */
  suppliers: Array<CreateSupplier>;
};

export type CreateManyUsersInput = {
  /** Array of records to create */
  users: Array<CreateUser>;
};

export type CreateManyWorkOrdersInput = {
  /** Array of records to create */
  workOrders: Array<CreateWorkOrder>;
};

export type CreateModel = {
  name: Scalars['String'];
};

export type CreateMold = {
  name: Scalars['String'];
};

export type CreateNotification = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isCleared?: InputMaybe<Scalars['Boolean']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateOneClientInput = {
  /** The record to create */
  client: CreateClient;
};

export type CreateOneLeatherInput = {
  /** The record to create */
  leather: CreateLeather;
};

export type CreateOneModelInput = {
  /** The record to create */
  model: CreateModel;
};

export type CreateOneMoldInput = {
  /** The record to create */
  mold: CreateMold;
};

export type CreateOneNotificationInput = {
  /** The record to create */
  notification: CreateNotification;
};

export type CreateOneOrderInput = {
  /** The record to create */
  order: CreateOrder;
};

export type CreateOnePlanningInput = {
  /** The record to create */
  planning: CreatePlanning;
};

export type CreateOnePlanningRowInput = {
  /** The record to create */
  planningRow: CreatePlanningRow;
};

export type CreateOnePlanningRowStateInput = {
  /** The record to create */
  planningRowState: CreatePlanningRowState;
};

export type CreateOnePlanningStateInput = {
  /** The record to create */
  planningState: CreatePlanningState;
};

export type CreateOneSectorInput = {
  /** The record to create */
  sector: CreateSector;
};

export type CreateOneSoleInput = {
  /** The record to create */
  sole: CreateSole;
};

export type CreateOneSupplierInput = {
  /** The record to create */
  supplier: CreateSupplier;
};

export type CreateOneUserInput = {
  /** The record to create */
  user: CreateUser;
};

export type CreateOneWorkOrderInput = {
  /** The record to create */
  workOrder: CreateWorkOrder;
};

export type CreateOrder = {
  client: Scalars['ID'];
  clientReference: Scalars['String'];
  orderId: Scalars['Float'];
};

export type CreatePlanning = {
  planningState: Scalars['ID'];
  sector: Scalars['ID'];
  week: Scalars['Float'];
  year: Scalars['Float'];
};

export type CreatePlanningRow = {
  observations?: InputMaybe<Scalars['String']>;
  planning: Scalars['ID'];
  workOrder: Scalars['ID'];
};

export type CreatePlanningRowState = {
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreatePlanningState = {
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateSector = {
  name: Scalars['String'];
};

export type CreateSole = {
  name: Scalars['String'];
};

export type CreateSupplier = {
  name: Scalars['String'];
};

export type CreateUser = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  role: Scalars['String'];
  sector?: InputMaybe<Scalars['ID']>;
};

export type CreateWorkOrder = {
  clientModelReference?: InputMaybe<Scalars['String']>;
  clientOrderReference?: InputMaybe<Scalars['String']>;
  cuttingSystem: CuttingSystem;
  isLeatherAvailable?: InputMaybe<Scalars['Boolean']>;
  isSoleAvailable?: InputMaybe<Scalars['Boolean']>;
  leather: Scalars['ID'];
  model: Scalars['ID'];
  mold: Scalars['ID'];
  observations?: InputMaybe<Scalars['String']>;
  order: Scalars['ID'];
  orderDate: Scalars['DateTime'];
  quantity: Scalars['Float'];
  sole: Scalars['ID'];
  state?: InputMaybe<WorkOrderState>;
  supplier?: InputMaybe<Scalars['ID']>;
  workOrderid: Scalars['Float'];
};

export enum CuttingSystem {
  B = 'B',
  M = 'M'
}

export type CuttingSystemFilterComparison = {
  eq?: InputMaybe<CuttingSystem>;
  gt?: InputMaybe<CuttingSystem>;
  gte?: InputMaybe<CuttingSystem>;
  iLike?: InputMaybe<CuttingSystem>;
  in?: InputMaybe<Array<CuttingSystem>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<CuttingSystem>;
  lt?: InputMaybe<CuttingSystem>;
  lte?: InputMaybe<CuttingSystem>;
  neq?: InputMaybe<CuttingSystem>;
  notILike?: InputMaybe<CuttingSystem>;
  notIn?: InputMaybe<Array<CuttingSystem>>;
  notLike?: InputMaybe<CuttingSystem>;
};

export type DeleteManyClientsInput = {
  /** Filter to find records to delete */
  filter: ClientDeleteFilter;
};

export type DeleteManyLeathersInput = {
  /** Filter to find records to delete */
  filter: LeatherDeleteFilter;
};

export type DeleteManyModelsInput = {
  /** Filter to find records to delete */
  filter: ModelDeleteFilter;
};

export type DeleteManyMoldsInput = {
  /** Filter to find records to delete */
  filter: MoldDeleteFilter;
};

export type DeleteManyNotificationsInput = {
  /** Filter to find records to delete */
  filter: NotificationDeleteFilter;
};

export type DeleteManyOrdersInput = {
  /** Filter to find records to delete */
  filter: OrderDeleteFilter;
};

export type DeleteManyPlanningRowStatesInput = {
  /** Filter to find records to delete */
  filter: PlanningRowStateDeleteFilter;
};

export type DeleteManyPlanningRowsInput = {
  /** Filter to find records to delete */
  filter: PlanningRowDeleteFilter;
};

export type DeleteManyPlanningStatesInput = {
  /** Filter to find records to delete */
  filter: PlanningStateDeleteFilter;
};

export type DeleteManyPlanningsInput = {
  /** Filter to find records to delete */
  filter: PlanningDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteManySectorsInput = {
  /** Filter to find records to delete */
  filter: SectorDeleteFilter;
};

export type DeleteManySolesInput = {
  /** Filter to find records to delete */
  filter: SoleDeleteFilter;
};

export type DeleteManySuppliersInput = {
  /** Filter to find records to delete */
  filter: SupplierDeleteFilter;
};

export type DeleteManyUsersInput = {
  /** Filter to find records to delete */
  filter: UserDeleteFilter;
};

export type DeleteManyWorkOrdersInput = {
  /** Filter to find records to delete */
  filter: WorkOrderDeleteFilter;
};

export type DeleteOneClientInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneLeatherInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneModelInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneMoldInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneNotificationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneOrderInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePlanningInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePlanningRowInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePlanningRowStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOnePlanningStateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSectorInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSoleInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneSupplierInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneUserInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DeleteOneWorkOrderInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type DuplicatePlanningInput = {
  planning: Scalars['ID'];
  sector: Scalars['ID'];
  week: Scalars['Float'];
  year: Scalars['Float'];
};

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  iLike?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  neq?: InputMaybe<Scalars['ID']>;
  notILike?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
  notLike?: InputMaybe<Scalars['ID']>;
};

export type ImportManyWorkOrdersInput = {
  path: Scalars['Upload'];
};

export type Leather = {
  __typename?: 'Leather';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workOrders: Array<WorkOrder>;
};


export type LeatherWorkOrdersArgs = {
  filter?: InputMaybe<WorkOrderFilter>;
  sorting?: InputMaybe<Array<WorkOrderSort>>;
};

export type LeatherAggregateGroupBy = {
  __typename?: 'LeatherAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LeatherAvgAggregate = {
  __typename?: 'LeatherAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type LeatherConnection = {
  __typename?: 'LeatherConnection';
  /** Array of nodes. */
  nodes: Array<Leather>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type LeatherCountAggregate = {
  __typename?: 'LeatherCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type LeatherDeleteFilter = {
  and?: InputMaybe<Array<LeatherDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LeatherDeleteFilter>>;
};

export type LeatherDeleteResponse = {
  __typename?: 'LeatherDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LeatherFilter = {
  and?: InputMaybe<Array<LeatherFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LeatherFilter>>;
  workOrder?: InputMaybe<LeatherFilterWorkOrderFilter>;
};

export type LeatherFilterWorkOrderFilter = {
  and?: InputMaybe<Array<LeatherFilterWorkOrderFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<LeatherFilterWorkOrderFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type LeatherMaxAggregate = {
  __typename?: 'LeatherMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LeatherMinAggregate = {
  __typename?: 'LeatherMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type LeatherSort = {
  direction: SortDirection;
  field: LeatherSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LeatherSortFields {
  Id = 'id',
  Name = 'name'
}

export type LeatherSumAggregate = {
  __typename?: 'LeatherSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type LeatherUpdateFilter = {
  and?: InputMaybe<Array<LeatherUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LeatherUpdateFilter>>;
};

export type ManageWorkspaceViewColumn = {
  column?: InputMaybe<Scalars['String']>;
  oldIndex?: InputMaybe<Scalars['Float']>;
  size?: InputMaybe<Scalars['Float']>;
  targetIndex?: InputMaybe<Scalars['Float']>;
  view: Scalars['String'];
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type Model = {
  __typename?: 'Model';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workOrders: Array<WorkOrder>;
};


export type ModelWorkOrdersArgs = {
  filter?: InputMaybe<WorkOrderFilter>;
  sorting?: InputMaybe<Array<WorkOrderSort>>;
};

export type ModelAggregateGroupBy = {
  __typename?: 'ModelAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ModelAvgAggregate = {
  __typename?: 'ModelAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ModelConnection = {
  __typename?: 'ModelConnection';
  /** Array of nodes. */
  nodes: Array<Model>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type ModelCountAggregate = {
  __typename?: 'ModelCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type ModelDeleteFilter = {
  and?: InputMaybe<Array<ModelDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ModelDeleteFilter>>;
};

export type ModelDeleteResponse = {
  __typename?: 'ModelDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ModelFilter = {
  and?: InputMaybe<Array<ModelFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ModelFilter>>;
  workOrder?: InputMaybe<ModelFilterWorkOrderFilter>;
};

export type ModelFilterWorkOrderFilter = {
  and?: InputMaybe<Array<ModelFilterWorkOrderFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ModelFilterWorkOrderFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type ModelMaxAggregate = {
  __typename?: 'ModelMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ModelMinAggregate = {
  __typename?: 'ModelMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ModelSort = {
  direction: SortDirection;
  field: ModelSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ModelSortFields {
  Id = 'id',
  Name = 'name'
}

export type ModelSumAggregate = {
  __typename?: 'ModelSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ModelUpdateFilter = {
  and?: InputMaybe<Array<ModelUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ModelUpdateFilter>>;
};

export type Mold = {
  __typename?: 'Mold';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  manufacturingOrders: Array<WorkOrder>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type MoldManufacturingOrdersArgs = {
  filter?: InputMaybe<WorkOrderFilter>;
  sorting?: InputMaybe<Array<WorkOrderSort>>;
};

export type MoldAggregateGroupBy = {
  __typename?: 'MoldAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type MoldAvgAggregate = {
  __typename?: 'MoldAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MoldConnection = {
  __typename?: 'MoldConnection';
  /** Array of nodes. */
  nodes: Array<Mold>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type MoldCountAggregate = {
  __typename?: 'MoldCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type MoldDeleteFilter = {
  and?: InputMaybe<Array<MoldDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MoldDeleteFilter>>;
};

export type MoldDeleteResponse = {
  __typename?: 'MoldDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MoldFilter = {
  and?: InputMaybe<Array<MoldFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  manufacturingOrder?: InputMaybe<MoldFilterWorkOrderFilter>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MoldFilter>>;
};

export type MoldFilterWorkOrderFilter = {
  and?: InputMaybe<Array<MoldFilterWorkOrderFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<MoldFilterWorkOrderFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type MoldMaxAggregate = {
  __typename?: 'MoldMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type MoldMinAggregate = {
  __typename?: 'MoldMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type MoldSort = {
  direction: SortDirection;
  field: MoldSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MoldSortFields {
  Id = 'id',
  Name = 'name'
}

export type MoldSumAggregate = {
  __typename?: 'MoldSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MoldUpdateFilter = {
  and?: InputMaybe<Array<MoldUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MoldUpdateFilter>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addManufacturingOrdersToMold: Mold;
  addOrdersToClient: Client;
  addPlanningRowsToPlanning: Planning;
  addPlanningRowsToWorkOrder: WorkOrder;
  addPlanningsToSector: Sector;
  addWorkOrdersToLeather: Leather;
  addWorkOrdersToModel: Model;
  addWorkOrdersToOrder: Order;
  addWorkOrdersToSole: Sole;
  addWorkOrdersToSupplier: Supplier;
  addWorkspaceViewColumnsToWorkspaceView: WorkspaceView;
  addWorkspaceViewsToWorkspace: Workspace;
  createManyClients: Array<Client>;
  createManyLeathers: Array<Leather>;
  createManyModels: Array<Model>;
  createManyMolds: Array<Mold>;
  createManyNotifications: Array<Notification>;
  createManyOrders: Array<Order>;
  createManyPlanningRowStates: Array<PlanningRowState>;
  createManyPlanningRows: Array<PlanningRow>;
  createManyPlanningStates: Array<PlanningState>;
  createManyPlannings: Array<Planning>;
  createManySectors: Array<Sector>;
  createManySoles: Array<Sole>;
  createManySuppliers: Array<Supplier>;
  createManyUsers: Array<User>;
  createManyWorkOrders: Array<WorkOrder>;
  createOneClient: Client;
  createOneLeather: Leather;
  createOneModel: Model;
  createOneMold: Mold;
  createOneNotification: Notification;
  createOneOrder: Order;
  createOnePlanning: Planning;
  createOnePlanningRow: PlanningRow;
  createOnePlanningRowState: PlanningRowState;
  createOnePlanningState: PlanningState;
  createOneSector: Sector;
  createOneSole: Sole;
  createOneSupplier: Supplier;
  createOneUser: User;
  createOneWorkOrder: WorkOrder;
  deleteManyClients: DeleteManyResponse;
  deleteManyLeathers: DeleteManyResponse;
  deleteManyModels: DeleteManyResponse;
  deleteManyMolds: DeleteManyResponse;
  deleteManyNotifications: DeleteManyResponse;
  deleteManyOrders: DeleteManyResponse;
  deleteManyPlanningRowStates: DeleteManyResponse;
  deleteManyPlanningRows: DeleteManyResponse;
  deleteManyPlanningStates: DeleteManyResponse;
  deleteManyPlannings: DeleteManyResponse;
  deleteManySectors: DeleteManyResponse;
  deleteManySoles: DeleteManyResponse;
  deleteManySuppliers: DeleteManyResponse;
  deleteManyUsers: DeleteManyResponse;
  deleteManyWorkOrders: DeleteManyResponse;
  deleteOneClient: ClientDeleteResponse;
  deleteOneLeather: LeatherDeleteResponse;
  deleteOneModel: ModelDeleteResponse;
  deleteOneMold: MoldDeleteResponse;
  deleteOneNotification: NotificationDeleteResponse;
  deleteOneOrder: OrderDeleteResponse;
  deleteOnePlanning: PlanningDeleteResponse;
  deleteOnePlanningRow: PlanningRowDeleteResponse;
  deleteOnePlanningRowState: PlanningRowStateDeleteResponse;
  deleteOnePlanningState: PlanningStateDeleteResponse;
  deleteOneSector: SectorDeleteResponse;
  deleteOneSole: SoleDeleteResponse;
  deleteOneSupplier: SupplierDeleteResponse;
  deleteOneUser: UserDeleteResponse;
  deleteOneWorkOrder: WorkOrderDeleteResponse;
  duplicatePlanning: Planning;
  generateImportTemplate: Scalars['String'];
  importWorkOrders: Scalars['Boolean'];
  manageWorkspaceViewColumn: Scalars['Boolean'];
  removeClientFromOrder: Order;
  removeLeatherFromWorkOrder: WorkOrder;
  removeManufacturingOrdersFromMold: Mold;
  removeModelFromWorkOrder: WorkOrder;
  removeMoldFromWorkOrder: WorkOrder;
  removeOrderFromWorkOrder: WorkOrder;
  removeOrdersFromClient: Client;
  removePlanningFromPlanningRow: PlanningRow;
  removePlanningRowStateFromPlanningRow: PlanningRow;
  removePlanningRowsFromPlanning: Planning;
  removePlanningRowsFromWorkOrder: WorkOrder;
  removePlanningStateFromPlanning: Planning;
  removePlanningsFromSector: Sector;
  removeSectorFromPlanning: Planning;
  removeSectorFromUser: User;
  removeSoleFromWorkOrder: WorkOrder;
  removeSupplierFromWorkOrder: WorkOrder;
  removeUserFromNotification: Notification;
  removeUserFromWorkspace: Workspace;
  removeWorkOrderFromPlanningRow: PlanningRow;
  removeWorkOrdersFromLeather: Leather;
  removeWorkOrdersFromModel: Model;
  removeWorkOrdersFromOrder: Order;
  removeWorkOrdersFromSole: Sole;
  removeWorkOrdersFromSupplier: Supplier;
  removeWorkspaceFromWorkspaceView: WorkspaceView;
  removeWorkspaceViewColumnsFromWorkspaceView: WorkspaceView;
  removeWorkspaceViewsFromWorkspace: Workspace;
  sendResetPasswordEmail: Scalars['Boolean'];
  setClientOnOrder: Order;
  setLeatherOnWorkOrder: WorkOrder;
  setManufacturingOrdersOnMold: Mold;
  setModelOnWorkOrder: WorkOrder;
  setMoldOnWorkOrder: WorkOrder;
  setOrderOnWorkOrder: WorkOrder;
  setOrdersOnClient: Client;
  setPassword: Scalars['String'];
  setPlanningOnPlanningRow: PlanningRow;
  setPlanningRowStateOnPlanningRow: PlanningRow;
  setPlanningRowsOnPlanning: Planning;
  setPlanningRowsOnWorkOrder: WorkOrder;
  setPlanningStateOnPlanning: Planning;
  setPlanningsOnSector: Sector;
  setSectorOnPlanning: Planning;
  setSectorOnUser: User;
  setSoleOnWorkOrder: WorkOrder;
  setSupplierOnWorkOrder: WorkOrder;
  setUserOnNotification: Notification;
  setUserOnWorkspace: Workspace;
  setWorkOrderOnPlanningRow: PlanningRow;
  setWorkOrdersOnLeather: Leather;
  setWorkOrdersOnModel: Model;
  setWorkOrdersOnOrder: Order;
  setWorkOrdersOnSole: Sole;
  setWorkOrdersOnSupplier: Supplier;
  setWorkspaceOnWorkspaceView: WorkspaceView;
  setWorkspaceView: Scalars['Boolean'];
  setWorkspaceViewColumnsOnWorkspaceView: WorkspaceView;
  setWorkspaceViewsOnWorkspace: Workspace;
  updateManyClients: UpdateManyResponse;
  updateManyLeathers: UpdateManyResponse;
  updateManyModels: UpdateManyResponse;
  updateManyMolds: UpdateManyResponse;
  updateManyNotifications: UpdateManyResponse;
  updateManyOrders: UpdateManyResponse;
  updateManyPlanningRowStates: UpdateManyResponse;
  updateManyPlanningRows: UpdateManyResponse;
  updateManyPlanningStates: UpdateManyResponse;
  updateManyPlannings: UpdateManyResponse;
  updateManySectors: UpdateManyResponse;
  updateManySoles: UpdateManyResponse;
  updateManySuppliers: UpdateManyResponse;
  updateManyUsers: UpdateManyResponse;
  updateManyWorkOrders: UpdateManyResponse;
  updateMe: User;
  updateOneClient: Client;
  updateOneLeather: Leather;
  updateOneModel: Model;
  updateOneMold: Mold;
  updateOneNotification: Notification;
  updateOneOrder: Order;
  updateOnePlanning: Planning;
  updateOnePlanningRow: PlanningRow;
  updateOnePlanningRowState: PlanningRowState;
  updateOnePlanningState: PlanningState;
  updateOneSector: Sector;
  updateOneSole: Sole;
  updateOneSupplier: Supplier;
  updateOneUser: User;
  updateOneWorkOrder: WorkOrder;
  validateWorkOrdersImport: Array<ValidationResult>;
};


export type MutationAddManufacturingOrdersToMoldArgs = {
  input: AddManufacturingOrdersToMoldInput;
};


export type MutationAddOrdersToClientArgs = {
  input: AddOrdersToClientInput;
};


export type MutationAddPlanningRowsToPlanningArgs = {
  input: AddPlanningRowsToPlanningInput;
};


export type MutationAddPlanningRowsToWorkOrderArgs = {
  input: AddPlanningRowsToWorkOrderInput;
};


export type MutationAddPlanningsToSectorArgs = {
  input: AddPlanningsToSectorInput;
};


export type MutationAddWorkOrdersToLeatherArgs = {
  input: AddWorkOrdersToLeatherInput;
};


export type MutationAddWorkOrdersToModelArgs = {
  input: AddWorkOrdersToModelInput;
};


export type MutationAddWorkOrdersToOrderArgs = {
  input: AddWorkOrdersToOrderInput;
};


export type MutationAddWorkOrdersToSoleArgs = {
  input: AddWorkOrdersToSoleInput;
};


export type MutationAddWorkOrdersToSupplierArgs = {
  input: AddWorkOrdersToSupplierInput;
};


export type MutationAddWorkspaceViewColumnsToWorkspaceViewArgs = {
  input: AddWorkspaceViewColumnsToWorkspaceViewInput;
};


export type MutationAddWorkspaceViewsToWorkspaceArgs = {
  input: AddWorkspaceViewsToWorkspaceInput;
};


export type MutationCreateManyClientsArgs = {
  input: CreateManyClientsInput;
};


export type MutationCreateManyLeathersArgs = {
  input: CreateManyLeathersInput;
};


export type MutationCreateManyModelsArgs = {
  input: CreateManyModelsInput;
};


export type MutationCreateManyMoldsArgs = {
  input: CreateManyMoldsInput;
};


export type MutationCreateManyNotificationsArgs = {
  input: CreateManyNotificationsInput;
};


export type MutationCreateManyOrdersArgs = {
  input: CreateManyOrdersInput;
};


export type MutationCreateManyPlanningRowStatesArgs = {
  input: CreateManyPlanningRowStatesInput;
};


export type MutationCreateManyPlanningRowsArgs = {
  input: CreateManyPlanningRowsInput;
};


export type MutationCreateManyPlanningStatesArgs = {
  input: CreateManyPlanningStatesInput;
};


export type MutationCreateManyPlanningsArgs = {
  input: CreateManyPlanningsInput;
};


export type MutationCreateManySectorsArgs = {
  input: CreateManySectorsInput;
};


export type MutationCreateManySolesArgs = {
  input: CreateManySolesInput;
};


export type MutationCreateManySuppliersArgs = {
  input: CreateManySuppliersInput;
};


export type MutationCreateManyUsersArgs = {
  input: CreateManyUsersInput;
};


export type MutationCreateManyWorkOrdersArgs = {
  input: CreateManyWorkOrdersInput;
};


export type MutationCreateOneClientArgs = {
  input: CreateOneClientInput;
};


export type MutationCreateOneLeatherArgs = {
  input: CreateOneLeatherInput;
};


export type MutationCreateOneModelArgs = {
  input: CreateOneModelInput;
};


export type MutationCreateOneMoldArgs = {
  input: CreateOneMoldInput;
};


export type MutationCreateOneNotificationArgs = {
  input: CreateOneNotificationInput;
};


export type MutationCreateOneOrderArgs = {
  input: CreateOneOrderInput;
};


export type MutationCreateOnePlanningArgs = {
  input: CreateOnePlanningInput;
};


export type MutationCreateOnePlanningRowArgs = {
  input: CreateOnePlanningRowInput;
};


export type MutationCreateOnePlanningRowStateArgs = {
  input: CreateOnePlanningRowStateInput;
};


export type MutationCreateOnePlanningStateArgs = {
  input: CreateOnePlanningStateInput;
};


export type MutationCreateOneSectorArgs = {
  input: CreateOneSectorInput;
};


export type MutationCreateOneSoleArgs = {
  input: CreateOneSoleInput;
};


export type MutationCreateOneSupplierArgs = {
  input: CreateOneSupplierInput;
};


export type MutationCreateOneUserArgs = {
  input: CreateOneUserInput;
};


export type MutationCreateOneWorkOrderArgs = {
  input: CreateOneWorkOrderInput;
};


export type MutationDeleteManyClientsArgs = {
  input: DeleteManyClientsInput;
};


export type MutationDeleteManyLeathersArgs = {
  input: DeleteManyLeathersInput;
};


export type MutationDeleteManyModelsArgs = {
  input: DeleteManyModelsInput;
};


export type MutationDeleteManyMoldsArgs = {
  input: DeleteManyMoldsInput;
};


export type MutationDeleteManyNotificationsArgs = {
  input: DeleteManyNotificationsInput;
};


export type MutationDeleteManyOrdersArgs = {
  input: DeleteManyOrdersInput;
};


export type MutationDeleteManyPlanningRowStatesArgs = {
  input: DeleteManyPlanningRowStatesInput;
};


export type MutationDeleteManyPlanningRowsArgs = {
  input: DeleteManyPlanningRowsInput;
};


export type MutationDeleteManyPlanningStatesArgs = {
  input: DeleteManyPlanningStatesInput;
};


export type MutationDeleteManyPlanningsArgs = {
  input: DeleteManyPlanningsInput;
};


export type MutationDeleteManySectorsArgs = {
  input: DeleteManySectorsInput;
};


export type MutationDeleteManySolesArgs = {
  input: DeleteManySolesInput;
};


export type MutationDeleteManySuppliersArgs = {
  input: DeleteManySuppliersInput;
};


export type MutationDeleteManyUsersArgs = {
  input: DeleteManyUsersInput;
};


export type MutationDeleteManyWorkOrdersArgs = {
  input: DeleteManyWorkOrdersInput;
};


export type MutationDeleteOneClientArgs = {
  input: DeleteOneClientInput;
};


export type MutationDeleteOneLeatherArgs = {
  input: DeleteOneLeatherInput;
};


export type MutationDeleteOneModelArgs = {
  input: DeleteOneModelInput;
};


export type MutationDeleteOneMoldArgs = {
  input: DeleteOneMoldInput;
};


export type MutationDeleteOneNotificationArgs = {
  input: DeleteOneNotificationInput;
};


export type MutationDeleteOneOrderArgs = {
  input: DeleteOneOrderInput;
};


export type MutationDeleteOnePlanningArgs = {
  input: DeleteOnePlanningInput;
};


export type MutationDeleteOnePlanningRowArgs = {
  input: DeleteOnePlanningRowInput;
};


export type MutationDeleteOnePlanningRowStateArgs = {
  input: DeleteOnePlanningRowStateInput;
};


export type MutationDeleteOnePlanningStateArgs = {
  input: DeleteOnePlanningStateInput;
};


export type MutationDeleteOneSectorArgs = {
  input: DeleteOneSectorInput;
};


export type MutationDeleteOneSoleArgs = {
  input: DeleteOneSoleInput;
};


export type MutationDeleteOneSupplierArgs = {
  input: DeleteOneSupplierInput;
};


export type MutationDeleteOneUserArgs = {
  input: DeleteOneUserInput;
};


export type MutationDeleteOneWorkOrderArgs = {
  input: DeleteOneWorkOrderInput;
};


export type MutationDuplicatePlanningArgs = {
  input: DuplicatePlanningInput;
};


export type MutationImportWorkOrdersArgs = {
  input: ImportManyWorkOrdersInput;
};


export type MutationManageWorkspaceViewColumnArgs = {
  input: ManageWorkspaceViewColumn;
};


export type MutationRemoveClientFromOrderArgs = {
  input: RemoveClientFromOrderInput;
};


export type MutationRemoveLeatherFromWorkOrderArgs = {
  input: RemoveLeatherFromWorkOrderInput;
};


export type MutationRemoveManufacturingOrdersFromMoldArgs = {
  input: RemoveManufacturingOrdersFromMoldInput;
};


export type MutationRemoveModelFromWorkOrderArgs = {
  input: RemoveModelFromWorkOrderInput;
};


export type MutationRemoveMoldFromWorkOrderArgs = {
  input: RemoveMoldFromWorkOrderInput;
};


export type MutationRemoveOrderFromWorkOrderArgs = {
  input: RemoveOrderFromWorkOrderInput;
};


export type MutationRemoveOrdersFromClientArgs = {
  input: RemoveOrdersFromClientInput;
};


export type MutationRemovePlanningFromPlanningRowArgs = {
  input: RemovePlanningFromPlanningRowInput;
};


export type MutationRemovePlanningRowStateFromPlanningRowArgs = {
  input: RemovePlanningRowStateFromPlanningRowInput;
};


export type MutationRemovePlanningRowsFromPlanningArgs = {
  input: RemovePlanningRowsFromPlanningInput;
};


export type MutationRemovePlanningRowsFromWorkOrderArgs = {
  input: RemovePlanningRowsFromWorkOrderInput;
};


export type MutationRemovePlanningStateFromPlanningArgs = {
  input: RemovePlanningStateFromPlanningInput;
};


export type MutationRemovePlanningsFromSectorArgs = {
  input: RemovePlanningsFromSectorInput;
};


export type MutationRemoveSectorFromPlanningArgs = {
  input: RemoveSectorFromPlanningInput;
};


export type MutationRemoveSectorFromUserArgs = {
  input: RemoveSectorFromUserInput;
};


export type MutationRemoveSoleFromWorkOrderArgs = {
  input: RemoveSoleFromWorkOrderInput;
};


export type MutationRemoveSupplierFromWorkOrderArgs = {
  input: RemoveSupplierFromWorkOrderInput;
};


export type MutationRemoveUserFromNotificationArgs = {
  input: RemoveUserFromNotificationInput;
};


export type MutationRemoveUserFromWorkspaceArgs = {
  input: RemoveUserFromWorkspaceInput;
};


export type MutationRemoveWorkOrderFromPlanningRowArgs = {
  input: RemoveWorkOrderFromPlanningRowInput;
};


export type MutationRemoveWorkOrdersFromLeatherArgs = {
  input: RemoveWorkOrdersFromLeatherInput;
};


export type MutationRemoveWorkOrdersFromModelArgs = {
  input: RemoveWorkOrdersFromModelInput;
};


export type MutationRemoveWorkOrdersFromOrderArgs = {
  input: RemoveWorkOrdersFromOrderInput;
};


export type MutationRemoveWorkOrdersFromSoleArgs = {
  input: RemoveWorkOrdersFromSoleInput;
};


export type MutationRemoveWorkOrdersFromSupplierArgs = {
  input: RemoveWorkOrdersFromSupplierInput;
};


export type MutationRemoveWorkspaceFromWorkspaceViewArgs = {
  input: RemoveWorkspaceFromWorkspaceViewInput;
};


export type MutationRemoveWorkspaceViewColumnsFromWorkspaceViewArgs = {
  input: RemoveWorkspaceViewColumnsFromWorkspaceViewInput;
};


export type MutationRemoveWorkspaceViewsFromWorkspaceArgs = {
  input: RemoveWorkspaceViewsFromWorkspaceInput;
};


export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String'];
};


export type MutationSetClientOnOrderArgs = {
  input: SetClientOnOrderInput;
};


export type MutationSetLeatherOnWorkOrderArgs = {
  input: SetLeatherOnWorkOrderInput;
};


export type MutationSetManufacturingOrdersOnMoldArgs = {
  input: SetManufacturingOrdersOnMoldInput;
};


export type MutationSetModelOnWorkOrderArgs = {
  input: SetModelOnWorkOrderInput;
};


export type MutationSetMoldOnWorkOrderArgs = {
  input: SetMoldOnWorkOrderInput;
};


export type MutationSetOrderOnWorkOrderArgs = {
  input: SetOrderOnWorkOrderInput;
};


export type MutationSetOrdersOnClientArgs = {
  input: SetOrdersOnClientInput;
};


export type MutationSetPasswordArgs = {
  input: SetPasswordInput;
};


export type MutationSetPlanningOnPlanningRowArgs = {
  input: SetPlanningOnPlanningRowInput;
};


export type MutationSetPlanningRowStateOnPlanningRowArgs = {
  input: SetPlanningRowStateOnPlanningRowInput;
};


export type MutationSetPlanningRowsOnPlanningArgs = {
  input: SetPlanningRowsOnPlanningInput;
};


export type MutationSetPlanningRowsOnWorkOrderArgs = {
  input: SetPlanningRowsOnWorkOrderInput;
};


export type MutationSetPlanningStateOnPlanningArgs = {
  input: SetPlanningStateOnPlanningInput;
};


export type MutationSetPlanningsOnSectorArgs = {
  input: SetPlanningsOnSectorInput;
};


export type MutationSetSectorOnPlanningArgs = {
  input: SetSectorOnPlanningInput;
};


export type MutationSetSectorOnUserArgs = {
  input: SetSectorOnUserInput;
};


export type MutationSetSoleOnWorkOrderArgs = {
  input: SetSoleOnWorkOrderInput;
};


export type MutationSetSupplierOnWorkOrderArgs = {
  input: SetSupplierOnWorkOrderInput;
};


export type MutationSetUserOnNotificationArgs = {
  input: SetUserOnNotificationInput;
};


export type MutationSetUserOnWorkspaceArgs = {
  input: SetUserOnWorkspaceInput;
};


export type MutationSetWorkOrderOnPlanningRowArgs = {
  input: SetWorkOrderOnPlanningRowInput;
};


export type MutationSetWorkOrdersOnLeatherArgs = {
  input: SetWorkOrdersOnLeatherInput;
};


export type MutationSetWorkOrdersOnModelArgs = {
  input: SetWorkOrdersOnModelInput;
};


export type MutationSetWorkOrdersOnOrderArgs = {
  input: SetWorkOrdersOnOrderInput;
};


export type MutationSetWorkOrdersOnSoleArgs = {
  input: SetWorkOrdersOnSoleInput;
};


export type MutationSetWorkOrdersOnSupplierArgs = {
  input: SetWorkOrdersOnSupplierInput;
};


export type MutationSetWorkspaceOnWorkspaceViewArgs = {
  input: SetWorkspaceOnWorkspaceViewInput;
};


export type MutationSetWorkspaceViewArgs = {
  input: SetWorkspaceView;
};


export type MutationSetWorkspaceViewColumnsOnWorkspaceViewArgs = {
  input: SetWorkspaceViewColumnsOnWorkspaceViewInput;
};


export type MutationSetWorkspaceViewsOnWorkspaceArgs = {
  input: SetWorkspaceViewsOnWorkspaceInput;
};


export type MutationUpdateManyClientsArgs = {
  input: UpdateManyClientsInput;
};


export type MutationUpdateManyLeathersArgs = {
  input: UpdateManyLeathersInput;
};


export type MutationUpdateManyModelsArgs = {
  input: UpdateManyModelsInput;
};


export type MutationUpdateManyMoldsArgs = {
  input: UpdateManyMoldsInput;
};


export type MutationUpdateManyNotificationsArgs = {
  input: UpdateManyNotificationsInput;
};


export type MutationUpdateManyOrdersArgs = {
  input: UpdateManyOrdersInput;
};


export type MutationUpdateManyPlanningRowStatesArgs = {
  input: UpdateManyPlanningRowStatesInput;
};


export type MutationUpdateManyPlanningRowsArgs = {
  input: UpdateManyPlanningRowsInput;
};


export type MutationUpdateManyPlanningStatesArgs = {
  input: UpdateManyPlanningStatesInput;
};


export type MutationUpdateManyPlanningsArgs = {
  input: UpdateManyPlanningsInput;
};


export type MutationUpdateManySectorsArgs = {
  input: UpdateManySectorsInput;
};


export type MutationUpdateManySolesArgs = {
  input: UpdateManySolesInput;
};


export type MutationUpdateManySuppliersArgs = {
  input: UpdateManySuppliersInput;
};


export type MutationUpdateManyUsersArgs = {
  input: UpdateManyUsersInput;
};


export type MutationUpdateManyWorkOrdersArgs = {
  input: UpdateManyWorkOrdersInput;
};


export type MutationUpdateMeArgs = {
  input: UpdateMe;
};


export type MutationUpdateOneClientArgs = {
  input: UpdateOneClientInput;
};


export type MutationUpdateOneLeatherArgs = {
  input: UpdateOneLeatherInput;
};


export type MutationUpdateOneModelArgs = {
  input: UpdateOneModelInput;
};


export type MutationUpdateOneMoldArgs = {
  input: UpdateOneMoldInput;
};


export type MutationUpdateOneNotificationArgs = {
  input: UpdateOneNotificationInput;
};


export type MutationUpdateOneOrderArgs = {
  input: UpdateOneOrderInput;
};


export type MutationUpdateOnePlanningArgs = {
  input: UpdateOnePlanningInput;
};


export type MutationUpdateOnePlanningRowArgs = {
  input: UpdateOnePlanningRowInput;
};


export type MutationUpdateOnePlanningRowStateArgs = {
  input: UpdateOnePlanningRowStateInput;
};


export type MutationUpdateOnePlanningStateArgs = {
  input: UpdateOnePlanningStateInput;
};


export type MutationUpdateOneSectorArgs = {
  input: UpdateOneSectorInput;
};


export type MutationUpdateOneSoleArgs = {
  input: UpdateOneSoleInput;
};


export type MutationUpdateOneSupplierArgs = {
  input: UpdateOneSupplierInput;
};


export type MutationUpdateOneUserArgs = {
  input: UpdateOneUserInput;
};


export type MutationUpdateOneWorkOrderArgs = {
  input: UpdateOneWorkOrderInput;
};


export type MutationValidateWorkOrdersImportArgs = {
  input: ImportManyWorkOrdersInput;
};

export type Notification = {
  __typename?: 'Notification';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isCleared: Scalars['Boolean'];
  isOpen: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type NotificationAggregateGroupBy = {
  __typename?: 'NotificationAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  isCleared?: Maybe<Scalars['Boolean']>;
};

export type NotificationAvgAggregate = {
  __typename?: 'NotificationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** Array of nodes. */
  nodes: Array<Notification>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type NotificationCountAggregate = {
  __typename?: 'NotificationCountAggregate';
  id?: Maybe<Scalars['Int']>;
  isCleared?: Maybe<Scalars['Int']>;
};

export type NotificationDeleteFilter = {
  and?: InputMaybe<Array<NotificationDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isCleared?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<NotificationDeleteFilter>>;
};

export type NotificationDeleteResponse = {
  __typename?: 'NotificationDeleteResponse';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isCleared?: Maybe<Scalars['Boolean']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  isRead?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NotificationFilter = {
  and?: InputMaybe<Array<NotificationFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isCleared?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<NotificationFilter>>;
  user?: InputMaybe<NotificationFilterUserFilter>;
};

export type NotificationFilterUserFilter = {
  and?: InputMaybe<Array<NotificationFilterUserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<NotificationFilterUserFilter>>;
  role?: InputMaybe<UserRolesFilterComparison>;
};

export type NotificationMaxAggregate = {
  __typename?: 'NotificationMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type NotificationMinAggregate = {
  __typename?: 'NotificationMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type NotificationSort = {
  direction: SortDirection;
  field: NotificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum NotificationSortFields {
  Id = 'id',
  IsCleared = 'isCleared'
}

export type NotificationSumAggregate = {
  __typename?: 'NotificationSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type NotificationUpdateFilter = {
  and?: InputMaybe<Array<NotificationUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  isCleared?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<NotificationUpdateFilter>>;
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']>;
};

export type Order = {
  __typename?: 'Order';
  client: Client;
  clientFk: Scalars['Float'];
  clientReference?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  orderId: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  workOrders: Array<WorkOrder>;
};


export type OrderWorkOrdersArgs = {
  filter?: InputMaybe<WorkOrderFilter>;
  sorting?: InputMaybe<Array<WorkOrderSort>>;
};

export type OrderAggregateGroupBy = {
  __typename?: 'OrderAggregateGroupBy';
  clientFk?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type OrderAvgAggregate = {
  __typename?: 'OrderAvgAggregate';
  clientFk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  /** Array of nodes. */
  nodes: Array<Order>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type OrderCountAggregate = {
  __typename?: 'OrderCountAggregate';
  clientFk?: Maybe<Scalars['Int']>;
  clientReference?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

export type OrderDeleteFilter = {
  and?: InputMaybe<Array<OrderDeleteFilter>>;
  clientFk?: InputMaybe<NumberFieldComparison>;
  clientReference?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderDeleteFilter>>;
};

export type OrderDeleteResponse = {
  __typename?: 'OrderDeleteResponse';
  clientFk?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  orderId?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OrderFilter = {
  and?: InputMaybe<Array<OrderFilter>>;
  client?: InputMaybe<OrderFilterClientFilter>;
  clientFk?: InputMaybe<NumberFieldComparison>;
  clientReference?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderFilter>>;
  workOrder?: InputMaybe<OrderFilterWorkOrderFilter>;
};

export type OrderFilterClientFilter = {
  and?: InputMaybe<Array<OrderFilterClientFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrderFilterClientFilter>>;
};

export type OrderFilterWorkOrderFilter = {
  and?: InputMaybe<Array<OrderFilterWorkOrderFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderFilterWorkOrderFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type OrderMaxAggregate = {
  __typename?: 'OrderMaxAggregate';
  clientFk?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type OrderMinAggregate = {
  __typename?: 'OrderMinAggregate';
  clientFk?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type OrderSort = {
  direction: SortDirection;
  field: OrderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrderSortFields {
  ClientFk = 'clientFk',
  ClientReference = 'clientReference',
  Id = 'id'
}

export type OrderSumAggregate = {
  __typename?: 'OrderSumAggregate';
  clientFk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

export type OrderUpdateFilter = {
  and?: InputMaybe<Array<OrderUpdateFilter>>;
  clientFk?: InputMaybe<NumberFieldComparison>;
  clientReference?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<OrderUpdateFilter>>;
};

export type Planning = {
  __typename?: 'Planning';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  planningRows: Array<PlanningRow>;
  planningState: PlanningState;
  sector: Sector;
  updatedAt: Scalars['DateTime'];
  week: Scalars['Float'];
  year: Scalars['Float'];
};


export type PlanningPlanningRowsArgs = {
  filter?: InputMaybe<PlanningRowFilter>;
  sorting?: InputMaybe<Array<PlanningRowSort>>;
};

export type PlanningAggregateGroupBy = {
  __typename?: 'PlanningAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type PlanningAvgAggregate = {
  __typename?: 'PlanningAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type PlanningConnection = {
  __typename?: 'PlanningConnection';
  /** Array of nodes. */
  nodes: Array<Planning>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PlanningCountAggregate = {
  __typename?: 'PlanningCountAggregate';
  id?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type PlanningDeleteFilter = {
  and?: InputMaybe<Array<PlanningDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PlanningDeleteFilter>>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type PlanningDeleteResponse = {
  __typename?: 'PlanningDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type PlanningFilter = {
  and?: InputMaybe<Array<PlanningFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PlanningFilter>>;
  planningRow?: InputMaybe<PlanningFilterPlanningRowFilter>;
  planningState?: InputMaybe<PlanningFilterPlanningStateFilter>;
  sector?: InputMaybe<PlanningFilterSectorFilter>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type PlanningFilterPlanningRowFilter = {
  and?: InputMaybe<Array<PlanningFilterPlanningRowFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningFilterPlanningRowFilter>>;
  priority?: InputMaybe<NumberFieldComparison>;
};

export type PlanningFilterPlanningStateFilter = {
  and?: InputMaybe<Array<PlanningFilterPlanningStateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningFilterPlanningStateFilter>>;
};

export type PlanningFilterSectorFilter = {
  and?: InputMaybe<Array<PlanningFilterSectorFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningFilterSectorFilter>>;
};

export type PlanningMaxAggregate = {
  __typename?: 'PlanningMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type PlanningMinAggregate = {
  __typename?: 'PlanningMinAggregate';
  id?: Maybe<Scalars['ID']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type PlanningRow = {
  __typename?: 'PlanningRow';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  observations?: Maybe<Scalars['String']>;
  planning: Planning;
  planningRowState: PlanningRowState;
  priority: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  workOrder: WorkOrder;
};

export type PlanningRowAggregateGroupBy = {
  __typename?: 'PlanningRowAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  observations?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
};

export type PlanningRowAvgAggregate = {
  __typename?: 'PlanningRowAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

export type PlanningRowConnection = {
  __typename?: 'PlanningRowConnection';
  /** Array of nodes. */
  nodes: Array<PlanningRow>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PlanningRowCountAggregate = {
  __typename?: 'PlanningRowCountAggregate';
  id?: Maybe<Scalars['Int']>;
  observations?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

export type PlanningRowDeleteFilter = {
  and?: InputMaybe<Array<PlanningRowDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningRowDeleteFilter>>;
  priority?: InputMaybe<NumberFieldComparison>;
};

export type PlanningRowDeleteResponse = {
  __typename?: 'PlanningRowDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  observations?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlanningRowFilter = {
  and?: InputMaybe<Array<PlanningRowFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningRowFilter>>;
  planning?: InputMaybe<PlanningRowFilterPlanningFilter>;
  planningRowState?: InputMaybe<PlanningRowFilterPlanningRowStateFilter>;
  priority?: InputMaybe<NumberFieldComparison>;
  workOrder?: InputMaybe<PlanningRowFilterWorkOrderFilter>;
};

export type PlanningRowFilterPlanningFilter = {
  and?: InputMaybe<Array<PlanningRowFilterPlanningFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PlanningRowFilterPlanningFilter>>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type PlanningRowFilterPlanningRowStateFilter = {
  and?: InputMaybe<Array<PlanningRowFilterPlanningRowStateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningRowFilterPlanningRowStateFilter>>;
};

export type PlanningRowFilterWorkOrderFilter = {
  and?: InputMaybe<Array<PlanningRowFilterWorkOrderFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PlanningRowFilterWorkOrderFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type PlanningRowMaxAggregate = {
  __typename?: 'PlanningRowMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  observations?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
};

export type PlanningRowMinAggregate = {
  __typename?: 'PlanningRowMinAggregate';
  id?: Maybe<Scalars['ID']>;
  observations?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Float']>;
};

export type PlanningRowSort = {
  direction: SortDirection;
  field: PlanningRowSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlanningRowSortFields {
  Id = 'id',
  Observations = 'observations',
  Priority = 'priority'
}

export type PlanningRowState = {
  __typename?: 'PlanningRowState';
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PlanningRowStateAggregateGroupBy = {
  __typename?: 'PlanningRowStateAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PlanningRowStateAvgAggregate = {
  __typename?: 'PlanningRowStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PlanningRowStateConnection = {
  __typename?: 'PlanningRowStateConnection';
  /** Array of nodes. */
  nodes: Array<PlanningRowState>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PlanningRowStateCountAggregate = {
  __typename?: 'PlanningRowStateCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type PlanningRowStateDeleteFilter = {
  and?: InputMaybe<Array<PlanningRowStateDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningRowStateDeleteFilter>>;
};

export type PlanningRowStateDeleteResponse = {
  __typename?: 'PlanningRowStateDeleteResponse';
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlanningRowStateFilter = {
  and?: InputMaybe<Array<PlanningRowStateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningRowStateFilter>>;
};

export type PlanningRowStateMaxAggregate = {
  __typename?: 'PlanningRowStateMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PlanningRowStateMinAggregate = {
  __typename?: 'PlanningRowStateMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PlanningRowStateSort = {
  direction: SortDirection;
  field: PlanningRowStateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlanningRowStateSortFields {
  Id = 'id',
  Name = 'name'
}

export type PlanningRowStateSumAggregate = {
  __typename?: 'PlanningRowStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PlanningRowStateUpdateFilter = {
  and?: InputMaybe<Array<PlanningRowStateUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningRowStateUpdateFilter>>;
};

export type PlanningRowSumAggregate = {
  __typename?: 'PlanningRowSumAggregate';
  id?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

export type PlanningRowUpdateFilter = {
  and?: InputMaybe<Array<PlanningRowUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningRowUpdateFilter>>;
  priority?: InputMaybe<NumberFieldComparison>;
};

export type PlanningSort = {
  direction: SortDirection;
  field: PlanningSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlanningSortFields {
  Id = 'id',
  Week = 'week',
  Year = 'year'
}

export type PlanningState = {
  __typename?: 'PlanningState';
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PlanningStateAggregateGroupBy = {
  __typename?: 'PlanningStateAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PlanningStateAvgAggregate = {
  __typename?: 'PlanningStateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PlanningStateConnection = {
  __typename?: 'PlanningStateConnection';
  /** Array of nodes. */
  nodes: Array<PlanningState>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type PlanningStateCountAggregate = {
  __typename?: 'PlanningStateCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type PlanningStateDeleteFilter = {
  and?: InputMaybe<Array<PlanningStateDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningStateDeleteFilter>>;
};

export type PlanningStateDeleteResponse = {
  __typename?: 'PlanningStateDeleteResponse';
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PlanningStateFilter = {
  and?: InputMaybe<Array<PlanningStateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningStateFilter>>;
};

export type PlanningStateMaxAggregate = {
  __typename?: 'PlanningStateMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PlanningStateMinAggregate = {
  __typename?: 'PlanningStateMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type PlanningStateSort = {
  direction: SortDirection;
  field: PlanningStateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlanningStateSortFields {
  Id = 'id',
  Name = 'name'
}

export type PlanningStateSumAggregate = {
  __typename?: 'PlanningStateSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type PlanningStateUpdateFilter = {
  and?: InputMaybe<Array<PlanningStateUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlanningStateUpdateFilter>>;
};

export type PlanningSumAggregate = {
  __typename?: 'PlanningSumAggregate';
  id?: Maybe<Scalars['Float']>;
  week?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type PlanningUpdateFilter = {
  and?: InputMaybe<Array<PlanningUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PlanningUpdateFilter>>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type Query = {
  __typename?: 'Query';
  client?: Maybe<Client>;
  clients: ClientConnection;
  leather?: Maybe<Leather>;
  leathers: LeatherConnection;
  me: User;
  model?: Maybe<Model>;
  models: ModelConnection;
  mold?: Maybe<Mold>;
  molds: MoldConnection;
  notification?: Maybe<Notification>;
  notifications: NotificationConnection;
  order?: Maybe<Order>;
  orders: OrderConnection;
  planning?: Maybe<Planning>;
  planningRow?: Maybe<PlanningRow>;
  planningRowState?: Maybe<PlanningRowState>;
  planningRowStates: PlanningRowStateConnection;
  planningRows: PlanningRowConnection;
  planningState?: Maybe<PlanningState>;
  planningStates: PlanningStateConnection;
  plannings: PlanningConnection;
  sector?: Maybe<Sector>;
  sectors: SectorConnection;
  sole?: Maybe<Sole>;
  soles: SoleConnection;
  status: Scalars['String'];
  supplier?: Maybe<Supplier>;
  suppliers: SupplierConnection;
  user?: Maybe<User>;
  users: UserConnection;
  workOrder?: Maybe<WorkOrder>;
  workOrders: WorkOrderConnection;
  workspace: Workspace;
};


export type QueryClientArgs = {
  id: Scalars['ID'];
};


export type QueryClientsArgs = {
  filter?: InputMaybe<ClientFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ClientSort>>;
};


export type QueryLeatherArgs = {
  id: Scalars['ID'];
};


export type QueryLeathersArgs = {
  filter?: InputMaybe<LeatherFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LeatherSort>>;
};


export type QueryModelArgs = {
  id: Scalars['ID'];
};


export type QueryModelsArgs = {
  filter?: InputMaybe<ModelFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ModelSort>>;
};


export type QueryMoldArgs = {
  id: Scalars['ID'];
};


export type QueryMoldsArgs = {
  filter?: InputMaybe<MoldFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<MoldSort>>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<NotificationSort>>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<OrderSort>>;
};


export type QueryPlanningArgs = {
  id: Scalars['ID'];
};


export type QueryPlanningRowArgs = {
  id: Scalars['ID'];
};


export type QueryPlanningRowStateArgs = {
  id: Scalars['ID'];
};


export type QueryPlanningRowStatesArgs = {
  filter?: InputMaybe<PlanningRowStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningRowStateSort>>;
};


export type QueryPlanningRowsArgs = {
  filter?: InputMaybe<PlanningRowFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningRowSort>>;
};


export type QueryPlanningStateArgs = {
  id: Scalars['ID'];
};


export type QueryPlanningStatesArgs = {
  filter?: InputMaybe<PlanningStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningStateSort>>;
};


export type QueryPlanningsArgs = {
  filter?: InputMaybe<PlanningFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningSort>>;
};


export type QuerySectorArgs = {
  id: Scalars['ID'];
};


export type QuerySectorsArgs = {
  filter?: InputMaybe<SectorFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SectorSort>>;
};


export type QuerySoleArgs = {
  id: Scalars['ID'];
};


export type QuerySolesArgs = {
  filter?: InputMaybe<SoleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SoleSort>>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QuerySuppliersArgs = {
  filter?: InputMaybe<SupplierFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SupplierSort>>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryWorkOrderArgs = {
  id: Scalars['ID'];
};


export type QueryWorkOrdersArgs = {
  filter?: InputMaybe<WorkOrderFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WorkOrderSort>>;
};

export type RemoveClientFromOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveLeatherFromWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveManufacturingOrdersFromMoldInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveModelFromWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveMoldFromWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveOrderFromWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveOrdersFromClientInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemovePlanningFromPlanningRowInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemovePlanningRowStateFromPlanningRowInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemovePlanningRowsFromPlanningInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemovePlanningRowsFromWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemovePlanningStateFromPlanningInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemovePlanningsFromSectorInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveSectorFromPlanningInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveSectorFromUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveSoleFromWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveSupplierFromWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveUserFromWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveWorkOrderFromPlanningRowInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveWorkOrdersFromLeatherInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWorkOrdersFromModelInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWorkOrdersFromOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWorkOrdersFromSoleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWorkOrdersFromSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWorkspaceFromWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type RemoveWorkspaceViewColumnsFromWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type RemoveWorkspaceViewsFromWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type Sector = {
  __typename?: 'Sector';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  plannings: Array<Planning>;
  updatedAt: Scalars['DateTime'];
};


export type SectorPlanningsArgs = {
  filter?: InputMaybe<PlanningFilter>;
  sorting?: InputMaybe<Array<PlanningSort>>;
};

export type SectorAggregateGroupBy = {
  __typename?: 'SectorAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SectorAvgAggregate = {
  __typename?: 'SectorAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SectorConnection = {
  __typename?: 'SectorConnection';
  /** Array of nodes. */
  nodes: Array<Sector>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SectorCountAggregate = {
  __typename?: 'SectorCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type SectorDeleteFilter = {
  and?: InputMaybe<Array<SectorDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SectorDeleteFilter>>;
};

export type SectorDeleteResponse = {
  __typename?: 'SectorDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SectorFilter = {
  and?: InputMaybe<Array<SectorFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SectorFilter>>;
  plannings?: InputMaybe<SectorFilterPlanningFilter>;
};

export type SectorFilterPlanningFilter = {
  and?: InputMaybe<Array<SectorFilterPlanningFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SectorFilterPlanningFilter>>;
  week?: InputMaybe<NumberFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type SectorMaxAggregate = {
  __typename?: 'SectorMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SectorMinAggregate = {
  __typename?: 'SectorMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SectorSort = {
  direction: SortDirection;
  field: SectorSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SectorSortFields {
  Id = 'id',
  Name = 'name'
}

export type SectorSumAggregate = {
  __typename?: 'SectorSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SectorUpdateFilter = {
  and?: InputMaybe<Array<SectorUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SectorUpdateFilter>>;
};

export type SetClientOnOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetLeatherOnWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetManufacturingOrdersOnMoldInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetModelOnWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetMoldOnWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetOrderOnWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetOrdersOnClientInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type SetPlanningOnPlanningRowInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetPlanningRowStateOnPlanningRowInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetPlanningRowsOnPlanningInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetPlanningRowsOnWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetPlanningStateOnPlanningInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetPlanningsOnSectorInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetSectorOnPlanningInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSectorOnUserInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSoleOnWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetSupplierOnWorkOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnNotificationInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetUserOnWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetWorkOrderOnPlanningRowInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetWorkOrdersOnLeatherInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWorkOrdersOnModelInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWorkOrdersOnOrderInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWorkOrdersOnSoleInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWorkOrdersOnSupplierInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWorkspaceOnWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The id of relation. */
  relationId: Scalars['ID'];
};

export type SetWorkspaceView = {
  columns: Array<SetWorkspaceViewColumn>;
  view: Scalars['String'];
};

export type SetWorkspaceViewColumn = {
  name: Scalars['String'];
  size?: InputMaybe<Scalars['Float']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type SetWorkspaceViewColumnsOnWorkspaceViewInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type SetWorkspaceViewsOnWorkspaceInput = {
  /** The id of the record. */
  id: Scalars['ID'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']>;
};

export type Sole = {
  __typename?: 'Sole';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workOrders: Array<WorkOrder>;
};


export type SoleWorkOrdersArgs = {
  filter?: InputMaybe<WorkOrderFilter>;
  sorting?: InputMaybe<Array<WorkOrderSort>>;
};

export type SoleAggregateGroupBy = {
  __typename?: 'SoleAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SoleAvgAggregate = {
  __typename?: 'SoleAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SoleConnection = {
  __typename?: 'SoleConnection';
  /** Array of nodes. */
  nodes: Array<Sole>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SoleCountAggregate = {
  __typename?: 'SoleCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type SoleDeleteFilter = {
  and?: InputMaybe<Array<SoleDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SoleDeleteFilter>>;
};

export type SoleDeleteResponse = {
  __typename?: 'SoleDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SoleFilter = {
  and?: InputMaybe<Array<SoleFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SoleFilter>>;
  workOrders?: InputMaybe<SoleFilterWorkOrderFilter>;
};

export type SoleFilterWorkOrderFilter = {
  and?: InputMaybe<Array<SoleFilterWorkOrderFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SoleFilterWorkOrderFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type SoleMaxAggregate = {
  __typename?: 'SoleMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SoleMinAggregate = {
  __typename?: 'SoleMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SoleSort = {
  direction: SortDirection;
  field: SoleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SoleSortFields {
  Id = 'id',
  Name = 'name'
}

export type SoleSumAggregate = {
  __typename?: 'SoleSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SoleUpdateFilter = {
  and?: InputMaybe<Array<SoleUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SoleUpdateFilter>>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  iLike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  notILike?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  notLike?: InputMaybe<Scalars['String']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workOrders: Array<WorkOrder>;
};


export type SupplierWorkOrdersArgs = {
  filter?: InputMaybe<WorkOrderFilter>;
  sorting?: InputMaybe<Array<WorkOrderSort>>;
};

export type SupplierAggregateGroupBy = {
  __typename?: 'SupplierAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierAvgAggregate = {
  __typename?: 'SupplierAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierConnection = {
  __typename?: 'SupplierConnection';
  /** Array of nodes. */
  nodes: Array<Supplier>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type SupplierCountAggregate = {
  __typename?: 'SupplierCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type SupplierDeleteFilter = {
  and?: InputMaybe<Array<SupplierDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SupplierDeleteFilter>>;
};

export type SupplierDeleteResponse = {
  __typename?: 'SupplierDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierFilter = {
  and?: InputMaybe<Array<SupplierFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SupplierFilter>>;
  workOrder?: InputMaybe<SupplierFilterWorkOrderFilter>;
};

export type SupplierFilterWorkOrderFilter = {
  and?: InputMaybe<Array<SupplierFilterWorkOrderFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SupplierFilterWorkOrderFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type SupplierMaxAggregate = {
  __typename?: 'SupplierMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierMinAggregate = {
  __typename?: 'SupplierMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type SupplierSort = {
  direction: SortDirection;
  field: SupplierSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SupplierSortFields {
  Id = 'id',
  Name = 'name'
}

export type SupplierSumAggregate = {
  __typename?: 'SupplierSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SupplierUpdateFilter = {
  and?: InputMaybe<Array<SupplierUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SupplierUpdateFilter>>;
};

export type UpdateManyClientsInput = {
  /** Filter used to find fields to update */
  filter: ClientUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateClient;
};

export type UpdateManyLeathersInput = {
  /** Filter used to find fields to update */
  filter: LeatherUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateLeather;
};

export type UpdateManyModelsInput = {
  /** Filter used to find fields to update */
  filter: ModelUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateModel;
};

export type UpdateManyMoldsInput = {
  /** Filter used to find fields to update */
  filter: MoldUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateMold;
};

export type UpdateManyNotificationsInput = {
  /** Filter used to find fields to update */
  filter: NotificationUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateNotification;
};

export type UpdateManyOrdersInput = {
  /** Filter used to find fields to update */
  filter: OrderUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateOrder;
};

export type UpdateManyPlanningRowStatesInput = {
  /** Filter used to find fields to update */
  filter: PlanningRowStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePlanningRowState;
};

export type UpdateManyPlanningRowsInput = {
  /** Filter used to find fields to update */
  filter: PlanningRowUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePlanningRow;
};

export type UpdateManyPlanningStatesInput = {
  /** Filter used to find fields to update */
  filter: PlanningStateUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePlanningState;
};

export type UpdateManyPlanningsInput = {
  /** Filter used to find fields to update */
  filter: PlanningUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdatePlanning;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateManySectorsInput = {
  /** Filter used to find fields to update */
  filter: SectorUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateSector;
};

export type UpdateManySolesInput = {
  /** Filter used to find fields to update */
  filter: SoleUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateSole;
};

export type UpdateManySuppliersInput = {
  /** Filter used to find fields to update */
  filter: SupplierUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: CreateSupplier;
};

export type UpdateManyUsersInput = {
  /** Filter used to find fields to update */
  filter: UserUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateUser;
};

export type UpdateManyWorkOrdersInput = {
  /** Filter used to find fields to update */
  filter: WorkOrderUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateWorkOrder;
};

export type UpdateMe = {
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type UpdateNotification = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isCleared?: InputMaybe<Scalars['Boolean']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateOneClientInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateClient;
};

export type UpdateOneLeatherInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateLeather;
};

export type UpdateOneModelInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateModel;
};

export type UpdateOneMoldInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateMold;
};

export type UpdateOneNotificationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateNotification;
};

export type UpdateOneOrderInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateOrder;
};

export type UpdateOnePlanningInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePlanning;
};

export type UpdateOnePlanningRowInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePlanningRow;
};

export type UpdateOnePlanningRowStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePlanningRowState;
};

export type UpdateOnePlanningStateInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdatePlanningState;
};

export type UpdateOneSectorInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateSector;
};

export type UpdateOneSoleInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateSole;
};

export type UpdateOneSupplierInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: CreateSupplier;
};

export type UpdateOneUserInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateUser;
};

export type UpdateOneWorkOrderInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateWorkOrder;
};

export type UpdateOrder = {
  client?: InputMaybe<Scalars['ID']>;
  clientReference?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['Float']>;
};

export type UpdatePlanning = {
  planningState?: InputMaybe<Scalars['ID']>;
  sector?: InputMaybe<Scalars['ID']>;
  week?: InputMaybe<Scalars['Float']>;
  year?: InputMaybe<Scalars['Float']>;
};

export type UpdatePlanningRow = {
  observations?: InputMaybe<Scalars['String']>;
  planning?: InputMaybe<Scalars['ID']>;
  planningRowState?: InputMaybe<Scalars['ID']>;
  priority?: InputMaybe<Scalars['Float']>;
  workOrder?: InputMaybe<Scalars['ID']>;
};

export type UpdatePlanningRowState = {
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdatePlanningState = {
  code?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateUser = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  sector?: InputMaybe<Scalars['ID']>;
};

export type UpdateWorkOrder = {
  clientModelReference?: InputMaybe<Scalars['String']>;
  clientOrderReference?: InputMaybe<Scalars['String']>;
  cuttingSystem?: InputMaybe<CuttingSystem>;
  isLeatherAvailable?: InputMaybe<Scalars['Boolean']>;
  isSoleAvailable?: InputMaybe<Scalars['Boolean']>;
  leather?: InputMaybe<Scalars['ID']>;
  model?: InputMaybe<Scalars['ID']>;
  mold?: InputMaybe<Scalars['ID']>;
  observations?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['ID']>;
  orderDate?: InputMaybe<Scalars['DateTime']>;
  quantity?: InputMaybe<Scalars['Float']>;
  sole?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<WorkOrderState>;
  supplier?: InputMaybe<Scalars['ID']>;
  workOrderid?: InputMaybe<Scalars['Float']>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  role: UserRoles;
  sector?: Maybe<Sector>;
  updatedAt: Scalars['DateTime'];
};

export type UserAggregateGroupBy = {
  __typename?: 'UserAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Array of nodes. */
  nodes: Array<User>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
};

export type UserDeleteFilter = {
  and?: InputMaybe<Array<UserDeleteFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserDeleteFilter>>;
  role?: InputMaybe<UserRolesFilterComparison>;
};

export type UserDeleteResponse = {
  __typename?: 'UserDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  role?: InputMaybe<UserRolesFilterComparison>;
  sector?: InputMaybe<UserFilterSectorFilter>;
};

export type UserFilterSectorFilter = {
  and?: InputMaybe<Array<UserFilterSectorFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserFilterSectorFilter>>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
};

export enum UserRoles {
  Manager = 'MANAGER',
  Overseer = 'OVERSEER'
}

export type UserRolesFilterComparison = {
  eq?: InputMaybe<UserRoles>;
  gt?: InputMaybe<UserRoles>;
  gte?: InputMaybe<UserRoles>;
  iLike?: InputMaybe<UserRoles>;
  in?: InputMaybe<Array<UserRoles>>;
  is?: InputMaybe<Scalars['Boolean']>;
  isNot?: InputMaybe<Scalars['Boolean']>;
  like?: InputMaybe<UserRoles>;
  lt?: InputMaybe<UserRoles>;
  lte?: InputMaybe<UserRoles>;
  neq?: InputMaybe<UserRoles>;
  notILike?: InputMaybe<UserRoles>;
  notIn?: InputMaybe<Array<UserRoles>>;
  notLike?: InputMaybe<UserRoles>;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  Id = 'id',
  Name = 'name',
  Role = 'role'
}

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type UserUpdateFilter = {
  and?: InputMaybe<Array<UserUpdateFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserUpdateFilter>>;
  role?: InputMaybe<UserRolesFilterComparison>;
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  address: Scalars['String'];
  errors?: Maybe<Array<Scalars['String']>>;
  informations?: Maybe<Array<Scalars['String']>>;
  value?: Maybe<Scalars['String']>;
  warnings?: Maybe<Array<Scalars['String']>>;
};

export type WorkOrder = {
  __typename?: 'WorkOrder';
  clientModelReference?: Maybe<Scalars['String']>;
  clientOrderReference?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  cuttingSystem: CuttingSystem;
  id: Scalars['ID'];
  isLeatherAvailable: Scalars['Boolean'];
  isSoleAvailable: Scalars['Boolean'];
  leather: Leather;
  model: Model;
  mold: Mold;
  observations?: Maybe<Scalars['String']>;
  order: Order;
  orderDate: Scalars['String'];
  planningRows: Array<PlanningRow>;
  quantity: Scalars['Float'];
  sole: Sole;
  state: Scalars['String'];
  supplier: Supplier;
  updatedAt: Scalars['DateTime'];
  workOrderid: Scalars['Float'];
  year: Scalars['Float'];
};


export type WorkOrderPlanningRowsArgs = {
  filter?: InputMaybe<PlanningRowFilter>;
  sorting?: InputMaybe<Array<PlanningRowSort>>;
};

export type WorkOrderAggregateGroupBy = {
  __typename?: 'WorkOrderAggregateGroupBy';
  cuttingSystem?: Maybe<CuttingSystem>;
  id?: Maybe<Scalars['ID']>;
  orderDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Float']>;
};

export type WorkOrderAvgAggregate = {
  __typename?: 'WorkOrderAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type WorkOrderConnection = {
  __typename?: 'WorkOrderConnection';
  /** Array of nodes. */
  nodes: Array<WorkOrder>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type WorkOrderCountAggregate = {
  __typename?: 'WorkOrderCountAggregate';
  cuttingSystem?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  orderDate?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type WorkOrderDeleteFilter = {
  and?: InputMaybe<Array<WorkOrderDeleteFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkOrderDeleteFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type WorkOrderDeleteResponse = {
  __typename?: 'WorkOrderDeleteResponse';
  clientModelReference?: Maybe<Scalars['String']>;
  clientOrderReference?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuttingSystem?: Maybe<CuttingSystem>;
  id?: Maybe<Scalars['ID']>;
  isLeatherAvailable?: Maybe<Scalars['Boolean']>;
  isSoleAvailable?: Maybe<Scalars['Boolean']>;
  observations?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  workOrderid?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type WorkOrderFilter = {
  and?: InputMaybe<Array<WorkOrderFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  leather?: InputMaybe<WorkOrderFilterLeatherFilter>;
  model?: InputMaybe<WorkOrderFilterModelFilter>;
  mold?: InputMaybe<WorkOrderFilterMoldFilter>;
  or?: InputMaybe<Array<WorkOrderFilter>>;
  order?: InputMaybe<WorkOrderFilterOrderFilter>;
  orderDate?: InputMaybe<StringFieldComparison>;
  planningRow?: InputMaybe<WorkOrderFilterPlanningRowFilter>;
  quantity?: InputMaybe<NumberFieldComparison>;
  sole?: InputMaybe<WorkOrderFilterSoleFilter>;
  state?: InputMaybe<StringFieldComparison>;
  supplier?: InputMaybe<WorkOrderFilterSupplierFilter>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type WorkOrderFilterLeatherFilter = {
  and?: InputMaybe<Array<WorkOrderFilterLeatherFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkOrderFilterLeatherFilter>>;
};

export type WorkOrderFilterModelFilter = {
  and?: InputMaybe<Array<WorkOrderFilterModelFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkOrderFilterModelFilter>>;
};

export type WorkOrderFilterMoldFilter = {
  and?: InputMaybe<Array<WorkOrderFilterMoldFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkOrderFilterMoldFilter>>;
};

export type WorkOrderFilterOrderFilter = {
  and?: InputMaybe<Array<WorkOrderFilterOrderFilter>>;
  clientFk?: InputMaybe<NumberFieldComparison>;
  clientReference?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkOrderFilterOrderFilter>>;
};

export type WorkOrderFilterPlanningRowFilter = {
  and?: InputMaybe<Array<WorkOrderFilterPlanningRowFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  observations?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkOrderFilterPlanningRowFilter>>;
  priority?: InputMaybe<NumberFieldComparison>;
};

export type WorkOrderFilterSoleFilter = {
  and?: InputMaybe<Array<WorkOrderFilterSoleFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkOrderFilterSoleFilter>>;
};

export type WorkOrderFilterSupplierFilter = {
  and?: InputMaybe<Array<WorkOrderFilterSupplierFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkOrderFilterSupplierFilter>>;
};

export type WorkOrderMaxAggregate = {
  __typename?: 'WorkOrderMaxAggregate';
  cuttingSystem?: Maybe<CuttingSystem>;
  id?: Maybe<Scalars['ID']>;
  orderDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Float']>;
};

export type WorkOrderMinAggregate = {
  __typename?: 'WorkOrderMinAggregate';
  cuttingSystem?: Maybe<CuttingSystem>;
  id?: Maybe<Scalars['ID']>;
  orderDate?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Float']>;
};

export type WorkOrderSort = {
  direction: SortDirection;
  field: WorkOrderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkOrderSortFields {
  CuttingSystem = 'cuttingSystem',
  Id = 'id',
  OrderDate = 'orderDate',
  Quantity = 'quantity',
  State = 'state',
  Year = 'year'
}

export enum WorkOrderState {
  Created = 'CREATED',
  Draft = 'DRAFT',
  Publish = 'PUBLISH'
}

export type WorkOrderSumAggregate = {
  __typename?: 'WorkOrderSumAggregate';
  id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

export type WorkOrderUpdateFilter = {
  and?: InputMaybe<Array<WorkOrderUpdateFilter>>;
  cuttingSystem?: InputMaybe<CuttingSystemFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkOrderUpdateFilter>>;
  orderDate?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<NumberFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  year?: InputMaybe<NumberFieldComparison>;
};

export type Workspace = {
  __typename?: 'Workspace';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  workspaceViews?: Maybe<Array<WorkspaceView>>;
};


export type WorkspaceWorkspaceViewsArgs = {
  filter?: InputMaybe<WorkspaceViewFilter>;
  sorting?: InputMaybe<Array<WorkspaceViewSort>>;
};

export type WorkspaceAggregateGroupBy = {
  __typename?: 'WorkspaceAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceAvgAggregate = {
  __typename?: 'WorkspaceAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceCountAggregate = {
  __typename?: 'WorkspaceCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type WorkspaceMaxAggregate = {
  __typename?: 'WorkspaceMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceMinAggregate = {
  __typename?: 'WorkspaceMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceSumAggregate = {
  __typename?: 'WorkspaceSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceView = {
  __typename?: 'WorkspaceView';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workspace: Workspace;
  workspaceViewColumns?: Maybe<Array<WorkspaceViewColumn>>;
};


export type WorkspaceViewWorkspaceViewColumnsArgs = {
  filter?: InputMaybe<WorkspaceViewColumnFilter>;
  sorting?: InputMaybe<Array<WorkspaceViewColumnSort>>;
};

export type WorkspaceViewAggregateGroupBy = {
  __typename?: 'WorkspaceViewAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkspaceViewAvgAggregate = {
  __typename?: 'WorkspaceViewAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceViewColumn = {
  __typename?: 'WorkspaceViewColumn';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Float'];
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  visible: Scalars['Boolean'];
};

export type WorkspaceViewColumnAggregateGroupBy = {
  __typename?: 'WorkspaceViewColumnAggregateGroupBy';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceViewColumnAvgAggregate = {
  __typename?: 'WorkspaceViewColumnAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceViewColumnCountAggregate = {
  __typename?: 'WorkspaceViewColumnCountAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type WorkspaceViewColumnFilter = {
  and?: InputMaybe<Array<WorkspaceViewColumnFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkspaceViewColumnFilter>>;
};

export type WorkspaceViewColumnMaxAggregate = {
  __typename?: 'WorkspaceViewColumnMaxAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceViewColumnMinAggregate = {
  __typename?: 'WorkspaceViewColumnMinAggregate';
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceViewColumnSort = {
  direction: SortDirection;
  field: WorkspaceViewColumnSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkspaceViewColumnSortFields {
  Id = 'id'
}

export type WorkspaceViewColumnSumAggregate = {
  __typename?: 'WorkspaceViewColumnSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type WorkspaceViewCountAggregate = {
  __typename?: 'WorkspaceViewCountAggregate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
};

export type WorkspaceViewFilter = {
  and?: InputMaybe<Array<WorkspaceViewFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WorkspaceViewFilter>>;
  workspace?: InputMaybe<WorkspaceViewFilterWorkspaceFilter>;
  workspaceViewColumns?: InputMaybe<WorkspaceViewFilterWorkspaceViewColumnFilter>;
};

export type WorkspaceViewFilterWorkspaceFilter = {
  and?: InputMaybe<Array<WorkspaceViewFilterWorkspaceFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkspaceViewFilterWorkspaceFilter>>;
};

export type WorkspaceViewFilterWorkspaceViewColumnFilter = {
  and?: InputMaybe<Array<WorkspaceViewFilterWorkspaceViewColumnFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WorkspaceViewFilterWorkspaceViewColumnFilter>>;
};

export type WorkspaceViewMaxAggregate = {
  __typename?: 'WorkspaceViewMaxAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkspaceViewMinAggregate = {
  __typename?: 'WorkspaceViewMinAggregate';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type WorkspaceViewSort = {
  direction: SortDirection;
  field: WorkspaceViewSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkspaceViewSortFields {
  Id = 'id',
  Name = 'name'
}

export type WorkspaceViewSumAggregate = {
  __typename?: 'WorkspaceViewSumAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type ClientsQueryVariables = Exact<{
  filter?: InputMaybe<ClientFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ClientSort> | ClientSort>;
}>;


export type ClientsQuery = { __typename?: 'Query', clients: { __typename?: 'ClientConnection', nodes: Array<{ __typename?: 'Client', id: string, name: string }> } };

export type CreateOneClientMutationVariables = Exact<{
  input: CreateOneClientInput;
}>;


export type CreateOneClientMutation = { __typename?: 'Mutation', createOneClient: { __typename?: 'Client', id: string } };

export type UpdateOneClientMutationVariables = Exact<{
  input: UpdateOneClientInput;
}>;


export type UpdateOneClientMutation = { __typename?: 'Mutation', updateOneClient: { __typename?: 'Client', id: string } };

export type DeleteOneClientMutationVariables = Exact<{
  input: DeleteOneClientInput;
}>;


export type DeleteOneClientMutation = { __typename?: 'Mutation', deleteOneClient: { __typename?: 'ClientDeleteResponse', id?: string | null } };

export type LeathersQueryVariables = Exact<{
  filter?: InputMaybe<LeatherFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LeatherSort> | LeatherSort>;
}>;


export type LeathersQuery = { __typename?: 'Query', leathers: { __typename?: 'LeatherConnection', nodes: Array<{ __typename?: 'Leather', id: string, name: string }> } };

export type CreateOneLeatherMutationVariables = Exact<{
  input: CreateOneLeatherInput;
}>;


export type CreateOneLeatherMutation = { __typename?: 'Mutation', createOneLeather: { __typename?: 'Leather', id: string } };

export type UpdateOneLeatherMutationVariables = Exact<{
  input: UpdateOneLeatherInput;
}>;


export type UpdateOneLeatherMutation = { __typename?: 'Mutation', updateOneLeather: { __typename?: 'Leather', id: string } };

export type DeleteOneLeatherMutationVariables = Exact<{
  input: DeleteOneLeatherInput;
}>;


export type DeleteOneLeatherMutation = { __typename?: 'Mutation', deleteOneLeather: { __typename?: 'LeatherDeleteResponse', id?: string | null } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, name: string, email: string, role: UserRoles, sector?: { __typename?: 'Sector', id: string, name: string } | null } };

export type UpdateMeMutationVariables = Exact<{
  input: UpdateMe;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe: { __typename?: 'User', id: string, name: string } };

export type ModelsQueryVariables = Exact<{
  filter?: InputMaybe<ModelFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ModelSort> | ModelSort>;
}>;


export type ModelsQuery = { __typename?: 'Query', models: { __typename?: 'ModelConnection', nodes: Array<{ __typename?: 'Model', id: string, name: string }> } };

export type CreateOneModelMutationVariables = Exact<{
  input: CreateOneModelInput;
}>;


export type CreateOneModelMutation = { __typename?: 'Mutation', createOneModel: { __typename?: 'Model', id: string } };

export type UpdateOneModelMutationVariables = Exact<{
  input: UpdateOneModelInput;
}>;


export type UpdateOneModelMutation = { __typename?: 'Mutation', updateOneModel: { __typename?: 'Model', id: string } };

export type DeleteOneModelMutationVariables = Exact<{
  input: DeleteOneModelInput;
}>;


export type DeleteOneModelMutation = { __typename?: 'Mutation', deleteOneModel: { __typename?: 'ModelDeleteResponse', id?: string | null } };

export type MoldsQueryVariables = Exact<{
  filter?: InputMaybe<MoldFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<MoldSort> | MoldSort>;
}>;


export type MoldsQuery = { __typename?: 'Query', molds: { __typename?: 'MoldConnection', nodes: Array<{ __typename?: 'Mold', id: string, name: string }> } };

export type CreateOneMoldMutationVariables = Exact<{
  input: CreateOneMoldInput;
}>;


export type CreateOneMoldMutation = { __typename?: 'Mutation', createOneMold: { __typename?: 'Mold', id: string } };

export type UpdateOneMoldMutationVariables = Exact<{
  input: UpdateOneMoldInput;
}>;


export type UpdateOneMoldMutation = { __typename?: 'Mutation', updateOneMold: { __typename?: 'Mold', id: string } };

export type DeleteOneMoldMutationVariables = Exact<{
  input: DeleteOneMoldInput;
}>;


export type DeleteOneMoldMutation = { __typename?: 'Mutation', deleteOneMold: { __typename?: 'MoldDeleteResponse', id?: string | null } };

export type NotificationsQueryVariables = Exact<{
  filter?: InputMaybe<NotificationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<NotificationSort> | NotificationSort>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', nodes: Array<{ __typename?: 'Notification', id: string, content: string, isOpen: boolean, isRead: boolean, isCleared: boolean, createdAt: any, link?: string | null, user: { __typename?: 'User', id: string, name: string } }> } };

export type UpdateOneNotificationMutationVariables = Exact<{
  input: UpdateOneNotificationInput;
}>;


export type UpdateOneNotificationMutation = { __typename?: 'Mutation', updateOneNotification: { __typename?: 'Notification', id: string } };

export type UpdateManyNotificationsMutationVariables = Exact<{
  input: UpdateManyNotificationsInput;
}>;


export type UpdateManyNotificationsMutation = { __typename?: 'Mutation', updateManyNotifications: { __typename?: 'UpdateManyResponse', updatedCount: number } };

export type OrdersQueryVariables = Exact<{
  filter?: InputMaybe<OrderFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<OrderSort> | OrderSort>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: { __typename?: 'OrderConnection', totalCount: number, nodes: Array<{ __typename?: 'Order', id: string, orderId: number, clientReference?: string | null, client: { __typename?: 'Client', id: string, name: string } }> } };

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string } | null };

export type CreateOneOrderMutationVariables = Exact<{
  input: CreateOneOrderInput;
}>;


export type CreateOneOrderMutation = { __typename?: 'Mutation', createOneOrder: { __typename?: 'Order', id: string } };

export type UpdateOneOrderMutationVariables = Exact<{
  input: UpdateOneOrderInput;
}>;


export type UpdateOneOrderMutation = { __typename?: 'Mutation', updateOneOrder: { __typename?: 'Order', id: string } };

export type DeleteOneOrderMutationVariables = Exact<{
  input: DeleteOneOrderInput;
}>;


export type DeleteOneOrderMutation = { __typename?: 'Mutation', deleteOneOrder: { __typename?: 'OrderDeleteResponse', id?: string | null } };

export type DeleteManyOrdersMutationVariables = Exact<{
  input: DeleteManyOrdersInput;
}>;


export type DeleteManyOrdersMutation = { __typename?: 'Mutation', deleteManyOrders: { __typename?: 'DeleteManyResponse', deletedCount: number } };

export type PlanningRowStatesQueryVariables = Exact<{
  filter?: InputMaybe<PlanningRowStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningRowStateSort> | PlanningRowStateSort>;
}>;


export type PlanningRowStatesQuery = { __typename?: 'Query', planningRowStates: { __typename?: 'PlanningRowStateConnection', nodes: Array<{ __typename?: 'PlanningRowState', id: string, name: string }> } };

export type PlanningRowsQueryVariables = Exact<{
  filter?: InputMaybe<PlanningRowFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningRowSort> | PlanningRowSort>;
}>;


export type PlanningRowsQuery = { __typename?: 'Query', planningRows: { __typename?: 'PlanningRowConnection', nodes: Array<{ __typename?: 'PlanningRow', id: string, observations?: string | null, priority: number, planningRowState: { __typename?: 'PlanningRowState', id: string, name: string, color?: string | null }, workOrder: { __typename?: 'WorkOrder', id: string, orderDate: string, quantity: number, order: { __typename?: 'Order', client: { __typename?: 'Client', id: string, name: string } }, leather: { __typename?: 'Leather', id: string, name: string }, model: { __typename?: 'Model', id: string, name: string }, mold: { __typename?: 'Mold', id: string, name: string }, sole: { __typename?: 'Sole', id: string, name: string } } }> } };

export type CreateOnePlanningRowMutationVariables = Exact<{
  input: CreateOnePlanningRowInput;
}>;


export type CreateOnePlanningRowMutation = { __typename?: 'Mutation', createOnePlanningRow: { __typename?: 'PlanningRow', id: string } };

export type CreateManyPlanningRowsMutationVariables = Exact<{
  input: CreateManyPlanningRowsInput;
}>;


export type CreateManyPlanningRowsMutation = { __typename?: 'Mutation', createManyPlanningRows: Array<{ __typename?: 'PlanningRow', id: string }> };

export type UpdateOnePlanningRowMutationVariables = Exact<{
  input: UpdateOnePlanningRowInput;
}>;


export type UpdateOnePlanningRowMutation = { __typename?: 'Mutation', updateOnePlanningRow: { __typename?: 'PlanningRow', id: string } };

export type UpdateManyPlanningRowsMutationVariables = Exact<{
  input: UpdateManyPlanningRowsInput;
}>;


export type UpdateManyPlanningRowsMutation = { __typename?: 'Mutation', updateManyPlanningRows: { __typename?: 'UpdateManyResponse', updatedCount: number } };

export type DeleteOnePlanningRowMutationVariables = Exact<{
  input: DeleteOnePlanningRowInput;
}>;


export type DeleteOnePlanningRowMutation = { __typename?: 'Mutation', deleteOnePlanningRow: { __typename?: 'PlanningRowDeleteResponse', id?: string | null } };

export type DeleteManyPlanningRowsMutationVariables = Exact<{
  input: DeleteManyPlanningRowsInput;
}>;


export type DeleteManyPlanningRowsMutation = { __typename?: 'Mutation', deleteManyPlanningRows: { __typename?: 'DeleteManyResponse', deletedCount: number } };

export type PlanningStatesQueryVariables = Exact<{
  filter?: InputMaybe<PlanningStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningStateSort> | PlanningStateSort>;
}>;


export type PlanningStatesQuery = { __typename?: 'Query', planningStates: { __typename?: 'PlanningStateConnection', nodes: Array<{ __typename?: 'PlanningState', id: string, name: string }> } };

export type PlanningsQueryVariables = Exact<{
  filter?: InputMaybe<PlanningFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningSort> | PlanningSort>;
}>;


export type PlanningsQuery = { __typename?: 'Query', plannings: { __typename?: 'PlanningConnection', totalCount: number, nodes: Array<{ __typename?: 'Planning', id: string, week: number, year: number, planningState: { __typename?: 'PlanningState', id: string, name: string, color?: string | null }, sector: { __typename?: 'Sector', id: string, name: string } }> } };

export type CreateOnePlanningMutationVariables = Exact<{
  input: CreateOnePlanningInput;
}>;


export type CreateOnePlanningMutation = { __typename?: 'Mutation', createOnePlanning: { __typename?: 'Planning', id: string } };

export type UpdateOnePlanningMutationVariables = Exact<{
  input: UpdateOnePlanningInput;
}>;


export type UpdateOnePlanningMutation = { __typename?: 'Mutation', updateOnePlanning: { __typename?: 'Planning', id: string } };

export type UpdateManyPlanningsMutationVariables = Exact<{
  input: UpdateManyPlanningsInput;
}>;


export type UpdateManyPlanningsMutation = { __typename?: 'Mutation', updateManyPlannings: { __typename?: 'UpdateManyResponse', updatedCount: number } };

export type DeleteOnePlanningMutationVariables = Exact<{
  input: DeleteOnePlanningInput;
}>;


export type DeleteOnePlanningMutation = { __typename?: 'Mutation', deleteOnePlanning: { __typename?: 'PlanningDeleteResponse', id?: string | null } };

export type DeleteManyPlanningsMutationVariables = Exact<{
  input: DeleteManyPlanningsInput;
}>;


export type DeleteManyPlanningsMutation = { __typename?: 'Mutation', deleteManyPlannings: { __typename?: 'DeleteManyResponse', deletedCount: number } };

export type PlanningQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PlanningQuery = { __typename?: 'Query', planning?: { __typename?: 'Planning', id: string, week: number, year: number, planningState: { __typename?: 'PlanningState', id: string, name: string }, sector: { __typename?: 'Sector', id: string, name: string }, planningRows: Array<{ __typename?: 'PlanningRow', id: string, workOrder: { __typename?: 'WorkOrder', id: string, quantity: number, orderDate: string, sole: { __typename?: 'Sole', id: string, name: string }, model: { __typename?: 'Model', id: string, name: string }, leather: { __typename?: 'Leather', id: string, name: string }, mold: { __typename?: 'Mold', id: string, name: string }, order: { __typename?: 'Order', client: { __typename?: 'Client', id: string, name: string } } } }> } | null };

export type DuplicatePlanningMutationVariables = Exact<{
  input: DuplicatePlanningInput;
}>;


export type DuplicatePlanningMutation = { __typename?: 'Mutation', duplicatePlanning: { __typename?: 'Planning', id: string } };

export type StatusQueryVariables = Exact<{ [key: string]: never; }>;


export type StatusQuery = { __typename?: 'Query', status: string };

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetPasswordEmailMutation = { __typename?: 'Mutation', sendResetPasswordEmail: boolean };

export type SetPasswordMutationVariables = Exact<{
  input: SetPasswordInput;
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword: string };

export type PlanningStateFieldQueryVariables = Exact<{
  filter?: InputMaybe<PlanningStateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PlanningStateSort> | PlanningStateSort>;
}>;


export type PlanningStateFieldQuery = { __typename?: 'Query', planningStates: { __typename?: 'PlanningStateConnection', nodes: Array<{ __typename?: 'PlanningState', id: string, name: string }> } };

export type SectorFieldQueryVariables = Exact<{
  filter?: InputMaybe<SectorFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SectorSort> | SectorSort>;
}>;


export type SectorFieldQuery = { __typename?: 'Query', sectors: { __typename?: 'SectorConnection', nodes: Array<{ __typename?: 'Sector', id: string, name: string }> } };

export type SectorsQueryVariables = Exact<{
  filter?: InputMaybe<SectorFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SectorSort> | SectorSort>;
}>;


export type SectorsQuery = { __typename?: 'Query', sectors: { __typename?: 'SectorConnection', nodes: Array<{ __typename?: 'Sector', id: string, name: string }> } };

export type CreateOneSectorMutationVariables = Exact<{
  input: CreateOneSectorInput;
}>;


export type CreateOneSectorMutation = { __typename?: 'Mutation', createOneSector: { __typename?: 'Sector', id: string } };

export type UpdateOneSectorMutationVariables = Exact<{
  input: UpdateOneSectorInput;
}>;


export type UpdateOneSectorMutation = { __typename?: 'Mutation', updateOneSector: { __typename?: 'Sector', id: string } };

export type DeleteOneSectorMutationVariables = Exact<{
  input: DeleteOneSectorInput;
}>;


export type DeleteOneSectorMutation = { __typename?: 'Mutation', deleteOneSector: { __typename?: 'SectorDeleteResponse', id?: string | null } };

export type SolesQueryVariables = Exact<{
  filter?: InputMaybe<SoleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SoleSort> | SoleSort>;
}>;


export type SolesQuery = { __typename?: 'Query', soles: { __typename?: 'SoleConnection', nodes: Array<{ __typename?: 'Sole', id: string, name: string }> } };

export type CreateOneSoleMutationVariables = Exact<{
  input: CreateOneSoleInput;
}>;


export type CreateOneSoleMutation = { __typename?: 'Mutation', createOneSole: { __typename?: 'Sole', id: string } };

export type UpdateOneSoleMutationVariables = Exact<{
  input: UpdateOneSoleInput;
}>;


export type UpdateOneSoleMutation = { __typename?: 'Mutation', updateOneSole: { __typename?: 'Sole', id: string } };

export type DeleteOneSoleMutationVariables = Exact<{
  input: DeleteOneSoleInput;
}>;


export type DeleteOneSoleMutation = { __typename?: 'Mutation', deleteOneSole: { __typename?: 'SoleDeleteResponse', id?: string | null } };

export type SuppliersQueryVariables = Exact<{
  filter?: InputMaybe<SupplierFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SupplierSort> | SupplierSort>;
}>;


export type SuppliersQuery = { __typename?: 'Query', suppliers: { __typename?: 'SupplierConnection', nodes: Array<{ __typename?: 'Supplier', id: string, name: string }> } };

export type CreateOneSupplierMutationVariables = Exact<{
  input: CreateOneSupplierInput;
}>;


export type CreateOneSupplierMutation = { __typename?: 'Mutation', createOneSupplier: { __typename?: 'Supplier', id: string } };

export type UpdateOneSupplierMutationVariables = Exact<{
  input: UpdateOneSupplierInput;
}>;


export type UpdateOneSupplierMutation = { __typename?: 'Mutation', updateOneSupplier: { __typename?: 'Supplier', id: string } };

export type DeleteOneSupplierMutationVariables = Exact<{
  input: DeleteOneSupplierInput;
}>;


export type DeleteOneSupplierMutation = { __typename?: 'Mutation', deleteOneSupplier: { __typename?: 'SupplierDeleteResponse', id?: string | null } };

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserSort> | UserSort>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', nodes: Array<{ __typename?: 'User', id: string, name: string, email: string, role: UserRoles, sector?: { __typename?: 'Sector', id: string, name: string } | null }> } };

export type CreateOneUserMutationVariables = Exact<{
  input: CreateOneUserInput;
}>;


export type CreateOneUserMutation = { __typename?: 'Mutation', createOneUser: { __typename?: 'User', id: string } };

export type UpdateOneUserMutationVariables = Exact<{
  input: UpdateOneUserInput;
}>;


export type UpdateOneUserMutation = { __typename?: 'Mutation', updateOneUser: { __typename?: 'User', id: string } };

export type DeleteOneUserMutationVariables = Exact<{
  input: DeleteOneUserInput;
}>;


export type DeleteOneUserMutation = { __typename?: 'Mutation', deleteOneUser: { __typename?: 'UserDeleteResponse', id?: string | null } };

export type WorkOrdersQueryVariables = Exact<{
  filter?: InputMaybe<WorkOrderFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WorkOrderSort> | WorkOrderSort>;
}>;


export type WorkOrdersQuery = { __typename?: 'Query', workOrders: { __typename?: 'WorkOrderConnection', totalCount: number, nodes: Array<{ __typename?: 'WorkOrder', id: string, workOrderid: number, quantity: number, orderDate: string, cuttingSystem: CuttingSystem, clientOrderReference?: string | null, clientModelReference?: string | null, observations?: string | null, isSoleAvailable: boolean, isLeatherAvailable: boolean, sole: { __typename?: 'Sole', id: string, name: string }, mold: { __typename?: 'Mold', id: string, name: string }, leather: { __typename?: 'Leather', id: string, name: string }, model: { __typename?: 'Model', id: string, name: string }, order: { __typename?: 'Order', id: string, orderId: number, clientReference?: string | null, createdAt: any, client: { __typename?: 'Client', id: string, name: string } } }> } };

export type WorkOrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WorkOrderQuery = { __typename?: 'Query', workOrder?: { __typename?: 'WorkOrder', id: string } | null };

export type CreateOneWorkOrderMutationVariables = Exact<{
  input: CreateOneWorkOrderInput;
}>;


export type CreateOneWorkOrderMutation = { __typename?: 'Mutation', createOneWorkOrder: { __typename?: 'WorkOrder', id: string } };

export type UpdateOneWorkOrderMutationVariables = Exact<{
  input: UpdateOneWorkOrderInput;
}>;


export type UpdateOneWorkOrderMutation = { __typename?: 'Mutation', updateOneWorkOrder: { __typename?: 'WorkOrder', id: string } };

export type DeleteOneWorkOrderMutationVariables = Exact<{
  input: DeleteOneWorkOrderInput;
}>;


export type DeleteOneWorkOrderMutation = { __typename?: 'Mutation', deleteOneWorkOrder: { __typename?: 'WorkOrderDeleteResponse', id?: string | null } };

export type DeleteManyWorkOrdersMutationVariables = Exact<{
  input: DeleteManyWorkOrdersInput;
}>;


export type DeleteManyWorkOrdersMutation = { __typename?: 'Mutation', deleteManyWorkOrders: { __typename?: 'DeleteManyResponse', deletedCount: number } };

export type GenerateWorkOrderTemplateMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateWorkOrderTemplateMutation = { __typename?: 'Mutation', generateImportTemplate: string };

export type ValidateWorkOrdersImportMutationVariables = Exact<{
  input: ImportManyWorkOrdersInput;
}>;


export type ValidateWorkOrdersImportMutation = { __typename?: 'Mutation', validateWorkOrdersImport: Array<{ __typename?: 'ValidationResult', address: string, value?: string | null, informations?: Array<string> | null, warnings?: Array<string> | null, errors?: Array<string> | null }> };

export type ImportWorkOrdersMutationVariables = Exact<{
  input: ImportManyWorkOrdersInput;
}>;


export type ImportWorkOrdersMutation = { __typename?: 'Mutation', importWorkOrders: boolean };

export type WorkspaceQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspaceQuery = { __typename?: 'Query', workspace: { __typename?: 'Workspace', workspaceViews?: Array<{ __typename?: 'WorkspaceView', name: string, workspaceViewColumns?: Array<{ __typename?: 'WorkspaceViewColumn', name: string, visible: boolean, order: number }> | null }> | null } };

export type ManageWorkspaceViewColumnMutationVariables = Exact<{
  input: ManageWorkspaceViewColumn;
}>;


export type ManageWorkspaceViewColumnMutation = { __typename?: 'Mutation', manageWorkspaceViewColumn: boolean };

export type SetWorkspaceViewMutationVariables = Exact<{
  input: SetWorkspaceView;
}>;


export type SetWorkspaceViewMutation = { __typename?: 'Mutation', setWorkspaceView: boolean };


export const ClientsDocument = gql`
    query clients($filter: ClientFilter, $paging: OffsetPaging, $sorting: [ClientSort!]) {
  clients(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const CreateOneClientDocument = gql`
    mutation createOneClient($input: CreateOneClientInput!) {
  createOneClient(input: $input) {
    id
  }
}
    `;
export type CreateOneClientMutationFn = Apollo.MutationFunction<CreateOneClientMutation, CreateOneClientMutationVariables>;

/**
 * __useCreateOneClientMutation__
 *
 * To run a mutation, you first call `useCreateOneClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneClientMutation, { data, loading, error }] = useCreateOneClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneClientMutation, CreateOneClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneClientMutation, CreateOneClientMutationVariables>(CreateOneClientDocument, options);
      }
export type CreateOneClientMutationHookResult = ReturnType<typeof useCreateOneClientMutation>;
export type CreateOneClientMutationResult = Apollo.MutationResult<CreateOneClientMutation>;
export type CreateOneClientMutationOptions = Apollo.BaseMutationOptions<CreateOneClientMutation, CreateOneClientMutationVariables>;
export const UpdateOneClientDocument = gql`
    mutation updateOneClient($input: UpdateOneClientInput!) {
  updateOneClient(input: $input) {
    id
  }
}
    `;
export type UpdateOneClientMutationFn = Apollo.MutationFunction<UpdateOneClientMutation, UpdateOneClientMutationVariables>;

/**
 * __useUpdateOneClientMutation__
 *
 * To run a mutation, you first call `useUpdateOneClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneClientMutation, { data, loading, error }] = useUpdateOneClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneClientMutation, UpdateOneClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneClientMutation, UpdateOneClientMutationVariables>(UpdateOneClientDocument, options);
      }
export type UpdateOneClientMutationHookResult = ReturnType<typeof useUpdateOneClientMutation>;
export type UpdateOneClientMutationResult = Apollo.MutationResult<UpdateOneClientMutation>;
export type UpdateOneClientMutationOptions = Apollo.BaseMutationOptions<UpdateOneClientMutation, UpdateOneClientMutationVariables>;
export const DeleteOneClientDocument = gql`
    mutation deleteOneClient($input: DeleteOneClientInput!) {
  deleteOneClient(input: $input) {
    id
  }
}
    `;
export type DeleteOneClientMutationFn = Apollo.MutationFunction<DeleteOneClientMutation, DeleteOneClientMutationVariables>;

/**
 * __useDeleteOneClientMutation__
 *
 * To run a mutation, you first call `useDeleteOneClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneClientMutation, { data, loading, error }] = useDeleteOneClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneClientMutation, DeleteOneClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneClientMutation, DeleteOneClientMutationVariables>(DeleteOneClientDocument, options);
      }
export type DeleteOneClientMutationHookResult = ReturnType<typeof useDeleteOneClientMutation>;
export type DeleteOneClientMutationResult = Apollo.MutationResult<DeleteOneClientMutation>;
export type DeleteOneClientMutationOptions = Apollo.BaseMutationOptions<DeleteOneClientMutation, DeleteOneClientMutationVariables>;
export const LeathersDocument = gql`
    query leathers($filter: LeatherFilter, $paging: OffsetPaging, $sorting: [LeatherSort!]) {
  leathers(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useLeathersQuery__
 *
 * To run a query within a React component, call `useLeathersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeathersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeathersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useLeathersQuery(baseOptions?: Apollo.QueryHookOptions<LeathersQuery, LeathersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeathersQuery, LeathersQueryVariables>(LeathersDocument, options);
      }
export function useLeathersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeathersQuery, LeathersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeathersQuery, LeathersQueryVariables>(LeathersDocument, options);
        }
export type LeathersQueryHookResult = ReturnType<typeof useLeathersQuery>;
export type LeathersLazyQueryHookResult = ReturnType<typeof useLeathersLazyQuery>;
export type LeathersQueryResult = Apollo.QueryResult<LeathersQuery, LeathersQueryVariables>;
export const CreateOneLeatherDocument = gql`
    mutation createOneLeather($input: CreateOneLeatherInput!) {
  createOneLeather(input: $input) {
    id
  }
}
    `;
export type CreateOneLeatherMutationFn = Apollo.MutationFunction<CreateOneLeatherMutation, CreateOneLeatherMutationVariables>;

/**
 * __useCreateOneLeatherMutation__
 *
 * To run a mutation, you first call `useCreateOneLeatherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneLeatherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneLeatherMutation, { data, loading, error }] = useCreateOneLeatherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneLeatherMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneLeatherMutation, CreateOneLeatherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneLeatherMutation, CreateOneLeatherMutationVariables>(CreateOneLeatherDocument, options);
      }
export type CreateOneLeatherMutationHookResult = ReturnType<typeof useCreateOneLeatherMutation>;
export type CreateOneLeatherMutationResult = Apollo.MutationResult<CreateOneLeatherMutation>;
export type CreateOneLeatherMutationOptions = Apollo.BaseMutationOptions<CreateOneLeatherMutation, CreateOneLeatherMutationVariables>;
export const UpdateOneLeatherDocument = gql`
    mutation updateOneLeather($input: UpdateOneLeatherInput!) {
  updateOneLeather(input: $input) {
    id
  }
}
    `;
export type UpdateOneLeatherMutationFn = Apollo.MutationFunction<UpdateOneLeatherMutation, UpdateOneLeatherMutationVariables>;

/**
 * __useUpdateOneLeatherMutation__
 *
 * To run a mutation, you first call `useUpdateOneLeatherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneLeatherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneLeatherMutation, { data, loading, error }] = useUpdateOneLeatherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneLeatherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneLeatherMutation, UpdateOneLeatherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneLeatherMutation, UpdateOneLeatherMutationVariables>(UpdateOneLeatherDocument, options);
      }
export type UpdateOneLeatherMutationHookResult = ReturnType<typeof useUpdateOneLeatherMutation>;
export type UpdateOneLeatherMutationResult = Apollo.MutationResult<UpdateOneLeatherMutation>;
export type UpdateOneLeatherMutationOptions = Apollo.BaseMutationOptions<UpdateOneLeatherMutation, UpdateOneLeatherMutationVariables>;
export const DeleteOneLeatherDocument = gql`
    mutation deleteOneLeather($input: DeleteOneLeatherInput!) {
  deleteOneLeather(input: $input) {
    id
  }
}
    `;
export type DeleteOneLeatherMutationFn = Apollo.MutationFunction<DeleteOneLeatherMutation, DeleteOneLeatherMutationVariables>;

/**
 * __useDeleteOneLeatherMutation__
 *
 * To run a mutation, you first call `useDeleteOneLeatherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneLeatherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneLeatherMutation, { data, loading, error }] = useDeleteOneLeatherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneLeatherMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneLeatherMutation, DeleteOneLeatherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneLeatherMutation, DeleteOneLeatherMutationVariables>(DeleteOneLeatherDocument, options);
      }
export type DeleteOneLeatherMutationHookResult = ReturnType<typeof useDeleteOneLeatherMutation>;
export type DeleteOneLeatherMutationResult = Apollo.MutationResult<DeleteOneLeatherMutation>;
export type DeleteOneLeatherMutationOptions = Apollo.BaseMutationOptions<DeleteOneLeatherMutation, DeleteOneLeatherMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    name
    email
    role
    sector {
      id
      name
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UpdateMeDocument = gql`
    mutation updateMe($input: UpdateMe!) {
  updateMe(input: $input) {
    id
    name
  }
}
    `;
export type UpdateMeMutationFn = Apollo.MutationFunction<UpdateMeMutation, UpdateMeMutationVariables>;

/**
 * __useUpdateMeMutation__
 *
 * To run a mutation, you first call `useUpdateMeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeMutation, { data, loading, error }] = useUpdateMeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const ModelsDocument = gql`
    query models($filter: ModelFilter, $paging: OffsetPaging, $sorting: [ModelSort!]) {
  models(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useModelsQuery__
 *
 * To run a query within a React component, call `useModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useModelsQuery(baseOptions?: Apollo.QueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, options);
      }
export function useModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, options);
        }
export type ModelsQueryHookResult = ReturnType<typeof useModelsQuery>;
export type ModelsLazyQueryHookResult = ReturnType<typeof useModelsLazyQuery>;
export type ModelsQueryResult = Apollo.QueryResult<ModelsQuery, ModelsQueryVariables>;
export const CreateOneModelDocument = gql`
    mutation createOneModel($input: CreateOneModelInput!) {
  createOneModel(input: $input) {
    id
  }
}
    `;
export type CreateOneModelMutationFn = Apollo.MutationFunction<CreateOneModelMutation, CreateOneModelMutationVariables>;

/**
 * __useCreateOneModelMutation__
 *
 * To run a mutation, you first call `useCreateOneModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneModelMutation, { data, loading, error }] = useCreateOneModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneModelMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneModelMutation, CreateOneModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneModelMutation, CreateOneModelMutationVariables>(CreateOneModelDocument, options);
      }
export type CreateOneModelMutationHookResult = ReturnType<typeof useCreateOneModelMutation>;
export type CreateOneModelMutationResult = Apollo.MutationResult<CreateOneModelMutation>;
export type CreateOneModelMutationOptions = Apollo.BaseMutationOptions<CreateOneModelMutation, CreateOneModelMutationVariables>;
export const UpdateOneModelDocument = gql`
    mutation updateOneModel($input: UpdateOneModelInput!) {
  updateOneModel(input: $input) {
    id
  }
}
    `;
export type UpdateOneModelMutationFn = Apollo.MutationFunction<UpdateOneModelMutation, UpdateOneModelMutationVariables>;

/**
 * __useUpdateOneModelMutation__
 *
 * To run a mutation, you first call `useUpdateOneModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneModelMutation, { data, loading, error }] = useUpdateOneModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneModelMutation, UpdateOneModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneModelMutation, UpdateOneModelMutationVariables>(UpdateOneModelDocument, options);
      }
export type UpdateOneModelMutationHookResult = ReturnType<typeof useUpdateOneModelMutation>;
export type UpdateOneModelMutationResult = Apollo.MutationResult<UpdateOneModelMutation>;
export type UpdateOneModelMutationOptions = Apollo.BaseMutationOptions<UpdateOneModelMutation, UpdateOneModelMutationVariables>;
export const DeleteOneModelDocument = gql`
    mutation deleteOneModel($input: DeleteOneModelInput!) {
  deleteOneModel(input: $input) {
    id
  }
}
    `;
export type DeleteOneModelMutationFn = Apollo.MutationFunction<DeleteOneModelMutation, DeleteOneModelMutationVariables>;

/**
 * __useDeleteOneModelMutation__
 *
 * To run a mutation, you first call `useDeleteOneModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneModelMutation, { data, loading, error }] = useDeleteOneModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneModelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneModelMutation, DeleteOneModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneModelMutation, DeleteOneModelMutationVariables>(DeleteOneModelDocument, options);
      }
export type DeleteOneModelMutationHookResult = ReturnType<typeof useDeleteOneModelMutation>;
export type DeleteOneModelMutationResult = Apollo.MutationResult<DeleteOneModelMutation>;
export type DeleteOneModelMutationOptions = Apollo.BaseMutationOptions<DeleteOneModelMutation, DeleteOneModelMutationVariables>;
export const MoldsDocument = gql`
    query molds($filter: MoldFilter, $paging: OffsetPaging, $sorting: [MoldSort!]) {
  molds(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useMoldsQuery__
 *
 * To run a query within a React component, call `useMoldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoldsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMoldsQuery(baseOptions?: Apollo.QueryHookOptions<MoldsQuery, MoldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MoldsQuery, MoldsQueryVariables>(MoldsDocument, options);
      }
export function useMoldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MoldsQuery, MoldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MoldsQuery, MoldsQueryVariables>(MoldsDocument, options);
        }
export type MoldsQueryHookResult = ReturnType<typeof useMoldsQuery>;
export type MoldsLazyQueryHookResult = ReturnType<typeof useMoldsLazyQuery>;
export type MoldsQueryResult = Apollo.QueryResult<MoldsQuery, MoldsQueryVariables>;
export const CreateOneMoldDocument = gql`
    mutation createOneMold($input: CreateOneMoldInput!) {
  createOneMold(input: $input) {
    id
  }
}
    `;
export type CreateOneMoldMutationFn = Apollo.MutationFunction<CreateOneMoldMutation, CreateOneMoldMutationVariables>;

/**
 * __useCreateOneMoldMutation__
 *
 * To run a mutation, you first call `useCreateOneMoldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneMoldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneMoldMutation, { data, loading, error }] = useCreateOneMoldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneMoldMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneMoldMutation, CreateOneMoldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneMoldMutation, CreateOneMoldMutationVariables>(CreateOneMoldDocument, options);
      }
export type CreateOneMoldMutationHookResult = ReturnType<typeof useCreateOneMoldMutation>;
export type CreateOneMoldMutationResult = Apollo.MutationResult<CreateOneMoldMutation>;
export type CreateOneMoldMutationOptions = Apollo.BaseMutationOptions<CreateOneMoldMutation, CreateOneMoldMutationVariables>;
export const UpdateOneMoldDocument = gql`
    mutation updateOneMold($input: UpdateOneMoldInput!) {
  updateOneMold(input: $input) {
    id
  }
}
    `;
export type UpdateOneMoldMutationFn = Apollo.MutationFunction<UpdateOneMoldMutation, UpdateOneMoldMutationVariables>;

/**
 * __useUpdateOneMoldMutation__
 *
 * To run a mutation, you first call `useUpdateOneMoldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneMoldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneMoldMutation, { data, loading, error }] = useUpdateOneMoldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneMoldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneMoldMutation, UpdateOneMoldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneMoldMutation, UpdateOneMoldMutationVariables>(UpdateOneMoldDocument, options);
      }
export type UpdateOneMoldMutationHookResult = ReturnType<typeof useUpdateOneMoldMutation>;
export type UpdateOneMoldMutationResult = Apollo.MutationResult<UpdateOneMoldMutation>;
export type UpdateOneMoldMutationOptions = Apollo.BaseMutationOptions<UpdateOneMoldMutation, UpdateOneMoldMutationVariables>;
export const DeleteOneMoldDocument = gql`
    mutation deleteOneMold($input: DeleteOneMoldInput!) {
  deleteOneMold(input: $input) {
    id
  }
}
    `;
export type DeleteOneMoldMutationFn = Apollo.MutationFunction<DeleteOneMoldMutation, DeleteOneMoldMutationVariables>;

/**
 * __useDeleteOneMoldMutation__
 *
 * To run a mutation, you first call `useDeleteOneMoldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneMoldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneMoldMutation, { data, loading, error }] = useDeleteOneMoldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneMoldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneMoldMutation, DeleteOneMoldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneMoldMutation, DeleteOneMoldMutationVariables>(DeleteOneMoldDocument, options);
      }
export type DeleteOneMoldMutationHookResult = ReturnType<typeof useDeleteOneMoldMutation>;
export type DeleteOneMoldMutationResult = Apollo.MutationResult<DeleteOneMoldMutation>;
export type DeleteOneMoldMutationOptions = Apollo.BaseMutationOptions<DeleteOneMoldMutation, DeleteOneMoldMutationVariables>;
export const NotificationsDocument = gql`
    query notifications($filter: NotificationFilter, $paging: OffsetPaging, $sorting: [NotificationSort!]) {
  notifications(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      content
      isOpen
      isRead
      isCleared
      createdAt
      link
      user {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const UpdateOneNotificationDocument = gql`
    mutation updateOneNotification($input: UpdateOneNotificationInput!) {
  updateOneNotification(input: $input) {
    id
  }
}
    `;
export type UpdateOneNotificationMutationFn = Apollo.MutationFunction<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>;

/**
 * __useUpdateOneNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateOneNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneNotificationMutation, { data, loading, error }] = useUpdateOneNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>(UpdateOneNotificationDocument, options);
      }
export type UpdateOneNotificationMutationHookResult = ReturnType<typeof useUpdateOneNotificationMutation>;
export type UpdateOneNotificationMutationResult = Apollo.MutationResult<UpdateOneNotificationMutation>;
export type UpdateOneNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateOneNotificationMutation, UpdateOneNotificationMutationVariables>;
export const UpdateManyNotificationsDocument = gql`
    mutation updateManyNotifications($input: UpdateManyNotificationsInput!) {
  updateManyNotifications(input: $input) {
    updatedCount
  }
}
    `;
export type UpdateManyNotificationsMutationFn = Apollo.MutationFunction<UpdateManyNotificationsMutation, UpdateManyNotificationsMutationVariables>;

/**
 * __useUpdateManyNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateManyNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyNotificationsMutation, { data, loading, error }] = useUpdateManyNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyNotificationsMutation, UpdateManyNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyNotificationsMutation, UpdateManyNotificationsMutationVariables>(UpdateManyNotificationsDocument, options);
      }
export type UpdateManyNotificationsMutationHookResult = ReturnType<typeof useUpdateManyNotificationsMutation>;
export type UpdateManyNotificationsMutationResult = Apollo.MutationResult<UpdateManyNotificationsMutation>;
export type UpdateManyNotificationsMutationOptions = Apollo.BaseMutationOptions<UpdateManyNotificationsMutation, UpdateManyNotificationsMutationVariables>;
export const OrdersDocument = gql`
    query orders($filter: OrderFilter, $paging: OffsetPaging, $sorting: [OrderSort!]) {
  orders(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      orderId
      client {
        id
        name
      }
      clientReference
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderDocument = gql`
    query order($id: ID!) {
  order(id: $id) {
    id
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const CreateOneOrderDocument = gql`
    mutation createOneOrder($input: CreateOneOrderInput!) {
  createOneOrder(input: $input) {
    id
  }
}
    `;
export type CreateOneOrderMutationFn = Apollo.MutationFunction<CreateOneOrderMutation, CreateOneOrderMutationVariables>;

/**
 * __useCreateOneOrderMutation__
 *
 * To run a mutation, you first call `useCreateOneOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneOrderMutation, { data, loading, error }] = useCreateOneOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneOrderMutation, CreateOneOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneOrderMutation, CreateOneOrderMutationVariables>(CreateOneOrderDocument, options);
      }
export type CreateOneOrderMutationHookResult = ReturnType<typeof useCreateOneOrderMutation>;
export type CreateOneOrderMutationResult = Apollo.MutationResult<CreateOneOrderMutation>;
export type CreateOneOrderMutationOptions = Apollo.BaseMutationOptions<CreateOneOrderMutation, CreateOneOrderMutationVariables>;
export const UpdateOneOrderDocument = gql`
    mutation updateOneOrder($input: UpdateOneOrderInput!) {
  updateOneOrder(input: $input) {
    id
  }
}
    `;
export type UpdateOneOrderMutationFn = Apollo.MutationFunction<UpdateOneOrderMutation, UpdateOneOrderMutationVariables>;

/**
 * __useUpdateOneOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOrderMutation, { data, loading, error }] = useUpdateOneOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneOrderMutation, UpdateOneOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneOrderMutation, UpdateOneOrderMutationVariables>(UpdateOneOrderDocument, options);
      }
export type UpdateOneOrderMutationHookResult = ReturnType<typeof useUpdateOneOrderMutation>;
export type UpdateOneOrderMutationResult = Apollo.MutationResult<UpdateOneOrderMutation>;
export type UpdateOneOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOneOrderMutation, UpdateOneOrderMutationVariables>;
export const DeleteOneOrderDocument = gql`
    mutation deleteOneOrder($input: DeleteOneOrderInput!) {
  deleteOneOrder(input: $input) {
    id
  }
}
    `;
export type DeleteOneOrderMutationFn = Apollo.MutationFunction<DeleteOneOrderMutation, DeleteOneOrderMutationVariables>;

/**
 * __useDeleteOneOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOneOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneOrderMutation, { data, loading, error }] = useDeleteOneOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneOrderMutation, DeleteOneOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneOrderMutation, DeleteOneOrderMutationVariables>(DeleteOneOrderDocument, options);
      }
export type DeleteOneOrderMutationHookResult = ReturnType<typeof useDeleteOneOrderMutation>;
export type DeleteOneOrderMutationResult = Apollo.MutationResult<DeleteOneOrderMutation>;
export type DeleteOneOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOneOrderMutation, DeleteOneOrderMutationVariables>;
export const DeleteManyOrdersDocument = gql`
    mutation deleteManyOrders($input: DeleteManyOrdersInput!) {
  deleteManyOrders(input: $input) {
    deletedCount
  }
}
    `;
export type DeleteManyOrdersMutationFn = Apollo.MutationFunction<DeleteManyOrdersMutation, DeleteManyOrdersMutationVariables>;

/**
 * __useDeleteManyOrdersMutation__
 *
 * To run a mutation, you first call `useDeleteManyOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyOrdersMutation, { data, loading, error }] = useDeleteManyOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyOrdersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyOrdersMutation, DeleteManyOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyOrdersMutation, DeleteManyOrdersMutationVariables>(DeleteManyOrdersDocument, options);
      }
export type DeleteManyOrdersMutationHookResult = ReturnType<typeof useDeleteManyOrdersMutation>;
export type DeleteManyOrdersMutationResult = Apollo.MutationResult<DeleteManyOrdersMutation>;
export type DeleteManyOrdersMutationOptions = Apollo.BaseMutationOptions<DeleteManyOrdersMutation, DeleteManyOrdersMutationVariables>;
export const PlanningRowStatesDocument = gql`
    query planningRowStates($filter: PlanningRowStateFilter, $paging: OffsetPaging, $sorting: [PlanningRowStateSort!]) {
  planningRowStates(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __usePlanningRowStatesQuery__
 *
 * To run a query within a React component, call `usePlanningRowStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningRowStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningRowStatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function usePlanningRowStatesQuery(baseOptions?: Apollo.QueryHookOptions<PlanningRowStatesQuery, PlanningRowStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningRowStatesQuery, PlanningRowStatesQueryVariables>(PlanningRowStatesDocument, options);
      }
export function usePlanningRowStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningRowStatesQuery, PlanningRowStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningRowStatesQuery, PlanningRowStatesQueryVariables>(PlanningRowStatesDocument, options);
        }
export type PlanningRowStatesQueryHookResult = ReturnType<typeof usePlanningRowStatesQuery>;
export type PlanningRowStatesLazyQueryHookResult = ReturnType<typeof usePlanningRowStatesLazyQuery>;
export type PlanningRowStatesQueryResult = Apollo.QueryResult<PlanningRowStatesQuery, PlanningRowStatesQueryVariables>;
export const PlanningRowsDocument = gql`
    query planningRows($filter: PlanningRowFilter, $paging: OffsetPaging, $sorting: [PlanningRowSort!]) {
  planningRows(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      observations
      priority
      planningRowState {
        id
        name
        color
      }
      workOrder {
        id
        orderDate
        quantity
        order {
          client {
            id
            name
          }
        }
        leather {
          id
          name
        }
        model {
          id
          name
        }
        mold {
          id
          name
        }
        sole {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePlanningRowsQuery__
 *
 * To run a query within a React component, call `usePlanningRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningRowsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function usePlanningRowsQuery(baseOptions?: Apollo.QueryHookOptions<PlanningRowsQuery, PlanningRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningRowsQuery, PlanningRowsQueryVariables>(PlanningRowsDocument, options);
      }
export function usePlanningRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningRowsQuery, PlanningRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningRowsQuery, PlanningRowsQueryVariables>(PlanningRowsDocument, options);
        }
export type PlanningRowsQueryHookResult = ReturnType<typeof usePlanningRowsQuery>;
export type PlanningRowsLazyQueryHookResult = ReturnType<typeof usePlanningRowsLazyQuery>;
export type PlanningRowsQueryResult = Apollo.QueryResult<PlanningRowsQuery, PlanningRowsQueryVariables>;
export const CreateOnePlanningRowDocument = gql`
    mutation createOnePlanningRow($input: CreateOnePlanningRowInput!) {
  createOnePlanningRow(input: $input) {
    id
  }
}
    `;
export type CreateOnePlanningRowMutationFn = Apollo.MutationFunction<CreateOnePlanningRowMutation, CreateOnePlanningRowMutationVariables>;

/**
 * __useCreateOnePlanningRowMutation__
 *
 * To run a mutation, you first call `useCreateOnePlanningRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnePlanningRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnePlanningRowMutation, { data, loading, error }] = useCreateOnePlanningRowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnePlanningRowMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnePlanningRowMutation, CreateOnePlanningRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnePlanningRowMutation, CreateOnePlanningRowMutationVariables>(CreateOnePlanningRowDocument, options);
      }
export type CreateOnePlanningRowMutationHookResult = ReturnType<typeof useCreateOnePlanningRowMutation>;
export type CreateOnePlanningRowMutationResult = Apollo.MutationResult<CreateOnePlanningRowMutation>;
export type CreateOnePlanningRowMutationOptions = Apollo.BaseMutationOptions<CreateOnePlanningRowMutation, CreateOnePlanningRowMutationVariables>;
export const CreateManyPlanningRowsDocument = gql`
    mutation createManyPlanningRows($input: CreateManyPlanningRowsInput!) {
  createManyPlanningRows(input: $input) {
    id
  }
}
    `;
export type CreateManyPlanningRowsMutationFn = Apollo.MutationFunction<CreateManyPlanningRowsMutation, CreateManyPlanningRowsMutationVariables>;

/**
 * __useCreateManyPlanningRowsMutation__
 *
 * To run a mutation, you first call `useCreateManyPlanningRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyPlanningRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyPlanningRowsMutation, { data, loading, error }] = useCreateManyPlanningRowsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManyPlanningRowsMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyPlanningRowsMutation, CreateManyPlanningRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyPlanningRowsMutation, CreateManyPlanningRowsMutationVariables>(CreateManyPlanningRowsDocument, options);
      }
export type CreateManyPlanningRowsMutationHookResult = ReturnType<typeof useCreateManyPlanningRowsMutation>;
export type CreateManyPlanningRowsMutationResult = Apollo.MutationResult<CreateManyPlanningRowsMutation>;
export type CreateManyPlanningRowsMutationOptions = Apollo.BaseMutationOptions<CreateManyPlanningRowsMutation, CreateManyPlanningRowsMutationVariables>;
export const UpdateOnePlanningRowDocument = gql`
    mutation updateOnePlanningRow($input: UpdateOnePlanningRowInput!) {
  updateOnePlanningRow(input: $input) {
    id
  }
}
    `;
export type UpdateOnePlanningRowMutationFn = Apollo.MutationFunction<UpdateOnePlanningRowMutation, UpdateOnePlanningRowMutationVariables>;

/**
 * __useUpdateOnePlanningRowMutation__
 *
 * To run a mutation, you first call `useUpdateOnePlanningRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnePlanningRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnePlanningRowMutation, { data, loading, error }] = useUpdateOnePlanningRowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnePlanningRowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnePlanningRowMutation, UpdateOnePlanningRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnePlanningRowMutation, UpdateOnePlanningRowMutationVariables>(UpdateOnePlanningRowDocument, options);
      }
export type UpdateOnePlanningRowMutationHookResult = ReturnType<typeof useUpdateOnePlanningRowMutation>;
export type UpdateOnePlanningRowMutationResult = Apollo.MutationResult<UpdateOnePlanningRowMutation>;
export type UpdateOnePlanningRowMutationOptions = Apollo.BaseMutationOptions<UpdateOnePlanningRowMutation, UpdateOnePlanningRowMutationVariables>;
export const UpdateManyPlanningRowsDocument = gql`
    mutation updateManyPlanningRows($input: UpdateManyPlanningRowsInput!) {
  updateManyPlanningRows(input: $input) {
    updatedCount
  }
}
    `;
export type UpdateManyPlanningRowsMutationFn = Apollo.MutationFunction<UpdateManyPlanningRowsMutation, UpdateManyPlanningRowsMutationVariables>;

/**
 * __useUpdateManyPlanningRowsMutation__
 *
 * To run a mutation, you first call `useUpdateManyPlanningRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyPlanningRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyPlanningRowsMutation, { data, loading, error }] = useUpdateManyPlanningRowsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyPlanningRowsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyPlanningRowsMutation, UpdateManyPlanningRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyPlanningRowsMutation, UpdateManyPlanningRowsMutationVariables>(UpdateManyPlanningRowsDocument, options);
      }
export type UpdateManyPlanningRowsMutationHookResult = ReturnType<typeof useUpdateManyPlanningRowsMutation>;
export type UpdateManyPlanningRowsMutationResult = Apollo.MutationResult<UpdateManyPlanningRowsMutation>;
export type UpdateManyPlanningRowsMutationOptions = Apollo.BaseMutationOptions<UpdateManyPlanningRowsMutation, UpdateManyPlanningRowsMutationVariables>;
export const DeleteOnePlanningRowDocument = gql`
    mutation deleteOnePlanningRow($input: DeleteOnePlanningRowInput!) {
  deleteOnePlanningRow(input: $input) {
    id
  }
}
    `;
export type DeleteOnePlanningRowMutationFn = Apollo.MutationFunction<DeleteOnePlanningRowMutation, DeleteOnePlanningRowMutationVariables>;

/**
 * __useDeleteOnePlanningRowMutation__
 *
 * To run a mutation, you first call `useDeleteOnePlanningRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnePlanningRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnePlanningRowMutation, { data, loading, error }] = useDeleteOnePlanningRowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOnePlanningRowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnePlanningRowMutation, DeleteOnePlanningRowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnePlanningRowMutation, DeleteOnePlanningRowMutationVariables>(DeleteOnePlanningRowDocument, options);
      }
export type DeleteOnePlanningRowMutationHookResult = ReturnType<typeof useDeleteOnePlanningRowMutation>;
export type DeleteOnePlanningRowMutationResult = Apollo.MutationResult<DeleteOnePlanningRowMutation>;
export type DeleteOnePlanningRowMutationOptions = Apollo.BaseMutationOptions<DeleteOnePlanningRowMutation, DeleteOnePlanningRowMutationVariables>;
export const DeleteManyPlanningRowsDocument = gql`
    mutation deleteManyPlanningRows($input: DeleteManyPlanningRowsInput!) {
  deleteManyPlanningRows(input: $input) {
    deletedCount
  }
}
    `;
export type DeleteManyPlanningRowsMutationFn = Apollo.MutationFunction<DeleteManyPlanningRowsMutation, DeleteManyPlanningRowsMutationVariables>;

/**
 * __useDeleteManyPlanningRowsMutation__
 *
 * To run a mutation, you first call `useDeleteManyPlanningRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyPlanningRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyPlanningRowsMutation, { data, loading, error }] = useDeleteManyPlanningRowsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyPlanningRowsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyPlanningRowsMutation, DeleteManyPlanningRowsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyPlanningRowsMutation, DeleteManyPlanningRowsMutationVariables>(DeleteManyPlanningRowsDocument, options);
      }
export type DeleteManyPlanningRowsMutationHookResult = ReturnType<typeof useDeleteManyPlanningRowsMutation>;
export type DeleteManyPlanningRowsMutationResult = Apollo.MutationResult<DeleteManyPlanningRowsMutation>;
export type DeleteManyPlanningRowsMutationOptions = Apollo.BaseMutationOptions<DeleteManyPlanningRowsMutation, DeleteManyPlanningRowsMutationVariables>;
export const PlanningStatesDocument = gql`
    query planningStates($filter: PlanningStateFilter, $paging: OffsetPaging, $sorting: [PlanningStateSort!]) {
  planningStates(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __usePlanningStatesQuery__
 *
 * To run a query within a React component, call `usePlanningStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningStatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function usePlanningStatesQuery(baseOptions?: Apollo.QueryHookOptions<PlanningStatesQuery, PlanningStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningStatesQuery, PlanningStatesQueryVariables>(PlanningStatesDocument, options);
      }
export function usePlanningStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningStatesQuery, PlanningStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningStatesQuery, PlanningStatesQueryVariables>(PlanningStatesDocument, options);
        }
export type PlanningStatesQueryHookResult = ReturnType<typeof usePlanningStatesQuery>;
export type PlanningStatesLazyQueryHookResult = ReturnType<typeof usePlanningStatesLazyQuery>;
export type PlanningStatesQueryResult = Apollo.QueryResult<PlanningStatesQuery, PlanningStatesQueryVariables>;
export const PlanningsDocument = gql`
    query plannings($filter: PlanningFilter, $paging: OffsetPaging, $sorting: [PlanningSort!]) {
  plannings(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      week
      year
      planningState {
        id
        name
        color
      }
      sector {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePlanningsQuery__
 *
 * To run a query within a React component, call `usePlanningsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function usePlanningsQuery(baseOptions?: Apollo.QueryHookOptions<PlanningsQuery, PlanningsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningsQuery, PlanningsQueryVariables>(PlanningsDocument, options);
      }
export function usePlanningsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningsQuery, PlanningsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningsQuery, PlanningsQueryVariables>(PlanningsDocument, options);
        }
export type PlanningsQueryHookResult = ReturnType<typeof usePlanningsQuery>;
export type PlanningsLazyQueryHookResult = ReturnType<typeof usePlanningsLazyQuery>;
export type PlanningsQueryResult = Apollo.QueryResult<PlanningsQuery, PlanningsQueryVariables>;
export const CreateOnePlanningDocument = gql`
    mutation createOnePlanning($input: CreateOnePlanningInput!) {
  createOnePlanning(input: $input) {
    id
  }
}
    `;
export type CreateOnePlanningMutationFn = Apollo.MutationFunction<CreateOnePlanningMutation, CreateOnePlanningMutationVariables>;

/**
 * __useCreateOnePlanningMutation__
 *
 * To run a mutation, you first call `useCreateOnePlanningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnePlanningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnePlanningMutation, { data, loading, error }] = useCreateOnePlanningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnePlanningMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnePlanningMutation, CreateOnePlanningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnePlanningMutation, CreateOnePlanningMutationVariables>(CreateOnePlanningDocument, options);
      }
export type CreateOnePlanningMutationHookResult = ReturnType<typeof useCreateOnePlanningMutation>;
export type CreateOnePlanningMutationResult = Apollo.MutationResult<CreateOnePlanningMutation>;
export type CreateOnePlanningMutationOptions = Apollo.BaseMutationOptions<CreateOnePlanningMutation, CreateOnePlanningMutationVariables>;
export const UpdateOnePlanningDocument = gql`
    mutation updateOnePlanning($input: UpdateOnePlanningInput!) {
  updateOnePlanning(input: $input) {
    id
  }
}
    `;
export type UpdateOnePlanningMutationFn = Apollo.MutationFunction<UpdateOnePlanningMutation, UpdateOnePlanningMutationVariables>;

/**
 * __useUpdateOnePlanningMutation__
 *
 * To run a mutation, you first call `useUpdateOnePlanningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOnePlanningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOnePlanningMutation, { data, loading, error }] = useUpdateOnePlanningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOnePlanningMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnePlanningMutation, UpdateOnePlanningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnePlanningMutation, UpdateOnePlanningMutationVariables>(UpdateOnePlanningDocument, options);
      }
export type UpdateOnePlanningMutationHookResult = ReturnType<typeof useUpdateOnePlanningMutation>;
export type UpdateOnePlanningMutationResult = Apollo.MutationResult<UpdateOnePlanningMutation>;
export type UpdateOnePlanningMutationOptions = Apollo.BaseMutationOptions<UpdateOnePlanningMutation, UpdateOnePlanningMutationVariables>;
export const UpdateManyPlanningsDocument = gql`
    mutation updateManyPlannings($input: UpdateManyPlanningsInput!) {
  updateManyPlannings(input: $input) {
    updatedCount
  }
}
    `;
export type UpdateManyPlanningsMutationFn = Apollo.MutationFunction<UpdateManyPlanningsMutation, UpdateManyPlanningsMutationVariables>;

/**
 * __useUpdateManyPlanningsMutation__
 *
 * To run a mutation, you first call `useUpdateManyPlanningsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyPlanningsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyPlanningsMutation, { data, loading, error }] = useUpdateManyPlanningsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyPlanningsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyPlanningsMutation, UpdateManyPlanningsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyPlanningsMutation, UpdateManyPlanningsMutationVariables>(UpdateManyPlanningsDocument, options);
      }
export type UpdateManyPlanningsMutationHookResult = ReturnType<typeof useUpdateManyPlanningsMutation>;
export type UpdateManyPlanningsMutationResult = Apollo.MutationResult<UpdateManyPlanningsMutation>;
export type UpdateManyPlanningsMutationOptions = Apollo.BaseMutationOptions<UpdateManyPlanningsMutation, UpdateManyPlanningsMutationVariables>;
export const DeleteOnePlanningDocument = gql`
    mutation deleteOnePlanning($input: DeleteOnePlanningInput!) {
  deleteOnePlanning(input: $input) {
    id
  }
}
    `;
export type DeleteOnePlanningMutationFn = Apollo.MutationFunction<DeleteOnePlanningMutation, DeleteOnePlanningMutationVariables>;

/**
 * __useDeleteOnePlanningMutation__
 *
 * To run a mutation, you first call `useDeleteOnePlanningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOnePlanningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOnePlanningMutation, { data, loading, error }] = useDeleteOnePlanningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOnePlanningMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOnePlanningMutation, DeleteOnePlanningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOnePlanningMutation, DeleteOnePlanningMutationVariables>(DeleteOnePlanningDocument, options);
      }
export type DeleteOnePlanningMutationHookResult = ReturnType<typeof useDeleteOnePlanningMutation>;
export type DeleteOnePlanningMutationResult = Apollo.MutationResult<DeleteOnePlanningMutation>;
export type DeleteOnePlanningMutationOptions = Apollo.BaseMutationOptions<DeleteOnePlanningMutation, DeleteOnePlanningMutationVariables>;
export const DeleteManyPlanningsDocument = gql`
    mutation deleteManyPlannings($input: DeleteManyPlanningsInput!) {
  deleteManyPlannings(input: $input) {
    deletedCount
  }
}
    `;
export type DeleteManyPlanningsMutationFn = Apollo.MutationFunction<DeleteManyPlanningsMutation, DeleteManyPlanningsMutationVariables>;

/**
 * __useDeleteManyPlanningsMutation__
 *
 * To run a mutation, you first call `useDeleteManyPlanningsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyPlanningsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyPlanningsMutation, { data, loading, error }] = useDeleteManyPlanningsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyPlanningsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyPlanningsMutation, DeleteManyPlanningsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyPlanningsMutation, DeleteManyPlanningsMutationVariables>(DeleteManyPlanningsDocument, options);
      }
export type DeleteManyPlanningsMutationHookResult = ReturnType<typeof useDeleteManyPlanningsMutation>;
export type DeleteManyPlanningsMutationResult = Apollo.MutationResult<DeleteManyPlanningsMutation>;
export type DeleteManyPlanningsMutationOptions = Apollo.BaseMutationOptions<DeleteManyPlanningsMutation, DeleteManyPlanningsMutationVariables>;
export const PlanningDocument = gql`
    query planning($id: ID!) {
  planning(id: $id) {
    id
    week
    year
    planningState {
      id
      name
    }
    sector {
      id
      name
    }
    planningRows {
      id
      workOrder {
        id
        quantity
        sole {
          id
          name
        }
        model {
          id
          name
        }
        leather {
          id
          name
        }
        mold {
          id
          name
        }
        order {
          client {
            id
            name
          }
        }
        orderDate
      }
    }
  }
}
    `;

/**
 * __usePlanningQuery__
 *
 * To run a query within a React component, call `usePlanningQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlanningQuery(baseOptions: Apollo.QueryHookOptions<PlanningQuery, PlanningQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningQuery, PlanningQueryVariables>(PlanningDocument, options);
      }
export function usePlanningLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningQuery, PlanningQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningQuery, PlanningQueryVariables>(PlanningDocument, options);
        }
export type PlanningQueryHookResult = ReturnType<typeof usePlanningQuery>;
export type PlanningLazyQueryHookResult = ReturnType<typeof usePlanningLazyQuery>;
export type PlanningQueryResult = Apollo.QueryResult<PlanningQuery, PlanningQueryVariables>;
export const DuplicatePlanningDocument = gql`
    mutation duplicatePlanning($input: DuplicatePlanningInput!) {
  duplicatePlanning(input: $input) {
    id
  }
}
    `;
export type DuplicatePlanningMutationFn = Apollo.MutationFunction<DuplicatePlanningMutation, DuplicatePlanningMutationVariables>;

/**
 * __useDuplicatePlanningMutation__
 *
 * To run a mutation, you first call `useDuplicatePlanningMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicatePlanningMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicatePlanningMutation, { data, loading, error }] = useDuplicatePlanningMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicatePlanningMutation(baseOptions?: Apollo.MutationHookOptions<DuplicatePlanningMutation, DuplicatePlanningMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicatePlanningMutation, DuplicatePlanningMutationVariables>(DuplicatePlanningDocument, options);
      }
export type DuplicatePlanningMutationHookResult = ReturnType<typeof useDuplicatePlanningMutation>;
export type DuplicatePlanningMutationResult = Apollo.MutationResult<DuplicatePlanningMutation>;
export type DuplicatePlanningMutationOptions = Apollo.BaseMutationOptions<DuplicatePlanningMutation, DuplicatePlanningMutationVariables>;
export const StatusDocument = gql`
    query status {
  status
}
    `;

/**
 * __useStatusQuery__
 *
 * To run a query within a React component, call `useStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatusQuery(baseOptions?: Apollo.QueryHookOptions<StatusQuery, StatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatusQuery, StatusQueryVariables>(StatusDocument, options);
      }
export function useStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatusQuery, StatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatusQuery, StatusQueryVariables>(StatusDocument, options);
        }
export type StatusQueryHookResult = ReturnType<typeof useStatusQuery>;
export type StatusLazyQueryHookResult = ReturnType<typeof useStatusLazyQuery>;
export type StatusQueryResult = Apollo.QueryResult<StatusQuery, StatusQueryVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation sendResetPasswordEmail($email: String!) {
  sendResetPasswordEmail(email: $email)
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const SetPasswordDocument = gql`
    mutation setPassword($input: SetPasswordInput!) {
  setPassword(input: $input)
}
    `;
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>;

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options);
      }
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>;
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>;
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>;
export const PlanningStateFieldDocument = gql`
    query planningStateField($filter: PlanningStateFilter, $paging: OffsetPaging, $sorting: [PlanningStateSort!]) {
  planningStates(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __usePlanningStateFieldQuery__
 *
 * To run a query within a React component, call `usePlanningStateFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanningStateFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanningStateFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function usePlanningStateFieldQuery(baseOptions?: Apollo.QueryHookOptions<PlanningStateFieldQuery, PlanningStateFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanningStateFieldQuery, PlanningStateFieldQueryVariables>(PlanningStateFieldDocument, options);
      }
export function usePlanningStateFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanningStateFieldQuery, PlanningStateFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanningStateFieldQuery, PlanningStateFieldQueryVariables>(PlanningStateFieldDocument, options);
        }
export type PlanningStateFieldQueryHookResult = ReturnType<typeof usePlanningStateFieldQuery>;
export type PlanningStateFieldLazyQueryHookResult = ReturnType<typeof usePlanningStateFieldLazyQuery>;
export type PlanningStateFieldQueryResult = Apollo.QueryResult<PlanningStateFieldQuery, PlanningStateFieldQueryVariables>;
export const SectorFieldDocument = gql`
    query sectorField($filter: SectorFilter, $paging: OffsetPaging, $sorting: [SectorSort!]) {
  sectors(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useSectorFieldQuery__
 *
 * To run a query within a React component, call `useSectorFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectorFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectorFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSectorFieldQuery(baseOptions?: Apollo.QueryHookOptions<SectorFieldQuery, SectorFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SectorFieldQuery, SectorFieldQueryVariables>(SectorFieldDocument, options);
      }
export function useSectorFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectorFieldQuery, SectorFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SectorFieldQuery, SectorFieldQueryVariables>(SectorFieldDocument, options);
        }
export type SectorFieldQueryHookResult = ReturnType<typeof useSectorFieldQuery>;
export type SectorFieldLazyQueryHookResult = ReturnType<typeof useSectorFieldLazyQuery>;
export type SectorFieldQueryResult = Apollo.QueryResult<SectorFieldQuery, SectorFieldQueryVariables>;
export const SectorsDocument = gql`
    query sectors($filter: SectorFilter, $paging: OffsetPaging, $sorting: [SectorSort!]) {
  sectors(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useSectorsQuery__
 *
 * To run a query within a React component, call `useSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectorsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSectorsQuery(baseOptions?: Apollo.QueryHookOptions<SectorsQuery, SectorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SectorsQuery, SectorsQueryVariables>(SectorsDocument, options);
      }
export function useSectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SectorsQuery, SectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SectorsQuery, SectorsQueryVariables>(SectorsDocument, options);
        }
export type SectorsQueryHookResult = ReturnType<typeof useSectorsQuery>;
export type SectorsLazyQueryHookResult = ReturnType<typeof useSectorsLazyQuery>;
export type SectorsQueryResult = Apollo.QueryResult<SectorsQuery, SectorsQueryVariables>;
export const CreateOneSectorDocument = gql`
    mutation createOneSector($input: CreateOneSectorInput!) {
  createOneSector(input: $input) {
    id
  }
}
    `;
export type CreateOneSectorMutationFn = Apollo.MutationFunction<CreateOneSectorMutation, CreateOneSectorMutationVariables>;

/**
 * __useCreateOneSectorMutation__
 *
 * To run a mutation, you first call `useCreateOneSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSectorMutation, { data, loading, error }] = useCreateOneSectorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSectorMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneSectorMutation, CreateOneSectorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneSectorMutation, CreateOneSectorMutationVariables>(CreateOneSectorDocument, options);
      }
export type CreateOneSectorMutationHookResult = ReturnType<typeof useCreateOneSectorMutation>;
export type CreateOneSectorMutationResult = Apollo.MutationResult<CreateOneSectorMutation>;
export type CreateOneSectorMutationOptions = Apollo.BaseMutationOptions<CreateOneSectorMutation, CreateOneSectorMutationVariables>;
export const UpdateOneSectorDocument = gql`
    mutation updateOneSector($input: UpdateOneSectorInput!) {
  updateOneSector(input: $input) {
    id
  }
}
    `;
export type UpdateOneSectorMutationFn = Apollo.MutationFunction<UpdateOneSectorMutation, UpdateOneSectorMutationVariables>;

/**
 * __useUpdateOneSectorMutation__
 *
 * To run a mutation, you first call `useUpdateOneSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSectorMutation, { data, loading, error }] = useUpdateOneSectorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSectorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSectorMutation, UpdateOneSectorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSectorMutation, UpdateOneSectorMutationVariables>(UpdateOneSectorDocument, options);
      }
export type UpdateOneSectorMutationHookResult = ReturnType<typeof useUpdateOneSectorMutation>;
export type UpdateOneSectorMutationResult = Apollo.MutationResult<UpdateOneSectorMutation>;
export type UpdateOneSectorMutationOptions = Apollo.BaseMutationOptions<UpdateOneSectorMutation, UpdateOneSectorMutationVariables>;
export const DeleteOneSectorDocument = gql`
    mutation deleteOneSector($input: DeleteOneSectorInput!) {
  deleteOneSector(input: $input) {
    id
  }
}
    `;
export type DeleteOneSectorMutationFn = Apollo.MutationFunction<DeleteOneSectorMutation, DeleteOneSectorMutationVariables>;

/**
 * __useDeleteOneSectorMutation__
 *
 * To run a mutation, you first call `useDeleteOneSectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSectorMutation, { data, loading, error }] = useDeleteOneSectorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSectorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneSectorMutation, DeleteOneSectorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneSectorMutation, DeleteOneSectorMutationVariables>(DeleteOneSectorDocument, options);
      }
export type DeleteOneSectorMutationHookResult = ReturnType<typeof useDeleteOneSectorMutation>;
export type DeleteOneSectorMutationResult = Apollo.MutationResult<DeleteOneSectorMutation>;
export type DeleteOneSectorMutationOptions = Apollo.BaseMutationOptions<DeleteOneSectorMutation, DeleteOneSectorMutationVariables>;
export const SolesDocument = gql`
    query soles($filter: SoleFilter, $paging: OffsetPaging, $sorting: [SoleSort!]) {
  soles(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useSolesQuery__
 *
 * To run a query within a React component, call `useSolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSolesQuery(baseOptions?: Apollo.QueryHookOptions<SolesQuery, SolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SolesQuery, SolesQueryVariables>(SolesDocument, options);
      }
export function useSolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolesQuery, SolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SolesQuery, SolesQueryVariables>(SolesDocument, options);
        }
export type SolesQueryHookResult = ReturnType<typeof useSolesQuery>;
export type SolesLazyQueryHookResult = ReturnType<typeof useSolesLazyQuery>;
export type SolesQueryResult = Apollo.QueryResult<SolesQuery, SolesQueryVariables>;
export const CreateOneSoleDocument = gql`
    mutation createOneSole($input: CreateOneSoleInput!) {
  createOneSole(input: $input) {
    id
  }
}
    `;
export type CreateOneSoleMutationFn = Apollo.MutationFunction<CreateOneSoleMutation, CreateOneSoleMutationVariables>;

/**
 * __useCreateOneSoleMutation__
 *
 * To run a mutation, you first call `useCreateOneSoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSoleMutation, { data, loading, error }] = useCreateOneSoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneSoleMutation, CreateOneSoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneSoleMutation, CreateOneSoleMutationVariables>(CreateOneSoleDocument, options);
      }
export type CreateOneSoleMutationHookResult = ReturnType<typeof useCreateOneSoleMutation>;
export type CreateOneSoleMutationResult = Apollo.MutationResult<CreateOneSoleMutation>;
export type CreateOneSoleMutationOptions = Apollo.BaseMutationOptions<CreateOneSoleMutation, CreateOneSoleMutationVariables>;
export const UpdateOneSoleDocument = gql`
    mutation updateOneSole($input: UpdateOneSoleInput!) {
  updateOneSole(input: $input) {
    id
  }
}
    `;
export type UpdateOneSoleMutationFn = Apollo.MutationFunction<UpdateOneSoleMutation, UpdateOneSoleMutationVariables>;

/**
 * __useUpdateOneSoleMutation__
 *
 * To run a mutation, you first call `useUpdateOneSoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSoleMutation, { data, loading, error }] = useUpdateOneSoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSoleMutation, UpdateOneSoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSoleMutation, UpdateOneSoleMutationVariables>(UpdateOneSoleDocument, options);
      }
export type UpdateOneSoleMutationHookResult = ReturnType<typeof useUpdateOneSoleMutation>;
export type UpdateOneSoleMutationResult = Apollo.MutationResult<UpdateOneSoleMutation>;
export type UpdateOneSoleMutationOptions = Apollo.BaseMutationOptions<UpdateOneSoleMutation, UpdateOneSoleMutationVariables>;
export const DeleteOneSoleDocument = gql`
    mutation deleteOneSole($input: DeleteOneSoleInput!) {
  deleteOneSole(input: $input) {
    id
  }
}
    `;
export type DeleteOneSoleMutationFn = Apollo.MutationFunction<DeleteOneSoleMutation, DeleteOneSoleMutationVariables>;

/**
 * __useDeleteOneSoleMutation__
 *
 * To run a mutation, you first call `useDeleteOneSoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSoleMutation, { data, loading, error }] = useDeleteOneSoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneSoleMutation, DeleteOneSoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneSoleMutation, DeleteOneSoleMutationVariables>(DeleteOneSoleDocument, options);
      }
export type DeleteOneSoleMutationHookResult = ReturnType<typeof useDeleteOneSoleMutation>;
export type DeleteOneSoleMutationResult = Apollo.MutationResult<DeleteOneSoleMutation>;
export type DeleteOneSoleMutationOptions = Apollo.BaseMutationOptions<DeleteOneSoleMutation, DeleteOneSoleMutationVariables>;
export const SuppliersDocument = gql`
    query suppliers($filter: SupplierFilter, $paging: OffsetPaging, $sorting: [SupplierSort!]) {
  suppliers(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
      }
export function useSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
        }
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const CreateOneSupplierDocument = gql`
    mutation createOneSupplier($input: CreateOneSupplierInput!) {
  createOneSupplier(input: $input) {
    id
  }
}
    `;
export type CreateOneSupplierMutationFn = Apollo.MutationFunction<CreateOneSupplierMutation, CreateOneSupplierMutationVariables>;

/**
 * __useCreateOneSupplierMutation__
 *
 * To run a mutation, you first call `useCreateOneSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneSupplierMutation, { data, loading, error }] = useCreateOneSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneSupplierMutation, CreateOneSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneSupplierMutation, CreateOneSupplierMutationVariables>(CreateOneSupplierDocument, options);
      }
export type CreateOneSupplierMutationHookResult = ReturnType<typeof useCreateOneSupplierMutation>;
export type CreateOneSupplierMutationResult = Apollo.MutationResult<CreateOneSupplierMutation>;
export type CreateOneSupplierMutationOptions = Apollo.BaseMutationOptions<CreateOneSupplierMutation, CreateOneSupplierMutationVariables>;
export const UpdateOneSupplierDocument = gql`
    mutation updateOneSupplier($input: UpdateOneSupplierInput!) {
  updateOneSupplier(input: $input) {
    id
  }
}
    `;
export type UpdateOneSupplierMutationFn = Apollo.MutationFunction<UpdateOneSupplierMutation, UpdateOneSupplierMutationVariables>;

/**
 * __useUpdateOneSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateOneSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneSupplierMutation, { data, loading, error }] = useUpdateOneSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneSupplierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneSupplierMutation, UpdateOneSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneSupplierMutation, UpdateOneSupplierMutationVariables>(UpdateOneSupplierDocument, options);
      }
export type UpdateOneSupplierMutationHookResult = ReturnType<typeof useUpdateOneSupplierMutation>;
export type UpdateOneSupplierMutationResult = Apollo.MutationResult<UpdateOneSupplierMutation>;
export type UpdateOneSupplierMutationOptions = Apollo.BaseMutationOptions<UpdateOneSupplierMutation, UpdateOneSupplierMutationVariables>;
export const DeleteOneSupplierDocument = gql`
    mutation deleteOneSupplier($input: DeleteOneSupplierInput!) {
  deleteOneSupplier(input: $input) {
    id
  }
}
    `;
export type DeleteOneSupplierMutationFn = Apollo.MutationFunction<DeleteOneSupplierMutation, DeleteOneSupplierMutationVariables>;

/**
 * __useDeleteOneSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteOneSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneSupplierMutation, { data, loading, error }] = useDeleteOneSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneSupplierMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneSupplierMutation, DeleteOneSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneSupplierMutation, DeleteOneSupplierMutationVariables>(DeleteOneSupplierDocument, options);
      }
export type DeleteOneSupplierMutationHookResult = ReturnType<typeof useDeleteOneSupplierMutation>;
export type DeleteOneSupplierMutationResult = Apollo.MutationResult<DeleteOneSupplierMutation>;
export type DeleteOneSupplierMutationOptions = Apollo.BaseMutationOptions<DeleteOneSupplierMutation, DeleteOneSupplierMutationVariables>;
export const UsersDocument = gql`
    query users($filter: UserFilter, $paging: OffsetPaging, $sorting: [UserSort!]) {
  users(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      id
      name
      email
      role
      sector {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const CreateOneUserDocument = gql`
    mutation createOneUser($input: CreateOneUserInput!) {
  createOneUser(input: $input) {
    id
  }
}
    `;
export type CreateOneUserMutationFn = Apollo.MutationFunction<CreateOneUserMutation, CreateOneUserMutationVariables>;

/**
 * __useCreateOneUserMutation__
 *
 * To run a mutation, you first call `useCreateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneUserMutation, { data, loading, error }] = useCreateOneUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneUserMutation, CreateOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneUserMutation, CreateOneUserMutationVariables>(CreateOneUserDocument, options);
      }
export type CreateOneUserMutationHookResult = ReturnType<typeof useCreateOneUserMutation>;
export type CreateOneUserMutationResult = Apollo.MutationResult<CreateOneUserMutation>;
export type CreateOneUserMutationOptions = Apollo.BaseMutationOptions<CreateOneUserMutation, CreateOneUserMutationVariables>;
export const UpdateOneUserDocument = gql`
    mutation updateOneUser($input: UpdateOneUserInput!) {
  updateOneUser(input: $input) {
    id
  }
}
    `;
export type UpdateOneUserMutationFn = Apollo.MutationFunction<UpdateOneUserMutation, UpdateOneUserMutationVariables>;

/**
 * __useUpdateOneUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserMutation, { data, loading, error }] = useUpdateOneUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(UpdateOneUserDocument, options);
      }
export type UpdateOneUserMutationHookResult = ReturnType<typeof useUpdateOneUserMutation>;
export type UpdateOneUserMutationResult = Apollo.MutationResult<UpdateOneUserMutation>;
export type UpdateOneUserMutationOptions = Apollo.BaseMutationOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>;
export const DeleteOneUserDocument = gql`
    mutation deleteOneUser($input: DeleteOneUserInput!) {
  deleteOneUser(input: $input) {
    id
  }
}
    `;
export type DeleteOneUserMutationFn = Apollo.MutationFunction<DeleteOneUserMutation, DeleteOneUserMutationVariables>;

/**
 * __useDeleteOneUserMutation__
 *
 * To run a mutation, you first call `useDeleteOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneUserMutation, { data, loading, error }] = useDeleteOneUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneUserMutation, DeleteOneUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneUserMutation, DeleteOneUserMutationVariables>(DeleteOneUserDocument, options);
      }
export type DeleteOneUserMutationHookResult = ReturnType<typeof useDeleteOneUserMutation>;
export type DeleteOneUserMutationResult = Apollo.MutationResult<DeleteOneUserMutation>;
export type DeleteOneUserMutationOptions = Apollo.BaseMutationOptions<DeleteOneUserMutation, DeleteOneUserMutationVariables>;
export const WorkOrdersDocument = gql`
    query workOrders($filter: WorkOrderFilter, $paging: OffsetPaging, $sorting: [WorkOrderSort!]) {
  workOrders(filter: $filter, paging: $paging, sorting: $sorting) {
    totalCount
    nodes {
      id
      workOrderid
      quantity
      orderDate
      cuttingSystem
      clientOrderReference
      clientModelReference
      observations
      isSoleAvailable
      isLeatherAvailable
      sole {
        id
        name
      }
      mold {
        id
        name
      }
      leather {
        id
        name
      }
      model {
        id
        name
      }
      order {
        id
        orderId
        clientReference
        createdAt
        client {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useWorkOrdersQuery__
 *
 * To run a query within a React component, call `useWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWorkOrdersQuery(baseOptions?: Apollo.QueryHookOptions<WorkOrdersQuery, WorkOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrdersQuery, WorkOrdersQueryVariables>(WorkOrdersDocument, options);
      }
export function useWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrdersQuery, WorkOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrdersQuery, WorkOrdersQueryVariables>(WorkOrdersDocument, options);
        }
export type WorkOrdersQueryHookResult = ReturnType<typeof useWorkOrdersQuery>;
export type WorkOrdersLazyQueryHookResult = ReturnType<typeof useWorkOrdersLazyQuery>;
export type WorkOrdersQueryResult = Apollo.QueryResult<WorkOrdersQuery, WorkOrdersQueryVariables>;
export const WorkOrderDocument = gql`
    query workOrder($id: ID!) {
  workOrder(id: $id) {
    id
  }
}
    `;

/**
 * __useWorkOrderQuery__
 *
 * To run a query within a React component, call `useWorkOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkOrderQuery(baseOptions: Apollo.QueryHookOptions<WorkOrderQuery, WorkOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrderQuery, WorkOrderQueryVariables>(WorkOrderDocument, options);
      }
export function useWorkOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrderQuery, WorkOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrderQuery, WorkOrderQueryVariables>(WorkOrderDocument, options);
        }
export type WorkOrderQueryHookResult = ReturnType<typeof useWorkOrderQuery>;
export type WorkOrderLazyQueryHookResult = ReturnType<typeof useWorkOrderLazyQuery>;
export type WorkOrderQueryResult = Apollo.QueryResult<WorkOrderQuery, WorkOrderQueryVariables>;
export const CreateOneWorkOrderDocument = gql`
    mutation createOneWorkOrder($input: CreateOneWorkOrderInput!) {
  createOneWorkOrder(input: $input) {
    id
  }
}
    `;
export type CreateOneWorkOrderMutationFn = Apollo.MutationFunction<CreateOneWorkOrderMutation, CreateOneWorkOrderMutationVariables>;

/**
 * __useCreateOneWorkOrderMutation__
 *
 * To run a mutation, you first call `useCreateOneWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneWorkOrderMutation, { data, loading, error }] = useCreateOneWorkOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneWorkOrderMutation, CreateOneWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneWorkOrderMutation, CreateOneWorkOrderMutationVariables>(CreateOneWorkOrderDocument, options);
      }
export type CreateOneWorkOrderMutationHookResult = ReturnType<typeof useCreateOneWorkOrderMutation>;
export type CreateOneWorkOrderMutationResult = Apollo.MutationResult<CreateOneWorkOrderMutation>;
export type CreateOneWorkOrderMutationOptions = Apollo.BaseMutationOptions<CreateOneWorkOrderMutation, CreateOneWorkOrderMutationVariables>;
export const UpdateOneWorkOrderDocument = gql`
    mutation updateOneWorkOrder($input: UpdateOneWorkOrderInput!) {
  updateOneWorkOrder(input: $input) {
    id
  }
}
    `;
export type UpdateOneWorkOrderMutationFn = Apollo.MutationFunction<UpdateOneWorkOrderMutation, UpdateOneWorkOrderMutationVariables>;

/**
 * __useUpdateOneWorkOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOneWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneWorkOrderMutation, { data, loading, error }] = useUpdateOneWorkOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneWorkOrderMutation, UpdateOneWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneWorkOrderMutation, UpdateOneWorkOrderMutationVariables>(UpdateOneWorkOrderDocument, options);
      }
export type UpdateOneWorkOrderMutationHookResult = ReturnType<typeof useUpdateOneWorkOrderMutation>;
export type UpdateOneWorkOrderMutationResult = Apollo.MutationResult<UpdateOneWorkOrderMutation>;
export type UpdateOneWorkOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOneWorkOrderMutation, UpdateOneWorkOrderMutationVariables>;
export const DeleteOneWorkOrderDocument = gql`
    mutation deleteOneWorkOrder($input: DeleteOneWorkOrderInput!) {
  deleteOneWorkOrder(input: $input) {
    id
  }
}
    `;
export type DeleteOneWorkOrderMutationFn = Apollo.MutationFunction<DeleteOneWorkOrderMutation, DeleteOneWorkOrderMutationVariables>;

/**
 * __useDeleteOneWorkOrderMutation__
 *
 * To run a mutation, you first call `useDeleteOneWorkOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneWorkOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneWorkOrderMutation, { data, loading, error }] = useDeleteOneWorkOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneWorkOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneWorkOrderMutation, DeleteOneWorkOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneWorkOrderMutation, DeleteOneWorkOrderMutationVariables>(DeleteOneWorkOrderDocument, options);
      }
export type DeleteOneWorkOrderMutationHookResult = ReturnType<typeof useDeleteOneWorkOrderMutation>;
export type DeleteOneWorkOrderMutationResult = Apollo.MutationResult<DeleteOneWorkOrderMutation>;
export type DeleteOneWorkOrderMutationOptions = Apollo.BaseMutationOptions<DeleteOneWorkOrderMutation, DeleteOneWorkOrderMutationVariables>;
export const DeleteManyWorkOrdersDocument = gql`
    mutation deleteManyWorkOrders($input: DeleteManyWorkOrdersInput!) {
  deleteManyWorkOrders(input: $input) {
    deletedCount
  }
}
    `;
export type DeleteManyWorkOrdersMutationFn = Apollo.MutationFunction<DeleteManyWorkOrdersMutation, DeleteManyWorkOrdersMutationVariables>;

/**
 * __useDeleteManyWorkOrdersMutation__
 *
 * To run a mutation, you first call `useDeleteManyWorkOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyWorkOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyWorkOrdersMutation, { data, loading, error }] = useDeleteManyWorkOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteManyWorkOrdersMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyWorkOrdersMutation, DeleteManyWorkOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyWorkOrdersMutation, DeleteManyWorkOrdersMutationVariables>(DeleteManyWorkOrdersDocument, options);
      }
export type DeleteManyWorkOrdersMutationHookResult = ReturnType<typeof useDeleteManyWorkOrdersMutation>;
export type DeleteManyWorkOrdersMutationResult = Apollo.MutationResult<DeleteManyWorkOrdersMutation>;
export type DeleteManyWorkOrdersMutationOptions = Apollo.BaseMutationOptions<DeleteManyWorkOrdersMutation, DeleteManyWorkOrdersMutationVariables>;
export const GenerateWorkOrderTemplateDocument = gql`
    mutation generateWorkOrderTemplate {
  generateImportTemplate
}
    `;
export type GenerateWorkOrderTemplateMutationFn = Apollo.MutationFunction<GenerateWorkOrderTemplateMutation, GenerateWorkOrderTemplateMutationVariables>;

/**
 * __useGenerateWorkOrderTemplateMutation__
 *
 * To run a mutation, you first call `useGenerateWorkOrderTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateWorkOrderTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateWorkOrderTemplateMutation, { data, loading, error }] = useGenerateWorkOrderTemplateMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateWorkOrderTemplateMutation(baseOptions?: Apollo.MutationHookOptions<GenerateWorkOrderTemplateMutation, GenerateWorkOrderTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateWorkOrderTemplateMutation, GenerateWorkOrderTemplateMutationVariables>(GenerateWorkOrderTemplateDocument, options);
      }
export type GenerateWorkOrderTemplateMutationHookResult = ReturnType<typeof useGenerateWorkOrderTemplateMutation>;
export type GenerateWorkOrderTemplateMutationResult = Apollo.MutationResult<GenerateWorkOrderTemplateMutation>;
export type GenerateWorkOrderTemplateMutationOptions = Apollo.BaseMutationOptions<GenerateWorkOrderTemplateMutation, GenerateWorkOrderTemplateMutationVariables>;
export const ValidateWorkOrdersImportDocument = gql`
    mutation validateWorkOrdersImport($input: ImportManyWorkOrdersInput!) {
  validateWorkOrdersImport(input: $input) {
    address
    value
    informations
    warnings
    errors
  }
}
    `;
export type ValidateWorkOrdersImportMutationFn = Apollo.MutationFunction<ValidateWorkOrdersImportMutation, ValidateWorkOrdersImportMutationVariables>;

/**
 * __useValidateWorkOrdersImportMutation__
 *
 * To run a mutation, you first call `useValidateWorkOrdersImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateWorkOrdersImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateWorkOrdersImportMutation, { data, loading, error }] = useValidateWorkOrdersImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateWorkOrdersImportMutation(baseOptions?: Apollo.MutationHookOptions<ValidateWorkOrdersImportMutation, ValidateWorkOrdersImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateWorkOrdersImportMutation, ValidateWorkOrdersImportMutationVariables>(ValidateWorkOrdersImportDocument, options);
      }
export type ValidateWorkOrdersImportMutationHookResult = ReturnType<typeof useValidateWorkOrdersImportMutation>;
export type ValidateWorkOrdersImportMutationResult = Apollo.MutationResult<ValidateWorkOrdersImportMutation>;
export type ValidateWorkOrdersImportMutationOptions = Apollo.BaseMutationOptions<ValidateWorkOrdersImportMutation, ValidateWorkOrdersImportMutationVariables>;
export const ImportWorkOrdersDocument = gql`
    mutation importWorkOrders($input: ImportManyWorkOrdersInput!) {
  importWorkOrders(input: $input)
}
    `;
export type ImportWorkOrdersMutationFn = Apollo.MutationFunction<ImportWorkOrdersMutation, ImportWorkOrdersMutationVariables>;

/**
 * __useImportWorkOrdersMutation__
 *
 * To run a mutation, you first call `useImportWorkOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportWorkOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importWorkOrdersMutation, { data, loading, error }] = useImportWorkOrdersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportWorkOrdersMutation(baseOptions?: Apollo.MutationHookOptions<ImportWorkOrdersMutation, ImportWorkOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportWorkOrdersMutation, ImportWorkOrdersMutationVariables>(ImportWorkOrdersDocument, options);
      }
export type ImportWorkOrdersMutationHookResult = ReturnType<typeof useImportWorkOrdersMutation>;
export type ImportWorkOrdersMutationResult = Apollo.MutationResult<ImportWorkOrdersMutation>;
export type ImportWorkOrdersMutationOptions = Apollo.BaseMutationOptions<ImportWorkOrdersMutation, ImportWorkOrdersMutationVariables>;
export const WorkspaceDocument = gql`
    query workspace {
  workspace {
    workspaceViews {
      name
      workspaceViewColumns {
        name
        visible
        order
      }
    }
  }
}
    `;

/**
 * __useWorkspaceQuery__
 *
 * To run a query within a React component, call `useWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceQuery(baseOptions?: Apollo.QueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
      }
export function useWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceQuery, WorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceQuery, WorkspaceQueryVariables>(WorkspaceDocument, options);
        }
export type WorkspaceQueryHookResult = ReturnType<typeof useWorkspaceQuery>;
export type WorkspaceLazyQueryHookResult = ReturnType<typeof useWorkspaceLazyQuery>;
export type WorkspaceQueryResult = Apollo.QueryResult<WorkspaceQuery, WorkspaceQueryVariables>;
export const ManageWorkspaceViewColumnDocument = gql`
    mutation manageWorkspaceViewColumn($input: ManageWorkspaceViewColumn!) {
  manageWorkspaceViewColumn(input: $input)
}
    `;
export type ManageWorkspaceViewColumnMutationFn = Apollo.MutationFunction<ManageWorkspaceViewColumnMutation, ManageWorkspaceViewColumnMutationVariables>;

/**
 * __useManageWorkspaceViewColumnMutation__
 *
 * To run a mutation, you first call `useManageWorkspaceViewColumnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageWorkspaceViewColumnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageWorkspaceViewColumnMutation, { data, loading, error }] = useManageWorkspaceViewColumnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageWorkspaceViewColumnMutation(baseOptions?: Apollo.MutationHookOptions<ManageWorkspaceViewColumnMutation, ManageWorkspaceViewColumnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageWorkspaceViewColumnMutation, ManageWorkspaceViewColumnMutationVariables>(ManageWorkspaceViewColumnDocument, options);
      }
export type ManageWorkspaceViewColumnMutationHookResult = ReturnType<typeof useManageWorkspaceViewColumnMutation>;
export type ManageWorkspaceViewColumnMutationResult = Apollo.MutationResult<ManageWorkspaceViewColumnMutation>;
export type ManageWorkspaceViewColumnMutationOptions = Apollo.BaseMutationOptions<ManageWorkspaceViewColumnMutation, ManageWorkspaceViewColumnMutationVariables>;
export const SetWorkspaceViewDocument = gql`
    mutation setWorkspaceView($input: SetWorkspaceView!) {
  setWorkspaceView(input: $input)
}
    `;
export type SetWorkspaceViewMutationFn = Apollo.MutationFunction<SetWorkspaceViewMutation, SetWorkspaceViewMutationVariables>;

/**
 * __useSetWorkspaceViewMutation__
 *
 * To run a mutation, you first call `useSetWorkspaceViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkspaceViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkspaceViewMutation, { data, loading, error }] = useSetWorkspaceViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetWorkspaceViewMutation(baseOptions?: Apollo.MutationHookOptions<SetWorkspaceViewMutation, SetWorkspaceViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWorkspaceViewMutation, SetWorkspaceViewMutationVariables>(SetWorkspaceViewDocument, options);
      }
export type SetWorkspaceViewMutationHookResult = ReturnType<typeof useSetWorkspaceViewMutation>;
export type SetWorkspaceViewMutationResult = Apollo.MutationResult<SetWorkspaceViewMutation>;
export type SetWorkspaceViewMutationOptions = Apollo.BaseMutationOptions<SetWorkspaceViewMutation, SetWorkspaceViewMutationVariables>;