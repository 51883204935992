import { GridColDef } from "@mui/x-data-grid-pro";
import { relationGetter } from "../Datagrid/Datagrid";
import { ActionsRender } from "../Datagrid/Renders/ActionsRender";
import { PlanningStateRender } from "../Datagrid/Renders/PlanningStateRender";

export default function getPlanningColumns(openLink: any, copyPlanning: any): GridColDef[] {
    return [
        { field: "id", width: 70 },
        { field: "week", width: 100, headerName: "semana" },
        { field: "year", width: 100, headerName: "ano" },
        { field: "planningState", headerName: "estado", width: 150, renderCell: PlanningStateRender, filterable: false, sortable: false },
        { field: "sector", width: 150, headerName: "setor", valueGetter: params => relationGetter(params, "sector") },
        { field: "actions", headerName: "", renderCell: params => ActionsRender(params, openLink, copyPlanning), width: 100, filterable: false, sortable: false, resizable: false, hideable: false, disableColumnMenu: true }

    ]
}
