import { FC, ReactNode } from "react";
import { useCreateOneOrderMutation, useDeleteOneOrderMutation, useOrdersQuery, useUpdateOneOrderMutation } from "../../generated/graphql";
import { FilterConfig } from "../../hooks/useFilters";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const OrdersDataSource = {
    createFunc: useCreateOneOrderMutation,
    readFunc: useOrdersQuery,
    updateFunc: useUpdateOneOrderMutation,
    deleteFunc: useDeleteOneOrderMutation,
}

interface OrderProviderProps {
    children: ReactNode
    filterConfig: FilterConfig[]
}

const OrderProvider: FC<OrderProviderProps> = props => {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={OrdersDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default OrderProvider
