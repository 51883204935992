import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Maybe } from 'graphql/jsutils/Maybe';
import { FC } from 'react';
import { useShowFieldError } from '../../hooks/useShowFieldError';

interface DatePickerProps {
    field: any,
    form: any,
    props: any,
    label?: string,
    fullWidth?: boolean,
    disabled?: boolean
    variant?: "standard" | "filled" | "outlined"
    minDate?: any
    maxDate?: any
}

// E#1002
const DatePicker: FC<DatePickerProps> = ({ field, form, ...props }) => {
    const { variant = "standard", disabled, minDate, maxDate } = props
    const { name, value, onChange } = field
    const { error, helperText } = useShowFieldError(field.name)

    // @ts-ignore
    const handleChange = (date: Maybe<Date>) => {
        if (!date) return null
        try {
            const event = {
                target: { name, value: date }
            }
            onChange(event)
        } catch (e) { }
    }

    return (
        // @ts-ignore
        <MuiDatePicker onChange={handleChange}
            value={value}
            slotProps={{ textField: { variant, error, helperText } }}
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
        />

    )
}


export default DatePicker
