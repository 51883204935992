import { FC, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import Config from "../../services/config";

interface UserRedirectProps {
    redirectTo?: string,
    children: ReactElement | null
}

const UserRedirect: FC<UserRedirectProps> = props => {
    const { children, redirectTo = Config.START_ROUTE } = props

    const token = localStorage.getItem(Config.AUTH_TOKEN_KEY)

    if (token) {
        return <Navigate to={redirectTo} replace />;
    }

    return children;
}

export default UserRedirect;
