import Config from '../../../services/config'
import styles from './PlanningStateRender.module.scss'

const getPlanningRowStateBackgroundColor = (params: any) => {
    return params?.row?.planningRowState?.color || Config.DEFAULT_STATE_COLOR
}

export const PlanningRowStateRender = (params: any) => {
    const backgroundColor = getPlanningRowStateBackgroundColor(params)
    return (
        <div className={styles.PlanningState} style={{ backgroundColor }}>
            {params?.row?.planningRowState?.name}
        </div>
    )
}
