import { CalendarViewMonth } from '@mui/icons-material';
import { Fade } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Config from '../../services/config';
import Datagrid from '../Datagrid/Datagrid';
import DataListHeader from '../DataListHeader/DataListHeader';
import { DataViewContext } from '../DataViewProvider/DataViewProvider';
import TemporaryDrawer from '../TemporaryDrawer/TemporaryDrawer';
import WorkOrderForm from '../WorkOrderForm/WorkOrderForm';
import { workOrderColumns } from './WorkOrderColumns';
import styles from './WorkOrdersList.module.scss';

const WorkOrdersList = () => {
    const context = useContext(DataViewContext)
    const nodes = context?.data?.workOrders?.nodes
    const rows = nodes || []
    const [detailRow, setDetailRow] = useState<any>(null)

    useEffect(() => {
        context.setView("workOrdersView")
        context.setColumnDefs(workOrderColumns)
    }, [])

    const handleRowSelectionChange = (newRowSelectionModel: any) => {
        context?.setRowSelectionModel(newRowSelectionModel);
    }

    const handleCellClick = (params: any) => {
        if (params?.field === 'workOrderid') {
            setDetailRow(params.row)
        }
    }

    return (
        <Fade in={!context.loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
            <div className={styles.WorkOrdersLists}>
                <DataListHeader name="Ordens Fabrico" icon={CalendarViewMonth} />
                <Datagrid
                    rows={rows}
                    columns={context?.columns}
                    loading={context.loading}
                    totalCount={context?.totalCount}
                    paginationModel={context?.paginationModel}
                    setPaginationModel={context?.setPaginationModel}
                    setSortModel={context?.setSortModel}
                    setColumnOrderModel={context?.setColumnOrderModel}
                    columnVisibilityModel={context?.columnVisibilityModel}
                    setColumnVisibilityModel={context?.setColumnVisibilityModel}
                    onRowSelectionModelChange={handleRowSelectionChange}
                    onCellClick={handleCellClick}
                    disableSelectionOnClick={true}
                    rowSelectionModel={context?.rowSelectionModel}
                />
                <TemporaryDrawer isOpen={!!detailRow} setIsOpen={setDetailRow} hideBackdrop={false}>
                    <WorkOrderForm workOrder={detailRow} onSuccess={() => setDetailRow(null)} />
                </TemporaryDrawer>
            </div>
        </Fade >
    );
}

export default WorkOrdersList
