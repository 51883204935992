import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import { FastField } from 'formik';
import { Autocomplete } from 'formik-mui';
import { UserRoles } from '../../generated/graphql';
import { FormFieldProps } from '../../types/types';
import { enumToOptions } from '../../utils/filters';

export default function RoleField(props: FormFieldProps) {
    const {
        name = "role",
        label = "Função",
        textFieldProps = { variant: "standard" }
    } = props

    const options = enumToOptions(UserRoles, "label")

    return (
        <FastField
            name={name}
            component={Autocomplete}
            disableClearable
            options={options}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    {...params}
                    label={label}
                    {...textFieldProps}
                />
            )}
        />
    )
}
