import { FC } from "react";
import { useCreateOnePlanningRowMutation, useDeleteOnePlanningRowMutation, usePlanningRowsQuery, useUpdateOnePlanningRowMutation } from "../../generated/graphql";
import { DataProviderProps } from "../../types/types";
import DataViewProvider from "../DataViewProvider/DataViewProvider";

const PlanningsDataSource = {
    createFunc: useCreateOnePlanningRowMutation,
    readFunc: usePlanningRowsQuery,
    updateFunc: useUpdateOnePlanningRowMutation,
    deleteFunc: useDeleteOnePlanningRowMutation
}

const PlanningRowProvider: FC<DataProviderProps> = props => {
    const { filterConfig } = props
    return (
        <DataViewProvider datasource={PlanningsDataSource} filterConfig={filterConfig}>
            {props.children}
        </DataViewProvider>
    )
}

export default PlanningRowProvider
