import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { MouseEvent } from 'react';
import { Notification as NotificationType } from '../../generated/graphql';
import { FormatDateTime } from '../FormatDate/FormatDate';
import styles from './Notification.module.scss';

interface NotificationProps {
    notification: any // Partial<NotificationType>
    toogleRead: (notification: NotificationType) => void
    openLink: (notification: NotificationType) => void
}

export default function Notification(props: NotificationProps) {
    const { notification, toogleRead, openLink } = props


    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        openLink(notification)
    }

    const handleToogle = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        toogleRead(notification)
    }

    const icon = notification?.isRead ? <RadioButtonUnchecked className={styles.Icon} /> : <RadioButtonChecked className={styles.Icon} style={{ color: "#316ddf" }} />
    const title = notification?.isRead ? "marcar não lida" : "marcar lida"

    return (
        <Grid container justifyContent="space-between" className={styles.Notification} onClick={handleClick}>
            <Grid item xs={10} container direction="column">
                <div>
                    <Typography variant="body1" className={styles.Content}>
                        {notification?.content}
                    </Typography>
                </div>
                <div style={{ fontSize: 12, marginTop: 10 }}>
                    <FormatDateTime date={notification.createdAt} />
                </div>
            </Grid>
            <Grid item xs={2} container justifyContent="flex-end" alignItems="flex-start">
                <Tooltip title={title}>
                    <IconButton onClick={handleToogle}>
                        {icon}
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )
}
