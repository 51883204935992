import { FormikValues } from "formik"
import { updateOmit } from "./objects"

// o value pode ser null em certos casos
// já aconteceu e deu erro, definir como lidar
// com os nulls
// @todo - adicionar testes e documentar
export const payloadTransform = (values: FormikValues) => {
    const transformedValues: any = {}
    Object.entries(values).forEach(([key, value]) => {
        if (value?.__typename) {
            transformedValues[key] = value.id
        } else {
            transformedValues[key] = value
        }
    })
    return transformedValues
}

// @todo - adicionar testes e documentar
export const createPayload = (values: FormikValues, endpoint: string) => {
    const transformedValues = payloadTransform(values)
    return { variables: { input: { [endpoint]: { ...transformedValues } } } }
}

/**
 * constroi um update payload
 */
export const updatePayload = (values: FormikValues, omitFields: string[] = []) => {
    let { id, ...data } = values
    data = updateOmit(data, omitFields)
    const transformedValues = payloadTransform(data)
    return { variables: { input: { id, update: { ...transformedValues } } } }
}
