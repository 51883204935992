import * as Yup from "yup";

export default function userSchemaGetter() {
    return Yup.object().shape({
        name: Yup.string().required().default(""),
        email: Yup.string().email().required().default(""),
        password: Yup.string().nullable().default(""),
        role: Yup.mixed().required().default(null),
        sector: Yup.mixed().required().default(null)
    })
}
