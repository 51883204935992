import * as Yup from "yup";
import { getCurrentIsoWeek, getCurrentIsoYear } from "../../utils/dates";

const currentWeek = getCurrentIsoWeek()
const currentYear = getCurrentIsoYear()

export default function planningSchemaGetter() {
    return Yup.object().shape({
        week: Yup.number().required().default(currentWeek),
        year: Yup.number().required().default(currentYear),
        planningState: Yup.mixed().required().default(null),
        sector: Yup.mixed().required().default(null),
    })
}
