import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useContext } from "react"
import { Planning } from "../../generated/graphql"
import useMakeFormSubmit from "../../hooks/useMakeFormSubmit"
import { noop } from "../../services/utils"
import { formInit } from "../../utils/forms"
import { DataViewContext } from "../DataViewProvider/DataViewProvider"
import InputNumberField from "../InputNumberField/InputNumberField"
import PlanningStateField from "../PlanningStateField/PlanningStateField"
import SectorField from "../SectorField/SectorField"
import SubmitButton from "../SubmitButton/SubmitButton"
import styles from './PlanningForm.module.scss'
import planningSchemaGetter from "./PlanningFormInit"

interface PlanningFormProps {
    planning?: Partial<Planning>
    onSuccess?: () => any
}

const PlanningForm: FC<PlanningFormProps> = props => {
    const { planning, onSuccess = noop } = props
    const context = useContext(DataViewContext)
    const { schema, initial } = formInit<Planning>(planningSchemaGetter, planning)
    const handleSubmit = useMakeFormSubmit({ context, entity: "planning", onSuccess })

    const formName = planning?.id ? "Editar Planeamento" : "Criar Planeamento"

    return (
        <div className={styles.TaskFormWrapper}>
            <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                {() => {
                    return (
                        <Form>
                            <Grid container direction="column" className={styles.Section}>
                                <Typography variant="h4" color="primary" gutterBottom>
                                    {formName}
                                </Typography>
                            </Grid>

                            <TableContainer>
                                <Table sx={{ minWidth: 400 }} className={styles.Table}>
                                    <TableBody>
                                        <TableRow key={1}>
                                            <TableCell>
                                                <InputNumberField name="week" label="semana" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={2}>
                                            <TableCell>
                                                <InputNumberField name="year" label="ano" fullWidth />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={3}>
                                            <TableCell>
                                                <SectorField />
                                            </TableCell>
                                        </TableRow>

                                        <TableRow key={4}>
                                            <TableCell>
                                                <PlanningStateField />
                                            </TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container justifyContent="flex-end">
                                <SubmitButton />
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default PlanningForm
