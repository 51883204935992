import { FastField } from "formik"
import { FC } from "react"
import FieldText from "../FieldText/FieldText"

interface InputNumberFieldProps {
    name: string
    label: string
    fullWidth?: boolean
}

const InputNumberField: FC<InputNumberFieldProps> = props => {
    const { name, label, fullWidth } = props
    return (
        <FastField
            component={FieldText}
            name={name}
            label={label}
            type="number"
            fullWidth={fullWidth}
        />
    )
}

export default InputNumberField
