import { FC, ReactNode } from 'react';
import Header from '../Header/Header';
import Protect from '../Protect/Protect';
import Sidemenu from '../Sidemenu/Sidemenu';
import LayoutProvider from './LayoutProvider';

interface LayoutProps {
    children: ReactNode
}

const Layout: FC<LayoutProps> = props => {

    const { children } = props

    return (
        <Protect>
            <LayoutProvider>
                <Header />
                <Sidemenu />
                {children}
            </LayoutProvider>
        </Protect>
    )
}


export default Layout;
